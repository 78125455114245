import initialState from './initial.state';
import * as types from '../actions/types.action';
import {
  BASKET_ERRORS_TYPE,
  CONTACT_ADMIN,
  ERRORS,
  HTTP_STATUS,
  ROUTES,
} from '../constants/constants';

export default function errorReducer(state = initialState.errors, action) {
  switch (action.type) {
    case types.GET_POSTCODE_LOOKUP_FAILED:
      return Object.assign({}, state, {
        lookup: action.error,
      });
    case types.GET_POSTCODE_LOOKUP_REQUESTED:
    case types.GET_ADDRESS_LOOKUP_REQUESTED:
      return null;
    case types.SET_FULFILLMENT_FAILED: {
      return Object.assign({}, state, {
        fulfillment: action.message,
      });
    }
    case types.GET_FULFILL_SLOT_FAILED: {
      return Object.assign({}, state, {
        getSlot: action.message,
      });
    }
    case types.SET_FULFILL_SLOT_FAILED: {
      return Object.assign({}, state, {
        setSlot:
          'Slot booking problem, Please try again or select a different slot',
      });
    }
    case types.DELETE_FULFILL_SLOT_FAILED: {
      return Object.assign({}, state, {
        deleteSlot: action.message,
      });
    }
    case types.GET_BASKET_SERVER_ERROR: {
      return Object.assign({}, state, {
        basketServerError: true,
      });
    }
    case types.INVALID_BASKET_UUID_ERROR: {
      return Object.assign({}, state, {
        invalidBasket: true,
      });
    }
    case types.AX_BASKET_ALREADY_PICKED: {
      return Object.assign({}, state, {
        basketAlreadyPicked: true,
      });
    }
    case types.AX_BASKET_NOT_EDITABLE: {
      return Object.assign({}, state, {
        basketNotEditable: true,
      });
    }
    case types.AX_BASKET_NOT_FOUND: {
      return Object.assign({}, state, {
        axBasketNotFound: true,
      });
    }
    case types.REORDER_BASKET_FAILED: {
      return Object.assign({}, state, {
        reOrderServerError: true,
      });
    }
    case types.EDIT_ORDER_FAILED: {
      return Object.assign({}, state, {
        editOrderServerError:
          action.message || BASKET_ERRORS_TYPE.FAILED_TO_EDIT_ORDER,
      });
    }
    case types.CANCEL_ORDER_FAILED: {
      return Object.assign({}, state, {
        cancelOrderServerError: true,
      });
    }
    case types.UPDATE_BASKET_FAILED:
    case types.SET_PAYMENT_TYPE_FAILED:
    case types.UPDATE_BASKET_ITEM_FAILED:
    case types.REMOVE_BASKET_ITEM_FAILED: {
      let code = action.code;
      if (
        action.code !== ERRORS.PRICE_NOT_FOUND &&
        action.code !== ERRORS.SLOT_EXPIRED
      ) {
        code = ERRORS.UPDATE_BASKET;
      }
      return {
        updateBasket: {
          code,
          issue: action.issue,
          message: action.message,
          actionType: action.type,
        },
      };
    }
    case types.CHECKOUT_SLOT_EXPIRED:
      const issue =
        action.page === ROUTES.CHECKOUT
          ? BASKET_ERRORS_TYPE.SLOT_EXPIRED_DURING_CHECKOUT
          : BASKET_ERRORS_TYPE.SLOT_EXPIRED;
      return {
        updateBasket: {
          code: ERRORS.SLOT_EXPIRED,
          issue,
        },
      };
    case types.CHECKOUT_TOTAL_DISCREPANCY:
      return {
        updateBasket: {
          code: ERRORS.TOTAL_DISCREPANCY,
        },
      };
    case types.SET_SUBSTITUTE_ITEM_FAILED: {
      return {
        updateBasket: {
          code: action.code || ERRORS.UPDATE_SUBSTITUTE_ITEM,
          message: action.message,
        },
      };
    }
    case types.BASKET_NO_ITEMS_ERROR: {
      return {
        updateBasket: {
          code: ERRORS.BASKET_NO_ITEMS,
        },
      };
    }
    case types.BASKET_ONLY_OOS_ITEMS_ERROR: {
      return {
        updateBasket: {
          code: ERRORS.BASKET_ONLY_OOS_ITEMS,
        },
      };
    }
    case types.BASKET_MIN_DELIVERY_ORDER_ERROR: {
      return {
        updateBasket: {
          code: ERRORS.BASKET_MIN_DELIVERY_ORDER,
        },
      };
    }
    case types.ORDER_ERROR: {
      return {
        updateBasket: {
          code: ERRORS.ORDER_ERROR,
        },
      };
    }
    case types.GET_ORDER_HISTORY_FAILED:
      return Object.assign({}, state, {
        getOrderHistoryFailed: true,
      });
    case types.GET_ACTIVE_ORDERS_FAILED: {
      return Object.assign({}, state, {
        getOrderActiveFailed: true,
      });
    }
    case types.ERROR_LOGIN_EXPIRED: {
      return Object.assign({}, state, {
        token: action.error,
      });
    }
    case types.ERROR_REQUIRE_LOGIN: {
      return Object.assign({}, state, {
        requireLogin: true,
      });
    }
    case types.GET_ACCOUNTS_FAILED: {
      return Object.assign({}, state, {
        getAccountFailed: true,
      });
    }
    case types.NO_ACTIVE_ACCOUNTS: {
      return Object.assign({}, state, {
        noActiveAccount: true,
      });
    }
    case types.GET_ACCOUNTS_SUCCESS:
    case types.GET_ACCOUNTS_FROM_CACHE_SUCCESS: {
      return Object.assign({}, state, {
        getAccountFailed: false,
      });
    }
    case types.GET_VACANCIES_FAILED: {
      return Object.assign({}, state, {
        getVacanciesFailed: true,
      });
    }
    case types.GET_VACANCIES_SUCCESS:
    case types.GET_VACANCIES_REQUESTED: {
      return Object.assign({}, state, {
        getVacanciesFailed: false,
      });
    }
    case types.SERVER_ERROR: {
      return Object.assign({}, state, {
        serverError: true,
      });
    }
    case types.BASKET_ERROR: {
      return Object.assign({}, state, {
        basket: action.message,
      });
    }
    case types.PROCESS_CHECKOUT_VALIDATION_FAILED: {
      return Object.assign({}, state, {
        checkout: action.messages,
      });
    }
    case types.ENHANCE_TOKEN_FAILED: {
      return Object.assign({}, state, {
        contactAdmin: CONTACT_ADMIN.CRITICAL,
      });
    }
    case types.GET_EMPTY_FULFILL_SLOT: {
      return Object.assign({}, state, {
        postCodeOutsideOfDeliveryArea: true,
      });
    }
    case types.FORCE_LOGIN_REQUESTED:
    case types.REFRESH_AUTH_FAILED: {
      return Object.assign({}, state, {
        forceLogout: true,
      });
    }
    case types.PROCESS_CHECKOUT_FAILED: {
      return Object.assign({}, state, {
        processCheckout: true,
      });
    }
    case types.ERROR_CLEAN_UP: {
      return null;
    }
    case types.UPDATE_MARKETING_OPTIONS_FAILED: {
      return Object.assign({}, state, {
        updateMarketing: true,
      });
    }
    case types.SEARCH_ACCOUNTS_REQUESTED: {
      return Object.assign({}, state, {
        search_account: false,
      });
    }
    case types.SEARCH_ACCOUNTS_FAILED: {
      return Object.assign({}, state, {
        search_account: true,
      });
    }
    case types.GET_ORDER_AFTER_CHECKOUT_FAILED: {
      return Object.assign({}, state, {
        order: true,
      });
    }
    case types.PROCESS_CHECKOUT_SUCCESS:
      return Object.assign({}, state, {
        order: false,
      });
    case types.REGISTER_USER_REQUESTED: {
      return Object.assign({}, state, {
        register: false,
      });
    }
    case types.REGISTER_USER_FAILED: {
      return Object.assign({}, state, {
        register: true,
      });
    }
    case types.SET_NEW_PASSWORD_REQUESTED:
    case types.PASSWORD_RESET_REQUESTED: {
      return Object.assign({}, state, {
        passwordReset: false,
      });
    }
    case types.SET_NEW_PASSWORD_FAILED:
    case types.PASSWORD_RESET_FAILED: {
      return Object.assign({}, state, {
        passwordReset: true,
      });
    }
    case types.CHANGE_PASSWORD_FAILED: {
      return Object.assign({}, state, {
        changePassword: true,
      });
    }
    case types.CHANGE_PASSWORD_REQUESTED:
    case types.CHANGE_PASSWORD_SUCCESS: {
      return Object.assign({}, state, {
        changePassword: false,
      });
    }
    case types.CLEAR_VOUCHER_ERRORS_SUCCEEDED:
    case types.ADD_VOUCHER_REQUESTED: {
      return Object.assign({}, state, {
        voucher: false,
      });
    }
    case types.ADD_VOUCHER_FAILED: {
      return Object.assign({}, state, {
        voucher: action.voucher,
      });
    }
    case types.GET_CMS_BANNERS_REQUESTED:
    case types.GET_CMS_BANNERS_SUCCESS: {
      return Object.assign({}, state, {
        banners: false,
      });
    }
    case types.GET_CMS_BANNERS_FAILED: {
      return Object.assign({}, state, {
        banners: true,
      });
    }
    case types.GET_CMS_ABOUTUS_REQUESTED:
    case types.GET_CMS_ABOUTUS_SUCCESS: {
      return Object.assign({}, state, {
        aboutus: false,
      });
    }
    case types.GET_CMS_ABOUTUS_FAILED: {
      return Object.assign({}, state, {
        aboutus: true,
      });
    }
    case types.GET_CMS_CAREERS_REQUESTED:
    case types.GET_CMS_CAREERS_SUCCESS: {
      return Object.assign({}, state, {
        careers: false,
      });
    }
    case types.GET_CMS_CAREERS_FAILED: {
      return Object.assign({}, state, {
        careers: true,
      });
    }
    case types.GET_CMS_CUSTOMERS_REQUESTED:
    case types.GET_CMS_CUSTOMERS_SUCCESS: {
      return Object.assign({}, state, {
        customers: false,
      });
    }
    case types.GET_CMS_CUSTOMERS_FAILED: {
      return Object.assign({}, state, {
        customers: true,
      });
    }
    case types.GET_CMS_HELP_REQUESTED:
    case types.GET_CMS_HELP_SUCCESS: {
      return Object.assign({}, state, {
        help: false,
      });
    }
    case types.GET_CMS_HELP_FAILED: {
      return Object.assign({}, state, {
        help: true,
      });
    }
    case types.GET_CMS_PBB_FAQ_REQUESTED:
    case types.GET_CMS_PBB_FAQ_SUCCESS: {
      return Object.assign({}, state, {
        payByBankFAQ: false,
      });
    }
    case types.GET_CMS_PBB_FAQ_FAILED: {
      return Object.assign({}, state, {
        payByBankFAQ: true,
      });
    }
    case types.GET_CMS_WHYJJ_FAILED: {
      return Object.assign({}, state, {
        whyJJ: true,
      });
    }
    case types.GET_CMS_BROCHURES_REQUESTED:
    case types.GET_CMS_BROCHURES_SUCCESS: {
      return Object.assign({}, state, {
        brochures: false,
      });
    }
    case types.GET_CMS_BROCHURES_FAILED: {
      return Object.assign({}, state, {
        brochures: true,
      });
    }
    case types.GET_CMS_VACANCIES_REQUESTED:
    case types.GET_CMS_VACANCIES_SUCCESS: {
      return Object.assign({}, state, {
        vacancies: false,
      });
    }
    case types.GET_CMS_VACANCIES_FAILED: {
      return Object.assign({}, state, {
        vacancies: true,
      });
    }
    case types.GET_CMS_DEPARTMENTS_REQUESTED:
    case types.GET_CMS_DEPARTMENTS_SUCCESS: {
      return Object.assign({}, state, {
        departments: false,
      });
    }
    case types.GET_CMS_DEPARTMENTS_FAILED: {
      return Object.assign({}, state, {
        departments: true,
      });
    }
    case types.GET_CMS_TEAM_REQUESTED:
    case types.GET_CMS_TEAM_SUCCESS: {
      return Object.assign({}, state, {
        team: false,
      });
    }
    case types.GET_CMS_TEAM_FAILED: {
      return Object.assign({}, state, {
        team: true,
      });
    }
    case types.UPDATE_SETTING_REQUESTED:
    case types.UPDATE_SETTING_SUCCESS: {
      return Object.assign({}, state, {
        updateSettings: false,
      });
    }
    case types.UPDATE_SETTING_FAILED: {
      return Object.assign({}, state, {
        updateSettings: true,
      });
    }
    case types.ACTIVATE_ACCOUNT_FAILED: {
      return Object.assign({}, state, {
        activateAccount: true,
      });
    }
    case types.ACTIVATE_ACCOUNT_REQUESTED:
    case types.ACTIVATE_ACCOUNT_SUCCESS: {
      return Object.assign({}, state, {
        activateAccount: false,
      });
    }
    case types.TRACK_ORDER_FAILED: {
      return Object.assign({}, state, {
        trackOrder: true,
      });
    }
    case types.TRACK_ORDER_REQUESTED:
    case types.TRACK_ORDER_SUCCEEDED: {
      return Object.assign({}, state, {
        trackOrder: false,
      });
    }
    case types.CHECK_BASKET_SYNC_SUCCEEDED: {
      return Object.assign({}, state, {
        basketServerSyncRetries: 0,
      });
    }
    case types.CHECK_BASKET_SYNC_FAILED: {
      return Object.assign({}, state, {
        basketServerSyncRetries:
          ((state && state.basketServerSyncRetries) || 0) + 1,
      });
    }
    case types.BASKET_NOT_FOUND: {
      return Object.assign({}, state, {
        checkoutServerError: true,
      });
    }
    case types.GET_NEAREST_BRANCH_REQUESTED:
    case types.GET_NEAREST_BRANCH_SUCCESS:
    case types.CLEAR_INVALID_POSTCODE_SUCCESS: {
      return Object.assign({}, state, {
        getNearestBranch: false,
      });
    }
    case types.GET_NEAREST_BRANCH_FAILED: {
      return Object.assign({}, state, {
        getNearestBranch: true,
      });
    }
    case types.GET_CMS_POLICIES_REQUESTED:
    case types.GET_CMS_POLICIES_SUCCESS: {
      return Object.assign({}, state, {
        policies: false,
      });
    }
    case types.GET_CMS_POLICIES_FAILED: {
      return Object.assign({}, state, {
        policies: true,
      });
    }
    case types.GET_CMS_DELIVERIES_REQUESTED:
    case types.GET_CMS_DELIVERIES_SUCCESS: {
      return Object.assign({}, state, {
        deliveries: false,
      });
    }
    case types.GET_CMS_DELIVERIES_FAILED: {
      return Object.assign({}, state, {
        deliveries: true,
      });
    }
    case types.SET_LOGIN_TOKEN_FAILED: {
      return Object.assign({}, state, {
        azure: true,
      });
    }
    case types.GET_TOKEN_BY_PASSWORD_REQUESTED:
    case types.GET_TOKEN_BY_PASSWORD_SUCCESS: {
      return Object.assign({}, state, {relogin: false});
    }
    case types.GET_TOKEN_BY_PASSWORD_FAILED: {
      return Object.assign({}, state, {relogin: true});
    }
    case types.GET_BASKET_FAILED: {
      if (
        action.response &&
        action.response.status > HTTP_STATUS.SERVER_ERROR
      ) {
        return Object.assign({}, state, {basketServerError: true});
      }
      return state;
    }
    case types.AUTHORIZE_BRAINTREE_PAYMENT_FAILED: {
      return Object.assign({}, state, {
        braintree: action.errors || action.data,
      });
    }
    case types.GET_PAYMENT_DETAILS_FAILED: {
      return Object.assign({}, state, {paymentDetails: true});
    }
    case types.GET_ALL_SETTINGS_SUCCESS:
    case types.GET_ALL_SETTINGS_REQUESTED:
      return {
        getSettingFailed: false,
      };
    case types.GET_ALL_SETTINGS_FAILED:
      return {
        getSettingFailed: true,
      };
    case types.SEND_REFUND_EMAIL_FAILED: {
      return Object.assign({}, state, {
        requestRefund: action.errorCode || true,
      });
    }
    case types.SEND_REFUND_EMAIL_REQUESTED:
    case types.SEND_REFUND_EMAIL_SUCCESS:
    case types.SEND_REFUND_EMAIL_RESET: {
      return Object.assign({}, state, {requestRefund: false});
    }
    case types.GET_INVOICED_ORDER_FAILED: {
      return Object.assign({}, state, {invoicedOrder: true});
    }
    case types.GET_INVOICED_ORDER_REQUESTED:
    case types.GET_ORDER_SUCCEEDED: {
      return Object.assign({}, state, {invoicedOrder: false});
    }
    case types.AUTHORIZE_ADYEN_PAYMENT_REQUESTED: {
      return Object.assign({}, state, {adyen: null});
    }
    case types.AUTHORIZE_ADYEN_THREEDS_FAILED:
    case types.AUTHORIZE_ADYEN_GIVING_PAYMENT_FAILED:
    case types.AUTHORIZE_ADYEN_PAYMENT_FAILED: {
      return Object.assign({}, state, {adyen: action.errors});
    }
    default:
      return state;
  }
}
