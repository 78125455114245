import React, {PureComponent} from 'react';

import {
  PriceText,
  PriceType,
  UnitPriceText,
} from '../../components/Product/Product.style';
import {Price} from './ProductDetailPage.style';

export default class MultiPrices extends PureComponent {
  render() {
    const {prices, hasMixOffer} = this.props;
    return prices.map((price, index) => {
      const isLastItem = index === prices.length - 1;
      const isOffer = hasMixOffer && isLastItem;
      const isFirstOffer = hasMixOffer && !isLastItem;
      return (
        <Price key={index}>
          <PriceType $isOffer={isOffer} $isFirstOffer={isFirstOffer}>
            {price.min && `Mix any ${price.min}+:`}
          </PriceType>
          <PriceText $isOffer={isOffer} $isFirstOffer={isFirstOffer}>
            {price.priceInc && `£${price.priceInc.toFixed(2)}`}
          </PriceText>
          {isLastItem && price.unitPriceDisplay && (
            <UnitPriceText>{price.unitPriceDisplay}</UnitPriceText>
          )}
        </Price>
      );
    });
  }
}
