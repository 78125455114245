import axios from 'axios';
import {getApiConfig} from '../config/configProvider';

export default class CheckoutApi {
  static processCheckout(basketUuid, data, headers) {
    const config = getApiConfig();
    const basketUrl = `${config.orderUrl}/baskets`;
    const url = `${basketUrl}/${basketUuid}/order`;
    return axios.post(url, data, {headers}).then(res => res.data);
  }

  static processNonCardPayment(orderId, headers) {
    const config = getApiConfig();
    const url = `${config.orderUrl}/orders/${orderId}/authorize`;
    return axios.put(url, null, {headers}).then(res => res.status);
  }

  static processUrlPayment(url, data) {
    return axios.post(url, data).then(res => res.data);
  }
}
