import styled from 'styled-components';
import {media} from '../../styles/components/media-queries';

import {Wrapper} from '../../styles/pages/modal';
import {Button3b} from '../../styles/components/buttons';

export const StyledWrapper = styled(Wrapper)`
  background-color: ${p =>
    p.$isError ? p.theme.tertiaryAltColor : p.theme.primaryColor};
  width: 60rem;
  ${media.smaller`
    height: ${p => (p.$isNotFullScreen ? 'auto' : '100%')};
    > div {
      padding: ${p => (p.$isNotFullScreen ? '2rem' : 'auto')};;
    }
  `};
`;

export const Content = styled.div`
  background-color: ${p => p.theme.tertiaryBgColor};
  color: ${p => p.theme.textColor};
  align-items: center;
  display: flex;
  height: 100%;
  max-height: ${p => (p.$maxHeight ? '100%' : 'auto')};
`;

export const TextWrapper = styled.div`
  padding: 2rem;
  text-align: center;
  line-height: 2.4rem;
  font-size: 1.6rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  ${media.smaller`
    line-height: 2rem;
    padding: 0;
  `};
`;

export const StandardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  height: 100%;
  padding: 1rem 0;
`;

export const TopWrapper = styled(StandardWrapper)`
  flex-grow: 1;
`;

export const BottomWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  background-color: ${p => p.theme.tertiaryBgColor};
`;

export const Title = styled.div`
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
  color: ${p => (p.$isError ? p.theme.tertiaryAltColor : p.theme.primaryColor)};
`;

export const MemoText = styled.div`
  font-size: 1.2rem;
  color: ${p => p.theme.textColor};
`;

export const TitleTail = styled.span`
  text-transform: capitalize;
  font-weight: bold;
`;

export const BranchesWrapper = styled(TextWrapper)`
  ${media.smaller`
    padding: 2rem;
  `};
`;

export const DetailsContent = styled.div`
  padding-bottom: 2rem;
  flex-grow: 1;
`;

export const ChangePaymentInfo = styled.div`
  padding: 2rem 0;
`;

export const SubContent = styled.div`
  margin: 1rem;
  line-height: 2.2rem;
  ${media.smaller`
    display: flex;
    flex-direction: column;
    align-items: center;
  `};
`;

export const ExtraContent = styled.div`
  text-align: left;
  ${media.smaller`
    margin-left: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  `};
`;

export const LinkBreak = styled.div`
  min-height: 1rem;
`;

export const Uppercase = styled.div`
  text-transform: uppercase;
`;

export const SubContentBlock = styled.div`
  width: 28rem;
  text-align: left;
  ${media.smaller`
      width: 22rem;
      margin-bottom: 2rem;
  `};
`;

export const ErrorButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  cursor: pointer;
  font-size: 1.4rem;
  font-weight: bold;
  text-transform: uppercase;
  height: 4rem;
  min-height: 4rem;
  color: ${p => p.theme.tertiaryAltColor};
  border: ${p => `0.2rem solid ${p.theme.tertiaryAltColor}`};
  background-color: ${p =>
    p.$isLoading ? p.theme.tertiaryAltColor : 'transparent'};

  &:hover {
    color: ${p => p.theme.primaryTextColor};
    background-color: ${p => p.theme.tertiaryAltColor};
    border-color: ${p => p.theme.tertiaryAltColor};
  }

  margin: 1rem;
  width: 14rem;
  ${media.smaller`
    ${p =>
      p.$wideButton &&
      `width: auto;
       padding: 0 1rem;`}`}
`;

export const Branches = styled.div`
  margin: 1rem;
  width: 100%;
`;

export const OK = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  cursor: pointer;
  background-color: transparent;
  font-size: 1.4rem;
  font-weight: bold;
  text-transform: uppercase;
  height: 4rem;
  min-height: 4rem;
  margin: ${p => (p.$isNesting ? '0 1rem' : '1rem')};
  border-width: 0.2rem;
  border-style: solid;
  width: ${p => (p.$wideButton ? '26rem' : '14rem')};
  color: ${p => (p.$isError ? p.theme.tertiaryAltColor : p.theme.primaryColor)};
  border-color: ${p =>
    p.$isError ? p.theme.tertiaryAltColor : p.theme.primaryColor};

  &:hover {
    background-color: ${p =>
      p.$isError ? p.theme.tertiaryAltColor : p.theme.primaryColor};
    border-color: ${p =>
      p.$isError ? p.theme.tertiaryAltColor : p.theme.primaryColor};
    color: ${p => p.theme.primaryTextColor};
  }

  ${media.smaller`
    ${p =>
      p.$wideButton &&
      `width: auto;
       padding: 0 1rem;`}`}
`;

export const NarrowButton = styled(OK)`
  width: 7rem;
  margin: 0.5rem;
`;

export const Cancel = styled(OK)`
  width: 14rem;
`;

export const LongButton = styled(Button3b)`
  margin: 1rem;
  width: 20rem;
  line-height: 1.6rem;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-grow: 0;
  ${media.smaller`
    ${p => p.$wideButtons && 'flex-direction: column'};
  `};
`;

export const Link = styled.a`
  text-decoration: none;
  font-weight: bold;
  color: ${p => p.theme.primaryColor};
`;

export const BoldText = styled.span`
  font-weight: bold;
`;

export const CameraFrame = styled.video`
  width: 90%;
  margin: 1rem 0;
`;

export const CanvasFrame = styled.canvas`
  width: 32rem;
  height: 24rem;
`;

export const LongButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
export const FullWideButton = styled(OK)`
  width: auto;
  height: 4.4rem;
  line-height: 1.6rem;
`;

export const OffersBanner = styled.img`
  width: 54.8rem;
  height: 16.6rem;
  max-width: 100%;
  margin: 2rem;
  ${media.smaller`
    margin: 1rem;
    height: 11.6rem
  `};
`;
