import initialState from './initial.state';
import * as types from '../actions/types.action';

export default function adyenReducer(state = initialState.adyen, action) {
  switch (action.type) {
    case types.AUTHORIZE_ADYEN_PAYMENT_SUCCESS:
    case types.AUTHORIZE_ADYEN_GIVING_PAYMENT_SUCCESS:
      return action.adyen;
    case types.AUTHORIZE_ADYEN_PAYMENT_REQUESTED:
    case types.AUTHORIZE_ADYEN_PAYMENT_FAILED:
    case types.AUTHORIZE_ADYEN_GIVING_PAYMENT_FAILED:
      return null;
    default:
      return state;
  }
}
