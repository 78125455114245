import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import {getBasket, reOrderBasket} from '../../actions/basket.action';
import {setSetting} from '../../actions/settings.action';
import {resetError} from '../../actions/error.action';
import {
  getOrderHistory,
  cancelOrder,
  trackOrder,
  resetTrackOrder,
  reAuthOrderPayment,
} from '../../actions/order.action';
import {toggleSwitchAccount} from '../../actions/modal.action';
import {
  Wrapper,
  Head,
  Title,
  SubTitle,
  Content,
  ButtonWrapper,
  UserInfo,
} from '../../styles/pages/pages';
import {
  SubTitleWrapper,
  Row,
  Container,
  OrderLinkButton,
  Error,
  PaginationWrapper,
} from './OrdersPage.style';
import Order from './Order';
import {ExtraWrapper} from '../../styles/components/wrapper';
import Loading from '../../components/Loading/Loading';
import PopupModal from '../../components/PopupModal/PopupModal';
import ReOrderBasketModal from '../../components/PopupModal/ReOrderBasketModal';
import {
  AX_BASKET_ERRORS,
  CONTENT_SITE,
  ROUTES,
} from '../../constants/constants';
import UnauthInfoPanel from '../../components/UnauthInfoPanel/UnauthInfoPanel';
import {editOrder, setCurrentRoute} from '../../actions';
import EditOrderBasketModal from '../../components/PopupModal/EditOrderBasketModal';
import Invoice from './Invoice';
import InvoicesPDFModal from '../../components/PopupModal/InvoicesPDFModal';
import Pagination from '../../components/Pagination/Pagination';
import {resetNextRoute} from '../../actions/nextRoute.action';
import OffersModal from '../../components/PopupModal/OffersModal';
import {setPrevRoute} from '../../actions/prevRoute.action';

class OrdersPage extends PureComponent {
  static propTypes = {
    basket: PropTypes.object,
  };
  state = {
    reOrdering: false,
    editing: false,
    showReOrderWarningModal: false,
    showEditOrderWarningModal: false,
    showTrackingErrorModal: false,
    orderId: null,
    showCurrentOrders: true,
    showNewOfferModal: null,
  };
  settingsUpdated = false;
  prevBasketItems = [];

  componentDidMount() {
    const {getOrderHistory, auth, setCurrentRoute, setPrevRoute} = this.props;
    setCurrentRoute(ROUTES.ORDERS);
    setPrevRoute(ROUTES.ORDERS);
    if (auth && auth.c_account) {
      getOrderHistory(auth.c_account);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      getOrderHistory,
      auth,
      history,
      loading,
      basket,
      errors,
      cancellingOrder,
      nextRoute,
      resetNextRoute,
    } = this.props;
    if (
      (prevProps.auth !== auth && auth && auth.c_account) ||
      (prevProps.loading.cancellingOrder && !loading.cancellingOrder)
    ) {
      getOrderHistory(auth.c_account);
    }
    if (nextRoute) {
      resetNextRoute();
      history.push({
        pathname: nextRoute,
        state: {
          isReAuth: true,
        },
      });
    }
    if (errors && errors.reOrderServerError) {
      this.setState({reOrdering: false, showReOrderWarningModal: false});
    }
    if (errors && errors.editOrderServerError) {
      this.setState({editing: false, showEditOrderWarningModal: false});
    }
    if (errors && errors.trackOrder) {
      this.setState({showTrackingErrorModal: true});
    }
    if (
      errors &&
      (errors.basketAlreadyPicked ||
        errors.axBasketNotFound ||
        errors.basketNotEditable)
    ) {
      this.setState({editing: false});
    }
    if (
      basket &&
      basket.uuid &&
      (this.state.reOrdering || this.state.editing)
    ) {
      // re-ordering or edit order started, but settings not yet updated, so we update settings
      if (!this.settingsUpdated && prevProps.basket) {
        this.settingsUpdated = true;
        this.prevBasketItems = prevProps.basket.items;
      }
      // once settings and basket updated, we redirect user to basket page to show the re-ordered/edited basket
      if (
        this.settingsUpdated &&
        prevProps.basket &&
        prevProps.basket.uuid === basket.uuid
      ) {
        history.push('/basket');
      }
    }
    if (
      prevProps.cancellingOrder &&
      !cancellingOrder &&
      !errors.cancelOrderServerError &&
      auth &&
      auth.c_account
    ) {
      getOrderHistory(auth.c_account);
    }
  }

  reOrder = withCurrentBasket => {
    // TODO use withCurrentBasket if "Add to the current basket" - when BE ready
    const {reOrderBasket, cleanBasket} = this.props;
    cleanBasket();
    reOrderBasket(this.state.orderId);
    this.setState({reOrdering: true});
  };

  cancelOrder = () => {
    const {cancelOrder} = this.props;
    cancelOrder(this.state.orderId);
    this.hideCancelOrderWarningModal();
  };

  editOrder = () => {
    const {editOrder, cleanBasket} = this.props;
    this.hideEditOrderWarningModal();
    cleanBasket();
    editOrder(this.state.orderId);
    this.setState({editing: true});
  };

  reOrderAddBasket = () => this.reOrder(true);

  hideReOrderWarningModal = () =>
    this.setState({showReOrderWarningModal: false});

  hideInvoicesModal = () =>
    this.setState({showInvoicesPDFModal: false, invoices: null});

  hideEditOrderWarningModal = () =>
    this.setState({showEditOrderWarningModal: false});

  showReOrderWarningModalFunc = orderId => {
    const {basket, modal} = this.props;
    if (
      this.state.showNewOfferModal === null &&
      modal.newOffers === undefined
    ) {
      this.setState({showNewOfferModal: true, orderId});
      return;
    }
    if (orderId) {
      this.setState({orderId});
    }
    if (basket) {
      this.setState({showReOrderWarningModal: true});
    } else {
      this.reOrder(this.state.orderId);
    }
  };

  viewNewOffer = () => {
    this.setState({showNewOfferModal: false});
    this.props.history.push(CONTENT_SITE.MIX_N_SAVE);
  };

  showEditOrderWarningModal = order => {
    this.setState(
      {orderId: order.orderId, cutOffDateTime: order.cutOffDateTime},
      () => {
        this.setState({showEditOrderWarningModal: true});
      }
    );
  };

  showCancelOrderWarningModal = orderId => {
    this.setState({orderId, showCancelOrderWarningModal: true});
  };

  hideCancelOrderWarningModal = () =>
    this.setState({showCancelOrderWarningModal: false});

  openSwitchAccountModal = () => this.props.toggleSwitchAccountModal(true);

  trackOrder = axBasketId => this.props.trackOrder(axBasketId);

  paginate = (pagination, dateFrom, dateTo) => {
    const {getOrderHistory, auth} = this.props;
    if (auth && auth.c_account) {
      getOrderHistory(auth.c_account, dateFrom, dateTo, pagination.page);
    }
  };

  resetFailedToEdit = () => {
    const {resetError, errors, history} = this.props;
    resetError();
    if (
      errors.editOrderServerError === AX_BASKET_ERRORS.FAILED_TO_EDIT_ORDER ||
      errors.editOrderServerError === AX_BASKET_ERRORS.STARTED_PICKING
    ) {
      history.push('/');
    } else {
      window && window.location && window.location.reload();
    }
  };

  viewPdf = invoices =>
    this.setState({
      invoices,
      showInvoicesPDFModal: true,
    });

  goToOrder = (axBasketId, state, isInvoiced) => {
    const url = isInvoiced ? '/invoicedOrder' : '/order';
    this.props.history.push({
      pathname: `${url}/${axBasketId}`,
      state,
    });
  };

  toggleCurrentOrders = () =>
    this.setState({showCurrentOrders: !this.state.showCurrentOrders});

  closeNewOfferModal = () => {
    this.setState({showNewOfferModal: false});
    this.showReOrderWarningModalFunc();
  };

  render() {
    const {
      orderHistory,
      loading,
      errors,
      accounts,
      resetError,
      auth,
      relogin,
      basket,
      openStopEditModal,
      reAuthOrderPayment,
    } = this.props;
    const {
      showReOrderWarningModal,
      showEditOrderWarningModal,
      reOrdering,
      editing,
      showCancelOrderWarningModal,
      showCurrentOrders,
      showInvoicesPDFModal,
      showNewOfferModal,
    } = this.state;
    if (!auth) {
      return <UnauthInfoPanel callback={relogin} loading={loading.auth} />;
    }

    const hasActiveOrders =
      orderHistory &&
      orderHistory._embedded &&
      orderHistory._embedded.activeOrders &&
      orderHistory._embedded.activeOrders.length > 0;

    const hasInvoices =
      orderHistory &&
      orderHistory._embedded &&
      orderHistory._embedded.invoices &&
      Object.keys(orderHistory._embedded.invoices).length > 0;

    const renderReOrderWarningModal = showReOrderWarningModal && (
      <ReOrderBasketModal
        newBasketCallback={this.reOrder}
        addBasketCallback={this.reOrderAddBasket}
        showCancelButton={true}
        showOKButton={true}
        cancelCallBack={this.hideReOrderWarningModal}
        closeModalCallback={this.hideReOrderWarningModal}
        showModal={showReOrderWarningModal}
        isLoadingModal={this.state.reOrdering}
      />
    );
    const renderEditOrderWarningModal = showEditOrderWarningModal && (
      <EditOrderBasketModal
        newBasketCallback={this.editOrder}
        showCancelButton={true}
        showOKButton={true}
        cancelCallBack={this.hideEditOrderWarningModal}
        closeModalCallback={this.hideEditOrderWarningModal}
        showModal={showEditOrderWarningModal}
        isLoadingModal={this.state.editing}
        cutOffDateTime={this.state.cutOffDateTime}
      />
    );
    const renderLoadingModal = reOrdering && (
      <PopupModal
        modalName="Creating Edit Order"
        title={'Please wait'}
        content={'While we create your basket'}
        showModal={reOrdering}
        isError={false}
        isLoadingModal={true}
      />
    );
    const renderLoadingEditOrderModal = editing && (
      <PopupModal
        modalName="Loading Edit Order"
        title={'Please wait'}
        content={'While we create your basket'}
        showModal={editing}
        isError={false}
        isLoadingModal={true}
      />
    );

    const renderErrorReOrderModal = errors && errors.reOrderServerError && (
      <PopupModal
        modalName="ReOrder Error"
        title={'Error'}
        content={'Failed to reorder, please try again later'}
        showModal={errors.reOrderServerError}
        isError={true}
        callback={resetError}
      />
    );

    const renderErrorEditOrderModal = errors && errors.editOrderServerError && (
      <PopupModal
        modalName="Edit Order Error"
        title={'Error'}
        content={errors.editOrderServerError}
        showOKButton={true}
        showModal={errors.editOrderServerError}
        isError={true}
        callback={this.resetFailedToEdit}
      />
    );

    const renderErrorUpdateSettingsModal = errors && errors.updateSettings && (
      <PopupModal
        modalName="Update Order Error"
        title={'Error'}
        content={'Failed to update settings, please try again'}
        showModal={errors.updateSettings}
        isError={true}
        callback={resetError}
      />
    );

    const renderCancelOrderWarningModal = showCancelOrderWarningModal && (
      <PopupModal
        modalName="Cancel Order Warning"
        title={'Cancel Order'}
        content={'Are you sure you wish to cancel this order?'}
        showModal={showCancelOrderWarningModal}
        okButtonText={'Yes'}
        cancelButtonText={'No'}
        isLoadingModal={false}
        showOkButton={true}
        showCancelButton={true}
        cancelCallBack={this.hideCancelOrderWarningModal}
        callback={this.cancelOrder}
      />
    );
    const renderCancelOrderLoadingModal = loading &&
      loading.cancellingOrder && (
        <PopupModal
          modalName="Canceling Order Error"
          title={'Please wait'}
          content={'While we cancel your order'}
          showModal={loading.cancellingOrder}
          isError={false}
          isLoadingModal={true}
        />
      );

    const renderCancelOrderErrorModal = errors &&
      errors.cancelOrderServerError && (
        <PopupModal
          modalName="Cancel Order Error"
          title={'Error'}
          content={'Failed to cancel order, please try again later'}
          showModal={errors.cancelOrderServerError}
          isError={true}
          callback={resetError}
        />
      );

    const renderTrackingErrorModal = errors && errors.trackOrder && (
      <PopupModal
        modalName="Trace Order Error"
        title={'Sorry'}
        content={'No tracking information available for this order'}
        showModal={errors.trackOrder}
        isError={false}
        okButtonText={'Ok'}
        callback={resetError}
      />
    );

    const renderInvoicesPDFModal = showInvoicesPDFModal && (
      <InvoicesPDFModal
        invoices={this.state.invoices}
        showModal={showInvoicesPDFModal}
        callback={this.hideInvoicesModal}
        accessToken={auth && auth.access_token}
      />
    );

    const renderHistoryError = errors && errors.getOrderHistoryFailed && (
      <Error>Failed to get order history, please try again later</Error>
    );

    const renderActiveError = errors && errors.getOrderActiveFailed && (
      <Error>Failed to get active orders, please try again later</Error>
    );

    const renderCurrentOrders = hasActiveOrders && showCurrentOrders && (
      <Row>
        {orderHistory._embedded.activeOrders.map(order => {
          return (
            <Order
              key={order && order.axBasketId}
              order={order}
              branchList={this.props.branchList}
              reOrder={this.showReOrderWarningModalFunc}
              cancelOrder={this.showCancelOrderWarningModal}
              editOrder={this.showEditOrderWarningModal}
              trackOrder={this.trackOrder}
              reAuthOrderPayment={reAuthOrderPayment}
              editing={
                basket && order && basket.axBasketId === order.axBasketId
              }
              openStopEditModal={openStopEditModal}
              goToOrder={this.goToOrder}
            />
          );
        })}
      </Row>
    );

    const renderActiveOrders = hasActiveOrders && (
      <Container>
        <Row onClick={this.toggleCurrentOrders} $showCursor={true}>
          <SubTitleWrapper>
            <SubTitle>My current orders</SubTitle>
          </SubTitleWrapper>
        </Row>
        {renderCurrentOrders}
      </Container>
    );

    const groupedInvoices =
      hasInvoices &&
      orderHistory &&
      orderHistory._embedded &&
      orderHistory._embedded.invoices;

    const renderPagination = orderHistory &&
      orderHistory.page &&
      orderHistory.page.totalPages > 1 && (
        <Pagination
          pages={orderHistory.page}
          updateSearchParams={this.paginate}
        />
      );

    const renderInvoices = hasInvoices && (
      <Container $isLoading={loading && loading.orderHistory}>
        <Row>
          <SubTitleWrapper>
            <SubTitle>My previous orders</SubTitle>
          </SubTitleWrapper>
        </Row>
        <Row>
          {groupedInvoices &&
            Object.keys(groupedInvoices).map(invoiceKey => {
              if (groupedInvoices[invoiceKey][0].salesId) {
                return (
                  <Invoice
                    invoices={groupedInvoices[invoiceKey]}
                    key={invoiceKey}
                    auth={auth}
                    reOrder={this.showReOrderWarningModalFunc}
                    viewPdf={this.viewPdf}
                    goToOrder={this.goToOrder}
                  />
                );
              }
              if (groupedInvoices[invoiceKey][0].axBasketId) {
                return (
                  <Order
                    order={groupedInvoices[invoiceKey][0]}
                    key={invoiceKey}
                    branchList={this.props.branchList}
                    reOrder={this.showReOrderWarningModalFunc}
                    isPreviousOrder={true}
                  />
                );
              }
            })}
          <PaginationWrapper>{renderPagination}</PaginationWrapper>
        </Row>
      </Container>
    );

    const renderNoOrdersFound = loading &&
      !loading.orderHistory &&
      !hasInvoices &&
      !hasActiveOrders &&
      errors &&
      !errors.getOrderHistoryFailed &&
      !errors.getOrderActiveFailed && (
        <Row>
          <Error>You have no previous orders</Error>
        </Row>
      );
    const renderLoading = loading && loading.orderHistory && (
      <Loading isLight={false} />
    );
    const renderSwitchAccount = accounts && accounts.length > 1 && (
      <OrderLinkButton onClick={this.openSwitchAccountModal}>
        Change Account
      </OrderLinkButton>
    );
    const renderNewOffersModal = showNewOfferModal && (
      <OffersModal
        showModal={true}
        callback={this.viewNewOffer}
        closeModalCallback={this.closeNewOfferModal}
      />
    );
    const renderHistoryContent = ((loading && loading.orderHistory) ||
      hasActiveOrders ||
      hasInvoices) && (
      <Content>
        {renderLoading}
        {renderActiveOrders}
        {renderInvoices}
      </Content>
    );
    const c_account = auth && auth.c_account;
    const displayName = auth && auth.display_name;
    return (
      <Wrapper>
        {renderLoadingModal}
        {renderReOrderWarningModal}
        {renderNewOffersModal}
        {renderEditOrderWarningModal}
        {renderLoadingEditOrderModal}
        {renderErrorReOrderModal}
        {renderErrorEditOrderModal}
        {renderErrorUpdateSettingsModal}
        {renderCancelOrderWarningModal}
        {renderCancelOrderLoadingModal}
        {renderCancelOrderErrorModal}
        {renderTrackingErrorModal}
        {renderInvoicesPDFModal}
        <Head>
          <Title>My orders</Title>
          <ButtonWrapper>
            <UserInfo>
              Showing orders for account {c_account} - {displayName}
            </UserInfo>
            {renderSwitchAccount}
          </ButtonWrapper>
        </Head>
        {renderNoOrdersFound}
        {renderActiveError}
        {renderHistoryError}
        {renderHistoryContent}
        <ExtraWrapper>
          <OrderLinkButton to="/">Continue shopping</OrderLinkButton>
        </ExtraWrapper>
      </Wrapper>
    );
  }
}

const mapStateToProps = state => {
  return {
    settings: state.settings,
    branch: state.branch,
    accounts: state.accounts,
    branchList: state.branchList,
    basket: state.basket,
    orderHistory: state.orderHistory,
    order: state.order,
    loading: state.loading,
    auth: state.auth,
    errors: state.errors,
    nextRoute: state.nextRoute,
    modal: state.modal,
  };
};

const mapDispatchToProps = dispatch => ({
  reOrderBasket: bindActionCreators(reOrderBasket, dispatch),
  editOrder: bindActionCreators(editOrder, dispatch),
  getBasket: bindActionCreators(getBasket, dispatch),
  getOrderHistory: bindActionCreators(getOrderHistory, dispatch),
  cancelOrder: bindActionCreators(cancelOrder, dispatch),
  toggleSwitchAccountModal: bindActionCreators(toggleSwitchAccount, dispatch),
  setSetting: bindActionCreators(setSetting, dispatch),
  resetError: bindActionCreators(resetError, dispatch),
  trackOrder: bindActionCreators(trackOrder, dispatch),
  resetTrackOrder: bindActionCreators(resetTrackOrder, dispatch),
  reAuthOrderPayment: bindActionCreators(reAuthOrderPayment, dispatch),
  resetNextRoute: bindActionCreators(resetNextRoute, dispatch),
  setCurrentRoute: bindActionCreators(setCurrentRoute, dispatch),
  setPrevRoute: bindActionCreators(setPrevRoute, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrdersPage);
