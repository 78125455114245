import React from 'react';
import {
  PromoText,
  PromoWrapper,
  TextWrapper,
  BubbleOffer,
  PromoLink,
  MultiBuy,
  PromoMin,
  PromoPlus,
  MultiBuyText,
  MultiBuyPrice,
} from './Promotion.style';
import {MultiBuyOffer, ExtraPromoSmaller} from '../Product/Product.style';
import {getFromPrice, getPromoString} from '../../helpers/data.helper';
import {OFFER_TYPES} from '../../constants/constants';
import {getMixAndSaveUrl} from '../../helpers/search.helper';

export default props => {
  const {
    alignLeft,
    offer,
    collectionPrice,
    isListMode,
    isOneLine,
    isPureText,
    hasMixOffer,
    isPbbOffer,
    promo,
  } = props;
  const promoText =
    offer && getPromoString(offer.promoTagId, offer.promoDisAmt);
  const isMultiBuyOffer = !!offer && offer.promoMinQty && offer.promoMinQty > 1;
  const getFromPriceText =
    offer && getFromPrice(collectionPrice, offer.promoDisAmt);
  const renderBubble = !isOneLine && !!getFromPriceText && (
    <BubbleOffer $hasOffer={!!getFromPriceText}>{getFromPriceText}</BubbleOffer>
  );
  const renderMultiBuyBubble = isMultiBuyOffer && !isPureText && (
    <BubbleOffer $hasOffer={true}>
      <MultiBuy>
        <MultiBuyText>
          Buy<PromoMin>{offer.promoMinQty}</PromoMin>
          <PromoPlus>+</PromoPlus>
        </MultiBuyText>
        <MultiBuyPrice>£{offer.promoPrice}</MultiBuyPrice>
      </MultiBuy>
    </BubbleOffer>
  );
  const renderMultiBuyDiscount = isMultiBuyOffer &&
    !hasMixOffer &&
    !isPureText &&
    !promoText &&
    !!offer &&
    offer.onOffer && (
      <MultiBuyOffer>
        Buy {offer.promoMinQty}+{' '}
        <ExtraPromoSmaller>£{offer.promoPrice}</ExtraPromoSmaller>
      </MultiBuyOffer>
    );
  const renderCollectionOnly = isMultiBuyOffer && !isPureText && !hasMixOffer && (
    <>
      <PromoText $hasOffer={true}>Collection</PromoText>
      <PromoText $hasOffer={true}>Only</PromoText>
    </>
  );
  const renderPromoText1 = !isMultiBuyOffer && !hasMixOffer && (
    <PromoText $isOneLine={isOneLine}>{promoText && promoText.prom1}</PromoText>
  );
  const renderPromoText2 = !isMultiBuyOffer && !hasMixOffer && (
    <PromoText $isOneLine={isOneLine}>{promoText && promoText.prom2}</PromoText>
  );
  const renderMultiBuyText = isMultiBuyOffer && isPureText && !hasMixOffer && (
    <PromoText $isOneLine={isOneLine}>
      Buy {offer.promoMinQty}+ COLLECTION £{offer.promoPrice}
    </PromoText>
  );
  const renderPbbOffer = isPbbOffer && (
    <PromoText $isOneLine={isOneLine}>{promo}</PromoText>
  );
  const renderMixNSave = hasMixOffer && (
    <PromoLink
      to={getMixAndSaveUrl(offer.promoTag || OFFER_TYPES.MIX, offer.promoTagId)}
    >
      <PromoText $isOneLine={true}>
        {offer.promoTag || OFFER_TYPES.MIX}
      </PromoText>
    </PromoLink>
  );
  return (
    <PromoWrapper
      $alignLeft={alignLeft}
      data-id="promotionSection"
      $isListMode={isListMode}
      $isOneLine={isOneLine}
    >
      {renderBubble}
      {renderMultiBuyBubble}
      <TextWrapper $isOneLine={isOneLine}>
        {renderMultiBuyDiscount}
        {renderMixNSave}
        {renderPromoText1}
        {renderPromoText2}
        {renderMultiBuyText}
        {renderCollectionOnly}
        {renderPbbOffer}
      </TextWrapper>
    </PromoWrapper>
  );
};
