import React, {PureComponent} from 'react';
import moment from 'moment';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {captureMessage, withScope} from '@sentry/browser';
import popularUrl from '../../images/popular.png';

import {
  OfferWrapper,
  Like,
  OfferText,
  AddButton,
  LoadingWrapper,
  QuantityWrapper,
  TopButtonGroup,
  NewProduct,
  OutOfStockButtonText,
  SubQuantityWrapper,
  AvailableFromButtonText,
  PromoWrapper,
} from './Product.style';
import Loading from '../Loading/Loading';
import Quantity from '../Quantity/Quantity';
import {GoogleAnalyticsHelper} from '../../helpers/googleAnalytics.helper';
import {SmallProduct} from './SmallProduct';
import Favorite from '../Favorite/Favorite';
import {
  ANALYTICS_EVENTS,
  OFFER_END_DATE_FULL_FORMAT,
  ERRORS,
  OFFER_ENDS_DATE_FORMAT_SHORT,
  SERVICE_TYPE,
  DEFAULT_DEBOUNCE_TIME,
  STOCK_CHECK,
  PROMO_TAGS,
  PROMO_TAGS_MAP,
  OFFER_TYPES,
} from '../../constants/constants';
import {addFavourite, removeFavourite} from '../../actions';
import PreviouslyPurchased from '../PreviouslyPurchased/PreviouslyPurchased';
import {ListProduct} from './ListProduct';
import Promotion from '../Promotion/Promotion';
import ToolTipsImage from '../ToolTipsImage/ToolTipsImage';
import {
  getCollectionUnitPrice,
  getProductMax,
  getProductStep,
} from '../../helpers/product.helper';
import {getBasketItemsCodes, getProductItem} from '../../helpers/basket.helper';
import {getCategoryName, getQtyInList} from '../../helpers/array.helper';
import {debounce} from '../../helpers/events.helper';
import CompactQuantity from '../Quantity/CompactQuantity';
import {getAvailableAtDateString} from '../../helpers/string.helper';
import {checkHasMixOffer, getPromoPrice} from '../../helpers/data.helper';
import SentryHelper from '../../helpers/sentry.helper';
import {PromoLink} from '../Promotion/Promotion.style';
import {getMixAndSaveUrl} from '../../helpers/search.helper';

class Product extends PureComponent {
  constructor(props) {
    super(props);
    const {
      fulfillmentType,
      product,
      shoppingListId,
      showShoppingListQuantity,
    } = props;
    const isPbbOffer =
      product &&
      product.offer &&
      product.offer.promoTagId === PROMO_TAGS.Pay_By_Bank;
    const isDelivery = fulfillmentType === SERVICE_TYPE.DELIVERY;
    let desiredQuantity;
    if (
      product &&
      product.shoppingLists &&
      shoppingListId &&
      showShoppingListQuantity
    ) {
      const qty = getQtyInList(product.shoppingLists, shoppingListId);
      if (qty) {
        desiredQuantity = qty;
      }
    }
    this.state = {
      isFavorite: product.favourite,
      loading: false,
      deliveryPrice: null,
      collectionPrice: null,
      step: getProductStep(product, isDelivery),
      max: getProductMax(product, isDelivery),
      defaultUnitPrice: getCollectionUnitPrice(product),
      currency: '£',
      desiredQuantity,
      isPbbOffer,
      discountPrice: getPromoPrice(
        product && product.offer && product.offer.promoDisAmt
      ),
    };
    this.offerDate =
      product &&
      product.offer &&
      moment(product.offer.promoEnd, OFFER_END_DATE_FULL_FORMAT).format(
        OFFER_ENDS_DATE_FORMAT_SHORT
      );

    if (
      !(product.delivery && product.delivery.price) &&
      !(product.collection && product.collection.price)
    ) {
      GoogleAnalyticsHelper.trackEvent(ERRORS.PRICE_NOT_FOUND, {
        product: product.itemId,
      });
    }
  }

  static getDerivedStateFromProps(props) {
    if (props.loading && !props.loading.addingToBasket) {
      return {
        loading: false,
      };
    }
    return null;
  }

  onFavoriteClick = product => {
    this.setState({isFavorite: !this.state.isFavorite});
    if (this.state.isFavorite) {
      this.props.removeFavouriteFunc(product);
      GoogleAnalyticsHelper.trackEvent(ANALYTICS_EVENTS.REMOVE_FAVOURITE, {
        product,
      });
    } else {
      this.props.addFavouriteFunc(product);
      GoogleAnalyticsHelper.trackEvent(ANALYTICS_EVENTS.ADD_FAVOURITE, {
        product,
      });
    }
  };
  addToBasket = e => {
    e.preventDefault();
    const {product, updateBasket, source} = this.props;
    const {loading, desiredQuantity} = this.state;
    if (loading && !product) {
      return;
    }
    if (!this.clickCount) {
      this.clickCount = 0;
    }
    this.clickCount += 1;
    if (!this.state.step) {
      SentryHelper.debugQuantityZero({
        message: `Product.js addToBasket() - qty: ${
          this.state.step
        } (${typeof this.state
          .step}), desiredQuantity: ${desiredQuantity} (${typeof desiredQuantity}), this.clickCount: ${
          this.clickCount
        }`,
      });
    }
    const item = getProductItem(product, this.state.step);
    const analyticsObj = {
      ...{
        product: {
          ...product,
          categoryName: getCategoryName(product),
        },
      },
      quantity: this.state.step,
      source,
    };
    if (!this.debouncedFn) {
      this.debouncedFn = debounce(() => {
        const updateItem = {
          ...item,
          ...{quantity: desiredQuantity || this.clickCount * this.state.step},
        };
        updateBasket(updateItem, analyticsObj);
        this.clickCount = 0;
        this.setState({loading: true});
      }, DEFAULT_DEBOUNCE_TIME);
    }
    this.debouncedFn();
  };

  remove = () => {
    const {removeItem, product, basketHashMap} = this.props;
    const basketItemUuid =
      product &&
      product.itemId &&
      basketHashMap &&
      basketHashMap[product.itemId] &&
      basketHashMap[product.itemId].basketItemUuid;
    if (basketItemUuid) {
      removeItem(basketItemUuid, product);
    } else {
      withScope(scope => {
        scope.setExtra('product', product);
        scope.setExtra('basketHashMap', basketHashMap);
        captureMessage(new Error(ERRORS.REMOVE_ITEM));
      });
    }
  };

  goToProductDetailPage = () => {
    const {product, onProductClick} = this.props;
    if (product && product.itemId && onProductClick) {
      onProductClick(product.itemId);
    }
  };

  onProductNameClick = e => e && e.preventDefault();

  gotoAlternatives = () => {
    const {product, substitutes, keywordSearch} = this.props;
    const query =
      substitutes &&
      product &&
      product.itemId &&
      getBasketItemsCodes(substitutes[product.itemId]);
    if (!query) {
      return;
    }
    GoogleAnalyticsHelper.trackEvent(ANALYTICS_EVENTS.SEARCH_ALTERNATIVES, {
      itemId: product.itemId,
      query,
    });
    keywordSearch(query);
  };

  removeDesiredQty = () => {
    const {removeItemFromShoppingList, shoppingListId, product} = this.props;
    removeItemFromShoppingList(shoppingListId, product.itemId);
  };
  updateDesiredQty = qty => {
    const {shoppingListId, product, updateItemFromShoppingList} = this.props;
    if (!qty) {
      SentryHelper.debugQuantityZero({
        message: `Product.js updateDesiredQty() - qty: ${qty} (${typeof qty})`,
      });
    }
    this.setState({desiredQuantity: qty});
    updateItemFromShoppingList(shoppingListId, product.itemId, qty);
  };

  render() {
    const {
      product,
      isListView,
      updateItem,
      isSmallView,
      auth,
      loading,
      deviceInfo,
      fulfillmentType,
      basketHashMap,
      substitutes,
      showShoppingListQuantity,
      isLimitedSpace,
    } = this.props;
    const {
      desiredQuantity,
      isPbbOffer,
      discountPrice,
      currency,
      defaultUnitPrice,
    } = this.state;
    const {delivery, collection} = product;
    const stockCheck =
      basketHashMap &&
      basketHashMap[product.itemId] &&
      basketHashMap[product.itemId].stockCheck;
    const isOOs =
      stockCheck &&
      (stockCheck.result === STOCK_CHECK.OUT_OF_STOCK ||
        stockCheck.result === STOCK_CHECK.DISCONTINUED);
    const isDiscontinued =
      stockCheck && stockCheck.result === STOCK_CHECK.DISCONTINUED;
    const isNotAllowed =
      stockCheck && stockCheck.result === STOCK_CHECK.NOT_ALLOWED;
    const isSubstituteAvailable =
      substitutes &&
      substitutes[product.itemId] &&
      substitutes[product.itemId].length > 0;
    const unitPrice =
      basketHashMap &&
      basketHashMap[product.itemId] &&
      basketHashMap[product.itemId].unitPrice;
    const renderOosTextContent = isDiscontinued
      ? 'Discontinued'
      : isNotAllowed
      ? 'Not Allowed'
      : 'Out of Stock';
    const isListMode = isListView || (deviceInfo && deviceInfo.isPhone);
    const isSmallViewMode =
      isSmallView && product && !(deviceInfo && deviceInfo.isPhone);
    const hasPromoText =
      product &&
      product.offer &&
      product.offer.promoDiscountText &&
      product.offer.promoDiscountText.length > 0;
    const renderMixNSaveTag =
      (product && product.offer && product.offer.promoTag) || OFFER_TYPES.MIX;
    const renderMixNSavePromoTagId =
      product && product.offer && product.offer.promoTagId;
    const hasMixOffer = checkHasMixOffer(product);
    const isNewProduct = product && (product.new || product.isNew);
    const isOffer = product && product.offer && product.offer.promoEnd;
    const renderPromo = !hasMixOffer && (
      <Promotion
        offer={product && product.offer}
        promo={product && product.offer && product.offer.promoDiscountText}
        collectionPrice={
          product && product.collection && product.collection.price
        }
        unitPrice={getCollectionUnitPrice(product)}
        isListMode={isListMode && !isSmallView}
      />
    );
    const renderPromotion = isOffer && (
      <PromoWrapper>{renderPromo}</PromoWrapper>
    );
    const renderIsNewProduct = isNewProduct && (
      <NewProduct $isOffer={isOffer}>NEW</NewProduct>
    );
    const renderOfferDate = isOffer && !hasMixOffer && (
      <OfferText $isLimitedSpace={isLimitedSpace} $isNew={isNewProduct}>
        Offer ends {this.offerDate}
      </OfferText>
    );
    const renderOfferMixSave = hasMixOffer && (
      <PromoLink
        to={getMixAndSaveUrl(renderMixNSaveTag, renderMixNSavePromoTagId)}
      >
        <OfferText $isLimitedSpace={isLimitedSpace} $isNew={isNewProduct}>
          {hasMixOffer ? renderMixNSaveTag : 'Offer ends '}
        </OfferText>
      </PromoLink>
    );
    const renderButtonLoading = this.state.loading && (
      <LoadingWrapper>
        <Loading isLight={true} />
      </LoadingWrapper>
    );
    const renderButtonContent =
      !this.state.loading &&
      `Add ${desiredQuantity ? desiredQuantity : 'item'}`;

    const renderAddButton =
      !isOOs &&
      (product && product.basketQuantity > -1 ? (
        <QuantityWrapper $hasPromoText={hasPromoText}>
          <Quantity
            quantity={product.basketQuantity}
            update={updateItem}
            uuid={product.basketItemUuid}
            remove={this.remove}
            step={this.state.step}
            max={this.state.max}
            loading={loading}
            stockCheck={stockCheck}
            unitPrice={unitPrice}
          />
        </QuantityWrapper>
      ) : (
        <AddButton
          onClick={this.addToBasket}
          $isLoading={this.state.loading}
          data-rw="product--add-button"
        >
          {renderButtonLoading}
          {renderButtonContent}
        </AddButton>
      ));

    const renderDesireQtyButton = !!showShoppingListQuantity && (
      <SubQuantityWrapper data-rw="desire-qty-button">
        <CompactQuantity
          quantity={desiredQuantity}
          remove={this.removeDesiredQty}
          update={this.updateDesiredQty}
          step={this.state.step}
        />
      </SubQuantityWrapper>
    );

    const renderAlternativesButton = isOOs && isSubstituteAvailable && (
      <AddButton
        onClick={this.gotoAlternatives}
        $isLoading={this.state.loading}
        data-rw="product--alternatives-button"
      >
        Alternatives
      </AddButton>
    );
    const renderOOsText = isOOs &&
      !isSubstituteAvailable &&
      !stockCheck.availableAt && (
        <OutOfStockButtonText>{renderOosTextContent}</OutOfStockButtonText>
      );

    const renderAdvancedText = isOOs &&
      !isSubstituteAvailable &&
      !!stockCheck.availableAt && (
        <AvailableFromButtonText>
          {getAvailableAtDateString(stockCheck.availableAt)}
        </AvailableFromButtonText>
      );

    const renderLike = !!auth && (
      <Like onClick={() => this.onFavoriteClick(product)}>
        <Favorite
          isOffer={!!product.offer}
          isFavorite={this.state.isFavorite}
        />
      </Like>
    );

    const renderPreviouslyPurchased = product.previouslyPurchased && (
      <PreviouslyPurchased isOffer={!!product.offer} />
    );

    const renderPopular = (product.popular || product.isPopular) && (
      <ToolTipsImage url={popularUrl} toolTipsContent={'Popular'} />
    );
    const renderOfferTab = (
      <OfferWrapper $isOffer={!!product.offer || hasMixOffer}>
        {renderIsNewProduct}
        {renderOfferDate}
        {renderOfferMixSave}
        <TopButtonGroup>
          {renderPopular}
          {renderPreviouslyPurchased}
          {renderLike}
        </TopButtonGroup>
      </OfferWrapper>
    );
    const hasProductOffer = !!product && !!product.offer;
    const renderOfferTitle =
      (product &&
        product.offer &&
        product.offer.promoTagId &&
        PROMO_TAGS_MAP[product.offer.promoTagId]) ||
      'offer';
    if (isSmallViewMode) {
      return (
        <SmallProduct
          product={product}
          currency={currency}
          updateItem={updateItem}
          deliveryPrice={delivery && delivery.price}
          collectionPrice={collection && collection.price}
          loading={this.state.loading}
          loadingProps={loading}
          addToBasket={this.addToBasket}
          goToProductDetailPage={this.goToProductDetailPage}
          remove={this.remove}
          step={this.state.step}
          max={this.state.max}
          fulfillmentType={fulfillmentType}
          stockCheck={stockCheck}
          unitPrice={unitPrice}
          isOOs={isOOs}
          oosText={renderOosTextContent}
          renderOfferTab={renderOfferTab}
          hasProductOffer={hasProductOffer}
          discountPrice={discountPrice}
          renderOfferTitle={renderOfferTitle}
        />
      );
    }
    return (
      <ListProduct
        product={product}
        currency={currency}
        updateItem={updateItem}
        deliveryPrice={delivery && delivery.price}
        collectionPrice={collection && collection.price}
        loading={this.state.loading}
        loadingProps={loading}
        addToBasket={this.addToBasket}
        goToProductDetailPage={this.goToProductDetailPage}
        remove={this.remove}
        step={this.state.step}
        max={this.state.max}
        renderOfferTab={renderOfferTab}
        fulfillmentType={fulfillmentType}
        stockCheck={stockCheck}
        unitPrice={unitPrice}
        defaultUnitPrice={defaultUnitPrice}
        isOOs={isOOs}
        oosText={renderOosTextContent}
        isSubstituteAvailable={isSubstituteAvailable}
        gotoAlternatives={this.gotoAlternatives}
        renderDesireQtyButton={renderDesireQtyButton}
        isPbbOffer={isPbbOffer}
        renderPromotion={renderPromotion}
        hasProductOffer={hasProductOffer}
        hasMixOffer={hasMixOffer}
        discountPrice={discountPrice}
        renderOfferTitle={renderOfferTitle}
      />
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  substitutes: state.substitutes,
});

const mapDispatchToProps = dispatch => ({
  addFavouriteFunc: bindActionCreators(addFavourite, dispatch),
  removeFavouriteFunc: bindActionCreators(removeFavourite, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Product);
