import styled, {css} from 'styled-components';
import {media} from '../../styles/components/media-queries';
import {
  Button3Inverted,
  Button3b,
  Button3c,
} from '../../styles/components/buttons';
import {Link2, LinkStyle} from '../../styles/components/links';
import {Content} from '../../styles/pages/pages';
import {ButtonLoadingWrapper} from '../AccountPage/AccountPage.style';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 6rem 0 2rem;
  ${media.smaller`
    margin: 0;
  `};
`;

const theSharingButtonStyle = css`
  width: 19rem;
  padding: 0 1rem;
  margin-right: 1rem;
  margin-bottom: 3rem;
  text-align: center;
  display: flex;
  ${media.small`
    width: 100%;
    margin-top: 1rem;
  `};
`;

export const EditOrder = styled(Button3b)`
  ${theSharingButtonStyle};
`;

export const PaymentOrder = styled(Button3b)`
  line-height: 1.4rem;
  ${theSharingButtonStyle};
  position: relative;

  &:hover {
    svg {
      fill: ${p => p.theme.primaryTextColor};
    }
  }
`;

export const CancelOrder = styled(Button3c)`
  ${theSharingButtonStyle};
  color: ${p =>
    p.$disabled ? p.theme.tertiaryTextColor : p.theme.tertiaryColor};
  border-color: ${p =>
    p.$disabled ? p.theme.tertiaryTextColor : p.theme.tertiaryColor};

  &[disabled] {
    pointer-events: none;
  }
`;

export const TrackOrder = styled(Link2)`
  width: 22rem;
  max-width: 22rem;
  padding: 0 1rem;
  margin-left: 1rem;
  text-align: center;
  display: flex;
  line-height: 1.5rem;
  ${media.small`
    width: 100%;
    max-width: none;
    margin: 1rem 1rem 2rem 0;
  `};
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  ${media.smaller`
    justify-content: space-between;
    padding-top: 2rem;
  `};
  ${media.small`
    padding-top: 0;
    padding-bottom: 2rem;
    margin-bottom: 1rem;
    & > a:first-child {
      margin-left: 1rem;
    }
    & > a:last-child {
      margin-right: 2rem;
    }
  `};
`;

export const ActionButton = styled(Button3Inverted)`
  min-width: 14rem;
  max-width: 14rem;
  padding: ${p => (p.$isShort ? '0 0.5rem' : '0 1rem')};
  margin-right: 1rem;
  text-align: center;
  color: ${p => (p.$isError ? p.theme.tertiaryColor : p.theme.secondaryColor)};
  border: ${p =>
    `solid 0.2rem ${
      p.$isError ? p.theme.tertiaryColor : p.theme.secondaryColor
    }`};

  &:hover {
    background-color: ${p =>
      p.$isError ? p.theme.tertiaryColor : p.theme.secondaryColor};
    border-color: ${p =>
      p.$isError ? p.theme.tertiaryColor : p.theme.secondaryColor};
  }

  ${media.small`
    min-width: 100%;
    max-width: 100%;
    margin-top: 1rem;
    margin-bottom: 1rem;
  `};
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  line-height: 2rem;
  font-size: 1.6rem;
  cursor: ${p => (p.$showCursor ? 'pointer' : 'default')};
`;

export const SubTitleWrapper = styled.div`
  width: 100%;
  padding-bottom: 1rem;
`;

export const Title = styled.span`
  color: ${p => p.theme.secondaryColor};
  text-align: ${p => (p.$left ? 'left' : 'center')};
  font-weight: bold;
  ${media.smaller`
    min-width: 40%;
  `};
`;

export const Container = styled.div`
  padding: 2rem;
  color: ${p => p.theme.secondaryColor};
  opacity: ${p => (p.$isLoading ? 0.5 : 1)};
  ${media.medium`
    min-width: 80%;
  `};
  ${media.small`
    min-width: 100%;
  `};
`;

export const OrderWrapper = styled.div`
  width: 100%;
`;

export const OrderRow = styled.div`
  font-size: 1.4rem;
  width: 100%;
  color: ${p => p.theme.textColor};
  padding: 1rem;
  border-bottom: ${p => `solid 0.1rem ${p.theme.borderColor}`};
  display: flex;
  flex-direction: row;
  align-items: center;
  ${media.small`
    flex-wrap: wrap;
  `};
`;

export const OrderLinkButton = styled.div`
  ${LinkStyle};
`;

export const OrderId = styled.div`
  min-width: 14%;
`;

export const Total = styled.div`
  padding-left: 1rem;
  padding-right: 2rem;
  text-align: right;
  min-width: 10%;
`;

export const Date = styled.div`
  flex-direction: column;
  display: flex;
  text-align: right;
  margin-left: auto;
  padding-left: 1rem;
  ${media.medium`
    margin-left: 0;
    text-align: left;
    margin-top: 0.5rem;
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  `};
`;

export const RequestButton = styled(ActionButton)`
  line-height: 16px;
  margin-left: 1rem;
  margin-right: 0;
  ${media.medium`
    margin-left: 0;
  `};
`;

export const RowTitle = styled.span`
  font-weight: bold;
`;

export const ReOrder = styled.a`
  color: ${p => p.theme.primaryColor};
  cursor: pointer;
  ${media.small`
    color: ${p =>
      p.$disabled ? p.theme.disabledColor : p.theme.secondaryColor};
    border: ${p =>
      p.$disabled
        ? `0.2rem solid ${p.theme.disabledColor}`
        : `0.2rem solid ${p.theme.secondaryColor}`};
    background-color: ${p =>
      p.$isLoading ? p.theme.secondaryColor : 'transparent'};
    text-transform: uppercase;
    text-align: center;
    font-weight: bold;
    display: flex;
    font-size: 1.4rem;
    height: 4rem;
    min-height: 4rem;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
  `};
`;

export const Items = styled.div`
  padding: 2rem;
  font-size: 1.4rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  ${media.small`
    padding: 1rem;  
  `};
`;

export const ItemTagText = styled.span`
  color: ${p => p.theme.secondaryColor};
`;

export const ButtonRow = styled.div`
  display: flex;
  height: 4rem;
  min-width: 100%;
  padding: ${p => (p.$hasPhoto ? 0 : '0 1rem 1rem')};
  flex-direction: row-reverse;
  background-color: ${p => p.theme.selectedBgColor};
  ${media.small`
    justify-content: space-between;
    padding: 0 1rem;  
  `};
`;

export const UploadPic = styled(Button3b)`
  min-height: 3rem;
  max-height: 3rem;
  font-size: 1.2rem;
  line-height: 1rem;
  padding: ${p => (p.$isSelect ? '1rem 0' : '1rem')};
  border-width: 0.1rem;
  margin-right: ${p => (p.$noMarginRight ? 0 : '1rem')};
  margin-left: ${p => (p.$hasMarginLeft ? '1rem' : 0)};
  white-space: nowrap;
  border-color: ${p =>
    p.$isError ? p.theme.tertiaryColor : p.theme.primaryColor};
  ${media.small`
    width: ${p => (p.$fullSize ? '100%' : 'auto')};
    display: ${p => (p.$isCaptureIcon ? 'none' : 'block')}
  `};
`;

export const SelectorButton = styled(Button3b)`
  min-height: 3rem;
  max-height: 3rem;
  min-width: 10rem;
  font-size: 1.2rem;
  padding: 1rem;
  border-width: 0.1rem;
  white-space: nowrap;
  margin-left: ${p => (p.$hasMargin ? '1rem' : 0)};
  border-color: ${p =>
    p.$isError ? p.theme.tertiaryColor : p.theme.primaryColor};
  color: ${p => (p.$isError ? p.theme.tertiaryColor : p.theme.primaryColor)};
  ${media.small`
    width: 100%;
    padding: ${p => (p.$isSelect ? '1rem 0 1rem 0.5rem' : '1rem')};
  `};
`;

export const UploadImage = styled.input`
  line-height: 1rem;
  max-width: 12rem;
  margin-right: 1rem;
  height: 3rem;
  min-width: 11rem;

  &[type='file'] {
    color: transparent;
  }

  &::-webkit-file-upload-button {
    visibility: hidden;
  }

  &::before {
    content: 'Upload Photo';
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    white-space: nowrap;
    -webkit-user-select: none;
    cursor: pointer;
    min-height: 3rem;
    font-size: 1.2rem;
    text-transform: uppercase;
    font-weight: bold;
    color: ${p => p.theme.primaryColor};
    background-color: ${p => p.theme.secondaryBgColor};
    border-width: 0.1rem;
    border-style: solid;
    border-color: ${p =>
      p.$isError ? p.theme.tertiaryColor : p.theme.primaryColor};
  }

  &:hover::before {
    background-color: ${p => p.theme.primaryColor};
    color: ${p => p.theme.primaryTextColor};
  }
`;

export const QtySelector = styled.select`
  border: 0;
  background: none;
  -webkit-appearance: none;
  color: ${p => p.theme.primaryColor};
  font-weight: bold;
  text-transform: uppercase;
  height: 4rem;
  width: 100%;

  &:focus {
    outline: none;
  }

  &:hover {
    color: ${p => p.theme.primaryTextColor};
  }

  ${media.small`
    width: auto;
  `};
`;

export const OrderItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-grow: 1;
  color: ${p => p.theme.textColor};
`;

export const OrderFullRowWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${p =>
    p.$isSelected ? p.theme.selectedBgColor : 'transparency'};
  border-color: ${p =>
    p.$isSelected
      ? p.$isError && !p.$isReady
        ? p.theme.tertiaryAltColor
        : p.theme.secondaryColorHover
      : p.theme.borderColor};
  border-style: solid;
  border-width: ${p => (p.$isReady || p.$isError ? '0.1rem' : '0 0 0.1rem 0')};
  margin: ${p =>
    (!p.$isReady && p.$isError) || (p.$isReady && !p.$isError)
      ? '0.1rem 0'
      : 0};
`;

export const OrderFullRowSubWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  position: relative;
  width: 100%;
`;

export const OrderFullRow = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-right: 1rem;
`;

export const SelectBoxWrapper = styled.div`
  position: relative;
  min-width: 3rem;
  ${media.small`
    min-width: 2.5rem;
  `};
`;

export const SelectBox = styled.div`
  position: absolute;
  left: 1.3rem;
  top: 2.1rem;
  background-color: ${p => p.theme.secondaryColor};
  width: 0.6rem;
  height: 0.6rem;
  display: block;
`;

export const CheckboxLabel = styled.div`
  display: block;
  cursor: pointer;
  padding-left: 1.6rem;
  position: relative;

  &:before {
    position: absolute;
    top: 1.8rem;
    left: 1rem;
    border: ${p => `solid 0.1rem ${p.theme.tertiaryTextColor}`};
    width: 1.2rem;
    height: 1.2rem;
    content: '';
    display: block;
  }
`;

export const ItemProp = styled.a`
  padding: 1.5rem 2rem 1.5rem 1rem;
  color: ${p => p.theme.primaryColor};
  width: 100%;
`;
export const ItemPropNonLink = styled.span`
  padding: 1.5rem 2rem 1.5rem 1rem;
  width: 100%;
  color: ${p => p.theme.primaryColor};
`;
export const ItemPropValue = styled.div`
  white-space: nowrap;
  line-height: 5rem;
`;

export const Error = styled.div`
  background-color: ${p => p.theme.secondaryBgColor};
  font-size: 2rem;
  padding: 2rem;
  text-align: center;
  width: 100%;
  margin: 4rem 0 2rem 0;
`;

export const CannotEditYet = styled.div`
  color: ${p => p.theme.tertiaryColor};
`;

export const EditUsingApp = styled.div`
  white-space: nowrap;
`;

export const Feedback = styled.a`
  font-size: 1.2rem;
  line-height: 1.4rem;
  color: ${p => p.theme.primaryColor};
  font-style: italic;
  max-width: 12rem;
  text-align: right;
  margin-left: 2rem;
  ${media.small`
    color: ${p =>
      p.$disabled ? p.theme.disabledColor : p.theme.secondaryColor};
    border: ${p =>
      p.$disabled
        ? `0.2rem solid ${p.theme.disabledColor}`
        : `0.2rem solid ${p.theme.secondaryColor}`};
    background-color: ${p =>
      p.$isLoading ? p.theme.secondaryColor : 'transparent'};
    text-transform: uppercase;
    text-align: center;
    font-weight: bold;
    display: flex;
    font-size: 1.4rem;
    height: 4rem;
    min-height: 4rem;
    align-items: center;
    justify-content: center;
    max-width: initial;
    width: 100%;
    margin: 1rem 0 0 0;
    font-style: normal;
  `};
`;

export const FulFillmentInfo = styled.div`
  font-size: 1.4rem;
  color: ${p => p.theme.textColor};
  text-align: right;
  padding: 1rem;
  font-weight: bold;
`;

export const PaginationWrapper = styled.div`
  display: flex;
  margin: 3rem 0 1rem 0;
`;

export const PageContent = styled(Content)`
  min-height: 20rem;
  border-top: ${p => `solid 1rem ${p.theme.secondaryBgColor}`};
  ${media.smaller`
    border-width: 1rem;
  `};
`;

export const MessageBox = styled.div`
  width: 100%;
  padding: 0 10rem 2rem;
  font-size: 1.5rem;
  text-align: center;
  color: ${p => p.theme.tertiaryColor};
  line-height: 2.2rem;
  ${media.smaller`
    font-size: 1.4rem;
    padding: 0 2rem 2rem;
  `};
`;

export const OrderError = styled.div`
  padding: 3rem 2rem 2rem;
  color: ${p => p.theme.textColor};
  font-size: 1.4rem;
  text-align: center;
`;

export const LoadingWrapper = styled(ButtonLoadingWrapper)`
  height: 20rem;
`;

export const ErrorMsg = styled.div`
  color: ${p => p.theme.tertiaryColor};
`;

export const ExtraWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
`;

export const PayButtonWrapper = styled.div`
  padding: 2rem;
`;

export const HideOnMobileBlock = styled.div`
  display: flex;
  ${media.smaller`
    display: none;
  `};
`;

export const ReasonMobileRow = styled.div`
  display: none;
  ${media.smaller`
    display: block;
    max-width: 100%;
    padding: 0 1rem 1rem;
  `};
`;

export const GalleryWrapper = styled.div`
  display: none;
  ${media.smaller`
    display: block;
    padding-bottom: 1rem;
      img {
        width: 8rem;
        height: 7rem;
        margin: 0 1rem 0 0;
      }
   `};
`;

export const ButtonGroup = styled.div`
  display: flex;
  ${media.smaller`
    flex-direction: column;
    width: 100%;
  `};
`;

export const ButtonImageGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  ${media.smaller`
      justify-content: space-between;
  `};
`;

export const GalleryDesktop = styled.div`
  display: flex;
  align-items: center;
  height: 100%;

  img {
    width: 10rem;
    height: 7rem;
  }
`;

export const SubHead = styled.div`
  padding: 2rem 2rem 1rem;
  ${media.smaller`
      padding: 0 2rem 1rem;
  `};
`;

export const Back = styled.div`
  cursor: pointer;
  font-size: 1.2rem;
  color: ${props => props.theme.secondaryColor};
  padding: 1rem 0;
  max-width: 13rem;
`;

export const RefundErrorMsg = styled.div`
  display: block;
  font-size: 1.3rem;
  line-height: 1.8rem;
  padding-left: 3rem;
  width: 100%;
  color: ${props => props.theme.tertiaryAltColor};
  ${media.smaller`
      padding: 2rem;
  `};
`;

export const Condition = styled.div`
  font-size: 1.4rem;
  color: ${p => p.theme.tertiaryTextColor};
  padding: 0 2rem 2rem;
  margin-top: -1rem;
  text-align: center;
`;

export const BatchInfo = styled.div`
  display: block;
  width: 100%;
  line-height: 2rem;
  text-align: center;
  padding: 0.8rem;
  color: ${props => props.theme.tertiaryTextColor};
  border-top: ${p => `solid 0.1rem ${p.theme.borderDarkColor}`};
`;

export const CatchWeightInput = styled.input`
  outline: none;
  padding-left: 1rem;
  max-width: 9rem;
  border-color: ${p =>
    p.$isError ? p.theme.tertiaryColor : p.theme.primaryColor};
  border-style: solid;
  border-width: 0.1rem;
  ${media.medium`
     max-height: 3rem;
  `};
`;
