import React from 'react';
import {
  Container,
  Content,
  Details,
  Price,
  PhotoWrapper,
  ListViewContent,
  NameWrapper,
  ButtonStyled,
  Name,
  PriceWrapper,
  SubWrapper,
  Larger,
  ListId,
  ListViewInfo,
  ListPriceWrapper,
  Small,
  HideOnMobile,
  PriceBox,
  PriceColumn,
  SmallMatch,
  ExtraPromo,
} from './ListProduct.style';
import {
  LoadingWrapper,
  AddButton,
  OutOfStockButtonText,
  OutOfStockText,
  AvailableFromButtonText,
  ExtraPromoBig,
} from './Product.style';
import Loading from '../Loading/Loading';
import Quantity from '../Quantity/Quantity';
import ImageComponent from '../Image/Image';
import {getPriceUnavailableText} from '../../helpers/product.helper';
import {getAvailableAtDateString} from '../../helpers/string.helper';
import {STOCK_CHECK} from '../../constants/constants';
import {PromoSmallText} from '../Promotion/Promotion.style';

export const ListProduct = props => {
  const {
    product,
    currency,
    updateItem,
    deliveryPrice,
    collectionPrice,
    stockCheck,
    unitPrice,
    defaultUnitPrice,
    loading,
    loadingProps,
    addToBasket,
    goToProductDetailPage,
    remove,
    step,
    max,
    renderOfferTab,
    isOOs,
    oosText,
    isSubstituteAvailable,
    gotoAlternatives,
    renderDesireQtyButton,
    renderPromotion,
    hasMixOffer,
    discountPrice,
    renderOfferTitle,
  } = props;
  const renderPromoDiscount = !!discountPrice && (
    <ExtraPromo data-rw="discount-price">
      Extra <ExtraPromoBig>{discountPrice}</ExtraPromoBig> off
    </ExtraPromo>
  );
  const renderButtonContent = loading ? (
    <LoadingWrapper>
      <Loading isLight={true} />
    </LoadingWrapper>
  ) : (
    'Add item'
  );
  const renderAvailableButton = product.basketQuantity ? (
    <Quantity
      quantity={product.basketQuantity}
      update={updateItem}
      uuid={product.basketItemUuid}
      remove={remove}
      step={step}
      max={max}
      loading={loadingProps}
      stockCheck={stockCheck}
      unitPrice={unitPrice}
    />
  ) : (
    <AddButton
      onClick={addToBasket}
      $isLoading={loading}
      data-rw="product--add-button"
    >
      {renderButtonContent}
    </AddButton>
  );

  const renderAddButton =
    !isOOs &&
    (stockCheck && stockCheck.result === STOCK_CHECK.OUT_OF_STOCK ? (
      <AddButton disabled={true}>
        !!stockCheck.availableAt ?
        {getAvailableAtDateString(stockCheck.availableAt)} : oosText
      </AddButton>
    ) : (
      renderAvailableButton
    ));

  const renderAlternativesButton = isOOs && isSubstituteAvailable && (
    <AddButton
      onClick={gotoAlternatives}
      $isLoading={loading}
      data-rw="product--alternatives-button"
    >
      Alternatives
    </AddButton>
  );
  const renderOOsText = isOOs &&
    !isSubstituteAvailable &&
    !stockCheck.availableAt && (
      <OutOfStockButtonText>{oosText}</OutOfStockButtonText>
    );

  const renderAdvancedText = isOOs &&
    !isSubstituteAvailable &&
    !!stockCheck.availableAt && (
      <AvailableFromButtonText>
        {getAvailableAtDateString(stockCheck.availableAt)}
      </AvailableFromButtonText>
    );
  const renderCollectionPrice = collectionPrice && (
    <Price>
      <Small>
        Col<HideOnMobile>lection</HideOnMobile>:{' '}
      </Small>
      <Larger>
        {currency}
        {collectionPrice}
      </Larger>
    </Price>
  );
  const renderDeliveryPrice = deliveryPrice && (
    <Price>
      <Small>
        Del<HideOnMobile>ivery</HideOnMobile>:{' '}
      </Small>
      <Larger>
        {currency}
        {deliveryPrice}
      </Larger>
    </Price>
  );
  const renderColPrice = !(isOOs && isSubstituteAvailable) &&
    collectionPrice &&
    collectionPrice > 0 && (
      <ListPriceWrapper
        $onlyOnePrice={!!product.offer || !product.collectionMulti}
        $extraBottom={!product.offer}
      >
        {renderCollectionPrice}
      </ListPriceWrapper>
    );
  const renderDelPrice = !(isOOs && isSubstituteAvailable) &&
    deliveryPrice &&
    deliveryPrice > 0 && (
      <ListPriceWrapper
        $onlyOnePrice={!!product.offer || !product.deliveryMulti}
        $extraBottom={!product.offer}
      >
        {renderDeliveryPrice}
      </ListPriceWrapper>
    );
  const renderMixCollectionPrice =
    !(isOOs && isSubstituteAvailable) &&
    hasMixOffer &&
    product &&
    product.collectionMultiBuy &&
    product.collectionMultiBuy.map((mixCol, index) => (
      <ListPriceWrapper key={`col${mixCol.priceInc}-${index}`} $isOffer={true}>
        <Price
          $isOffer={index === product.deliveryMultiBuy.length - 1}
          $isFirstOffer={index !== product.deliveryMultiBuy.length - 1}
        >
          <SmallMatch>Mix Any{` ${mixCol.min}+: `}</SmallMatch>
          <Larger>
            {currency}
            {mixCol && mixCol.priceInc && `${mixCol.priceInc.toFixed(2)}`}
          </Larger>
        </Price>
      </ListPriceWrapper>
    ));
  const promoDisAmt = product && product.offer && product.offer.promoDisAmt;
  const renderOfferCollectionPrice = !(isOOs && isSubstituteAvailable) &&
    !hasMixOffer &&
    product &&
    product.offer &&
    (promoDisAmt >= 0 || product.offer.promoPrice) &&
    product.collection &&
    product.collection.price > 0 && (
      <ListPriceWrapper $isOffer={true} $extraBottom={true}>
        <Price $isOffer={true}>
          <SmallMatch>{renderOfferTitle}</SmallMatch>
          <Larger>
            {currency}
            {(product.collection.price - product.offer.promoDisAmt).toFixed(2)}
          </Larger>
        </Price>
      </ListPriceWrapper>
    );
  const renderOfferDeliveryPrice = !(isOOs && isSubstituteAvailable) &&
    !hasMixOffer &&
    product &&
    product.offer &&
    (promoDisAmt >= 0 || product.offer.promoPrice) &&
    product.delivery &&
    product.delivery.price > 0 && (
      <ListPriceWrapper $isOffer={true} $extraBottom={true}>
        <Price $isOffer={true}>
          <SmallMatch>{renderOfferTitle}</SmallMatch>
          <Larger>
            {currency}
            {(product.delivery.price - product.offer.promoDisAmt).toFixed(2)}
          </Larger>
        </Price>
      </ListPriceWrapper>
    );
  const renderMixDeliveryPrice =
    !(isOOs && isSubstituteAvailable) &&
    hasMixOffer &&
    product &&
    product.deliveryMultiBuy &&
    product.deliveryMultiBuy.map((mixDel, index) => (
      <ListPriceWrapper key={`del${mixDel.priceInc}.${index}`}>
        <Price
          $isOffer={index === product.deliveryMultiBuy.length - 1}
          $isFirstOffer={index !== product.deliveryMultiBuy.length - 1}
        >
          <SmallMatch>Mix Any{` ${mixDel.min}+: `}</SmallMatch>
          <Larger>
            {currency}
            {mixDel && mixDel.priceInc && `${mixDel.priceInc.toFixed(2)}`}
          </Larger>
        </Price>
      </ListPriceWrapper>
    ));
  const renderColPriceMulti = !(isOOs && isSubstituteAvailable) &&
    product &&
    !product.offer &&
    product.collectionMulti && (
      <ListPriceWrapper>
        <Price>
          <Small>
            {product.collectionMulti &&
              product.collectionMulti.min &&
              `Buy ${product.collectionMulti.min}+: `}
          </Small>
          <Larger>
            {product.collectionMulti &&
              product.collectionMulti.priceInc &&
              `${currency}${product.collectionMulti.priceInc.toFixed(2)}`}
          </Larger>
        </Price>
      </ListPriceWrapper>
    );
  const renderDelPriceMulti = !(isOOs && isSubstituteAvailable) &&
    product &&
    !product.offer &&
    product.deliveryMulti && (
      <ListPriceWrapper>
        <Price>
          <Small>
            {product.deliveryMulti &&
              product.deliveryMulti.min &&
              `Buy ${product.deliveryMulti.min}+: `}
          </Small>
          <Larger>
            {product.deliveryMulti &&
              product.deliveryMulti.priceInc &&
              `${currency}${product.deliveryMulti.priceInc.toFixed(2)}`}
          </Larger>
        </Price>
      </ListPriceWrapper>
    );
  const renderPriceUnavailable =
    !collectionPrice && !deliveryPrice && getPriceUnavailableText();
  const renderOOsPrice = isOOs &&
    isSubstituteAvailable &&
    !stockCheck.availableAt && (
      <OutOfStockText isSmallView={true}>{oosText}</OutOfStockText>
    );
  const renderAdvancePrice = isOOs &&
    isSubstituteAvailable &&
    !!stockCheck.availableAt && (
      <AvailableFromButtonText>
        {getAvailableAtDateString(stockCheck.availableAt)}
      </AvailableFromButtonText>
    );

  const renderBubblePromotion = !hasMixOffer && (
    <SubWrapper data-rw="bubble-promos" onClick={goToProductDetailPage}>
      {renderPromotion}
    </SubWrapper>
  );
  return (
    <Container
      image={product.image}
      href={product.url}
      key={product.id}
      $isOffer={!!product.offer || hasMixOffer}
    >
      {renderOfferTab}
      <ListViewContent>
        <PhotoWrapper onClick={goToProductDetailPage}>
          {renderDesireQtyButton}
          <ImageComponent
            url={product.imageLinks && product.imageLinks[0]}
            alt={product.itemName}
          />
        </PhotoWrapper>
        <Content>
          <ListViewInfo onClick={goToProductDetailPage}>
            <ListId>{product && product.itemId}</ListId>
            <PromoSmallText>
              {unitPrice ? `£${unitPrice} each` : defaultUnitPrice}
            </PromoSmallText>
          </ListViewInfo>
          <NameWrapper onClick={goToProductDetailPage}>
            <Name>{product.itemName}</Name>
          </NameWrapper>
          {renderBubblePromotion}
          <SubWrapper>
            <Details>
              {renderPromoDiscount}
              <PriceWrapper data-rw="price-wrapper" $extraPadding={hasMixOffer}>
                <PriceBox data-rw="price-box">
                  <PriceColumn data-rw="price-column">
                    {renderColPrice}
                    {renderColPriceMulti}
                    {renderOfferCollectionPrice}
                    {renderMixCollectionPrice}
                  </PriceColumn>
                  <PriceColumn>
                    {renderDelPrice}
                    {renderDelPriceMulti}
                    {renderOfferDeliveryPrice}
                    {renderMixDeliveryPrice}
                  </PriceColumn>
                </PriceBox>
                {renderOOsPrice}
                {renderAdvancePrice}
                {renderPriceUnavailable}
              </PriceWrapper>
            </Details>
          </SubWrapper>
        </Content>
      </ListViewContent>
      <ButtonStyled>
        {renderAddButton}
        {renderAlternativesButton}
        {renderOOsText}
        {renderAdvancedText}
      </ButtonStyled>
    </Container>
  );
};
