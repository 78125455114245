import React, {Fragment, PureComponent} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import moment from 'moment';
import {captureException, withScope} from '@sentry/browser';

import videoImage from '../../images/videoPlayer.png';
import {
  getProductDetail,
  resetProductDetail,
} from '../../actions/productDetail.action';
import Loading from '../../components/Loading/Loading';
import {
  BoughtTogether,
  BrandLink,
  BreadCrumb,
  BreadCrumbLinks,
  Container,
  CurrentPage,
  Details,
  Disclaimer,
  Email,
  InfoColumn,
  InfoColumnBold,
  InfoDescription,
  InfoText,
  InfoTitle,
  ItemsWrapper,
  LoadingWrapper,
  NotFound,
  ProductPanel,
  SmallPrintTitle,
  TagsWrapper,
  OfferRange,
  UnavailableText,
  ButtonsWrapper,
  AddButton,
  QuantityStyled,
  ImageWrapper,
  SubMediaWrapper,
  Content,
  Title,
  Id,
  PricesWrapper,
  Description,
  BlockWrapper,
  InfoContent,
  InfoWrapper,
  LoadingButtonWrapper,
  Price,
  SmallPrint,
  SubImage,
  Back,
  PromotionWrapper,
  VAT,
  PriceWholeWrapper,
  MultiPriceColumn,
  OfferRangeLink,
} from './ProductDetailPage.style';
import Thumbnail from './Thumbnail';
import ProductBar from '../../components/ProductBar/ProductBar';
import {addFavourite, removeFavourite, setCurrentRoute} from '../../actions';
import {
  ANALYTICS_SOURCES,
  ERRORS,
  OFFER_END_DATE_FULL_FORMAT,
  OFFER_ENDS_DATE_FORMAT_SHORT,
  OFFER_TYPES,
  PRODUCT_TABS,
  PROMO_TAGS,
  PROMO_TAGS_MAP,
  RESERVED_WORDS,
  ROUTES,
  SERVICE_TYPE,
  STOCK_CHECK,
} from '../../constants/constants';
import ProductTab from './ProductTab';
import Quantity from '../../components/Quantity/Quantity';
import {getRecommended} from '../../actions/recommended.action';
import Product from '../../components/Product/Product';
import {
  getBrandUrl,
  getCollectionUnitPrice,
  getLogistics,
  getNutrition,
  getPriceUnavailableText,
  syncBasketQuantityWithOneProduct,
  syncBasketQuantityWithProducts,
} from '../../helpers/product.helper';
import ProductTags from '../../components/ProductTags/ProductTags';
import {
  AvailableFromButtonText,
  NewProductTag,
  OutOfStockButtonText,
  PriceText,
  PriceType,
  UnitPriceText,
} from '../../components/Product/Product.style';
import MainMedia from './MainMedia';
import Promotion from '../../components/Promotion/Promotion';

import {getBasketItemsCodes, getProductItem} from '../../helpers/basket.helper';
import {
  checkAvailableForBranch,
  checkHasMixOffer,
  getBranchFromUrl,
  processedProductDetail,
} from '../../helpers/data.helper';
import {
  arrayToHashMapTrueObj,
  getCategoryName,
  objectHasTrueValue,
} from '../../helpers/array.helper';
import {getOgData, setPageHeadTags} from '../../helpers/seo.helper';
import {setPrevRoute} from '../../actions/prevRoute.action';
import {
  getAvailableAtDateString,
  getBackButtonText,
} from '../../helpers/string.helper';
import DropdownList from '../../components/DropdownList/DropdownList';
import {
  addToShoppingList,
  createAShoppingList,
  getShoppingList,
  removeFromShoppingList,
} from '../../actions/shoppingList.action';
import {GoogleAnalyticsHelper} from '../../helpers/googleAnalytics.helper';
import MultiPrices from './MultiPrices';
import {getMixAndSaveUrl} from '../../helpers/search.helper';

class ProductDetailPage extends PureComponent {
  static propTypes = {
    productDetail: PropTypes.object,
  };

  constructor(props) {
    super(props);
    const {route, productDetailData, prevRoute} = props;
    this.state = {
      isFavorite: productDetailData && productDetailData.favourite,
      selectedMedia: null,
      content: '',
      loading: false,
      loadWholeProduct: false,
      subMedia:
        (productDetailData &&
          productDetailData.imageLinks &&
          productDetailData.imageLinks.map(img => ({url: img}))) ||
        [],
      fromSearch:
        route === ROUTES.SEARCH ||
        route === ROUTES.SEARCH_RESULTS ||
        prevRoute === ROUTES.RECENT_PURCHASES ||
        prevRoute === ROUTES.FAVOURITES ||
        prevRoute === ROUTES.OFFERS ||
        prevRoute === ROUTES.SHOPPING_LISTS_CONTENT,
      backButtonText: getBackButtonText(prevRoute),
      listHashMap: null,
      productShoppingList: null,
      isProductInShoppingList: false,
      hasMixOffer: false,
      mixAndSaveUrl: '',
    };
    this.isLoadingLists = false;
  }

  static getDerivedStateFromProps(nextProps) {
    if (nextProps.loading && !nextProps.loading.addingToBasket) {
      return {
        loading: false,
      };
    }
    return null;
  }

  componentDidMount() {
    const {match, setCurrentRoute} = this.props;
    setCurrentRoute(ROUTES.PRODUCT);
    const itemId = match.params.itemId;
    const branchFromUrl = getBranchFromUrl(match);
    this.setState({branchFromUrl});
    this.getProductDetails(itemId, branchFromUrl);
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      match,
      productDetailData,
      branch,
      getRecommendedFunc,
      auth,
      basket,
      settings,
      shoppingLists,
      getShoppingListFunc,
    } = this.props;
    const {branchFromUrl} = this.state;
    if (shoppingLists === null && !!auth && !this.isLoadingLists) {
      this.isLoadingLists = true;
      getShoppingListFunc();
    }
    if (prevProps.match.params.itemId !== match.params.itemId) {
      this.setState({loadWholeProduct: true});
      this.getProductDetails(match.params.itemId, branchFromUrl || branch);
    }
    if (
      productDetailData &&
      productDetailData !== prevProps.productDetailData
    ) {
      let listObj;
      let isProductInShoppingList = false;
      if (productDetailData.shoppingLists) {
        this.isLoadingLists = false;
        if (shoppingLists) {
          listObj = arrayToHashMapTrueObj(productDetailData.shoppingLists);
          if (objectHasTrueValue(listObj)) {
            isProductInShoppingList = true;
          }
        }
      }
      const product = processedProductDetail(productDetailData);
      if (product) {
        const og = getOgData(product);
        setPageHeadTags(og);
        GoogleAnalyticsHelper.logPageView();
      }
      const videosContents =
        (productDetailData &&
          productDetailData.video &&
          productDetailData.video.map(media => {
            const videoId =
              media.videoURL && media.videoURL.split('youtube.com/embed/');
            if (videoId && videoId[1]) {
              return {
                videoUrl: media.videoURL,
                id: videoId[1],
                url: videoImage,
              };
            }
          })) ||
        [];
      const imagesContent =
        (productDetailData &&
          productDetailData.imageLinks &&
          productDetailData.imageLinks.map(img => ({url: img}))) ||
        [];
      const subMedia = [...imagesContent, ...videosContents];
      const containsMixOffer = checkHasMixOffer(productDetailData);
      const renderMixNSaveTag =
        (productDetailData &&
          productDetailData.offer &&
          productDetailData.offer.promoTag) ||
        OFFER_TYPES.MIX;
      const renderMixNSavePromoTagId =
        productDetailData &&
        productDetailData.offer &&
        productDetailData.offer.promoTagId;
      const url = getMixAndSaveUrl(renderMixNSaveTag, renderMixNSavePromoTagId);
      this.setState({
        subMedia,
        selectedMedia: null,
        loadWholeProduct: false,
        productShoppingList: listObj,
        isProductInShoppingList,
        hasMixOffer: containsMixOffer,
        mixAndSaveUrl: url,
      });
    }
    if (
      (branch && branch !== prevProps.branch) ||
      (branchFromUrl &&
        branchFromUrl !== prevState.branchFromUrl &&
        ((basket && basket.branchId !== branchFromUrl) ||
          (!basket &&
            settings &&
            settings.branchId &&
            settings.branchId !== branchFromUrl)))
    ) {
      const isFullList = false;
      getRecommendedFunc(
        match.params.itemId,
        isFullList,
        branchFromUrl || branch
      );
    }
  }

  componentWillUnmount() {
    const {resetProductDetail} = this.props;
    this.setState({subMedia: [], selectedMedia: null});
    // TODO FIXME
    // quick fixes
    // - history.push on FE isn't connected with SSR
    //   possibly a different instance and FE doesn't actually navigate to new URL when switching between products
    // - check productDetail saga - getting branch and basket takes a while and blocks UI
    //   see https://github.com/jjfood/olympus-app/blob/develop/src/saga/productDetail.saga.js#L36
    //   and https://github.com/jjfood/olympus-app/blob/develop/src/saga/productDetail.saga.js#L42
    resetProductDetail();
  }

  getProductDetails = (itemId, branchId) => {
    const {getProductDetail, getRecommendedFunc} = this.props;
    getProductDetail({itemId, branchId});
    const isFullList = false;
    if (branchId) {
      getRecommendedFunc(itemId, isFullList, branchId);
    }
  };

  addToBasket = e => {
    if (this.state.loading) {
      return;
    }
    e.preventDefault();
    const {productDetailData, updateBasket, basket} = this.props;
    this.setState({loading: true});
    const isDelivery = !!(
      basket && basket.fulfillmentType === SERVICE_TYPE.DELIVERY
    );
    const quantity = isDelivery
      ? productDetailData.delivery && productDetailData.delivery.step
      : productDetailData.collection && productDetailData.collection.step;
    const item = getProductItem(productDetailData, quantity);
    const analyticsObj = {
      ...{
        product: {
          ...productDetailData,
          categoryName: getCategoryName(productDetailData),
        },
      },
      quantity,
      source: ANALYTICS_SOURCES.PRODUCT_DETAIL,
    };
    updateBasket(item, analyticsObj);
  };
  onMediaClick = media => this.setState({selectedMedia: media});

  onMainMediaClick = () => {
    const {productDetailData} = this.props;
    const {selectedMedia} = this.state;
    if (
      productDetailData &&
      productDetailData.imageLinks &&
      productDetailData.imageLinks.length > 1
    ) {
      let imageIndex = 0;
      let newIndex = 0;
      if (selectedMedia) {
        imageIndex = productDetailData.imageLinks.findIndex(
          link => link === selectedMedia.url
        );
      }
      if (imageIndex < productDetailData.imageLinks.length - 1) {
        newIndex = imageIndex + 1;
      }
      const nextImage = {
        url: productDetailData.imageLinks[newIndex],
      };
      this.setState({selectedMedia: nextImage});
    }
  };

  goBack = () => {
    const {history, setPrevRoute} = this.props;
    setPrevRoute(ROUTES.PRODUCT);
    history.goBack();
  };

  gotoAlternatives = () => {
    const {productDetailData, substitutes, keywordSearch} = this.props;
    const query = getBasketItemsCodes(substitutes[productDetailData.itemId]);
    keywordSearch(query);
  };

  remove = () => {
    const {removeItem, productDetailData, basketHashMap} = this.props;
    const basketItemUuid =
      productDetailData &&
      productDetailData.itemId &&
      basketHashMap &&
      basketHashMap[productDetailData.itemId] &&
      basketHashMap[productDetailData.itemId].basketItemUuid;
    if (basketItemUuid) {
      removeItem(basketItemUuid, productDetailData);
    } else {
      withScope(scope => {
        scope.setExtra('productDetailData', productDetailData);
        scope.setExtra('basketHashMap', basketHashMap);
        captureException(new Error(ERRORS.REMOVE_ITEM));
      });
    }
  };

  onFavoriteClick = product => {
    const {removeFavouriteFunc, addFavouriteFunc} = this.props;
    this.setState({isFavorite: !this.state.isFavorite});
    if (this.state.isFavorite) {
      removeFavouriteFunc(product);
    } else {
      addFavouriteFunc(product);
    }
  };

  onUpdateShoppingItemClick = (listId, itemId, quantity) => {
    const {addToShoppingListFunc, removeFromShoppingListFunc} = this.props;
    if (quantity === 0) {
      removeFromShoppingListFunc(listId, itemId);
    } else {
      addToShoppingListFunc(listId, itemId, quantity);
    }
  };

  render() {
    const {
      auth,
      productDetailData,
      loading,
      branch,
      basketHashMap,
      updateItem,
      updateBasket,
      recommendedItems,
      removeItem,
      basket,
      onProductClick,
      deviceInfo,
      match,
      substitutes,
      shoppingLists,
      createAShoppingListFunc,
      settings,
    } = this.props;
    const {
      fromSearch,
      selectedMedia,
      loadWholeProduct,
      subMedia,
      backButtonText,
      listHashMap,
      productShoppingList,
      isProductInShoppingList,
      hasMixOffer,
      mixAndSaveUrl,
    } = this.state;
    const isDelivery = !!(
      basket && basket.fulfillmentType === SERVICE_TYPE.DELIVERY
    );
    const productDetail = syncBasketQuantityWithOneProduct(
      basketHashMap,
      productDetailData
    );
    const quantityStep =
      (productDetail &&
        (isDelivery
          ? productDetail.delivery && productDetail.delivery.step
          : productDetail.collection && productDetail.collection.step)) ||
      1;
    const stockCheck =
      productDetail &&
      basketHashMap &&
      basketHashMap[productDetail.itemId] &&
      basketHashMap[productDetail.itemId].stockCheck;
    const isOOs =
      stockCheck &&
      (stockCheck.result === STOCK_CHECK.OUT_OF_STOCK ||
        stockCheck.result === STOCK_CHECK.DISCONTINUED ||
        stockCheck.result === STOCK_CHECK.NOT_ALLOWED);
    const isDiscontinued =
      stockCheck && stockCheck.result === STOCK_CHECK.DISCONTINUED;
    const isNotAllowed =
      stockCheck && stockCheck.result === STOCK_CHECK.NOT_ALLOWED;
    const isSubstituteAvailable =
      productDetail &&
      substitutes &&
      substitutes[productDetail.itemId] &&
      substitutes[productDetail.itemId].length > 0;
    const isAvailable = checkAvailableForBranch(
      productDetail,
      this.state.branchFromUrl || getBranchFromUrl(match),
      !!auth
    );
    const hasSubMedia =
      productDetail &&
      ((productDetail.imageLinks && productDetail.imageLinks.length > 1) ||
        (productDetail.video && productDetail.video.length > 0));
    const renderSubMedia =
      hasSubMedia &&
      subMedia.map((image, i) => {
        return (
          <SubImage key={i}>
            <Thumbnail callback={this.onMediaClick} media={image} />
          </SubImage>
        );
      });
    const renderMediaPanel = productDetail && (
      <ImageWrapper>
        <MainMedia
          media={selectedMedia || (subMedia && subMedia[0])}
          name={productDetail.itemName}
          hasSubImages={hasSubMedia}
          callback={this.onMainMediaClick}
        />
        <SubMediaWrapper>{renderSubMedia}</SubMediaWrapper>
      </ImageWrapper>
    );
    const renderButtonContent = this.state.loading ? (
      <LoadingWrapper>
        <Loading />
      </LoadingWrapper>
    ) : (
      'Add item'
    );
    const renderAvailableButton =
      productDetail && productDetail.basketQuantity ? (
        <QuantityStyled>
          <Quantity
            quantity={productDetail.basketQuantity}
            update={updateItem}
            uuid={productDetail.basketItemUuid}
            remove={this.remove}
            isLight={true}
            loading={loading}
            max={
              (isDelivery
                ? productDetail.delivery && productDetail.delivery.max
                : productDetail.collection && productDetail.collection.max) || 0
            }
            step={quantityStep}
            stockCheck={
              basketHashMap &&
              basketHashMap[productDetail.itemId] &&
              basketHashMap[productDetail.itemId].stockCheck
            }
            unitPrice={
              basketHashMap &&
              basketHashMap[productDetail.itemId] &&
              basketHashMap[productDetail.itemId].unitPrice
            }
          />
        </QuantityStyled>
      ) : (
        <AddButton
          onClick={this.addToBasket}
          $isLoading={this.state.loading}
          data-rw="product--add-button"
        >
          {renderButtonContent}
        </AddButton>
      );
    const renderOosTextContent = isDiscontinued
      ? 'Discontinued'
      : isNotAllowed
      ? 'Not Allowed'
      : 'Out of Stock';
    const renderOOsText = isOOs && !stockCheck.availableAt && (
      <OutOfStockButtonText>{renderOosTextContent}</OutOfStockButtonText>
    );
    const renderAdvancedText = isOOs && !!stockCheck.availableAt && (
      <AvailableFromButtonText>
        {getAvailableAtDateString(stockCheck.availableAt)}
      </AvailableFromButtonText>
    );
    const renderOOsButton = isSubstituteAvailable && (
      <AddButton
        onClick={this.gotoAlternatives}
        $isLoading={this.state.loading}
        data-rw="product--alternatives-button"
      >
        Alternatives
      </AddButton>
    );
    const renderAddButton =
      isAvailable && isAvailable.available ? (
        renderAvailableButton
      ) : (
        <UnavailableText>{isAvailable.message}</UnavailableText>
      );
    const renderButton = isOOs ? renderOOsButton : renderAddButton;

    const renderProductTags = productDetail &&
      productDetail.productFeatures &&
      productDetail.productFeatures.length > 0 && (
        <ProductTags
          isListView={false}
          showTagText={true}
          productFeatures={productDetail.productFeatures}
          origin={productDetail.origin}
        />
      );
    const renderNewProductTag = productDetail &&
      (productDetail.new || productDetail.isNew) && (
        <NewProductTag>NEW</NewProductTag>
      );
    const renderTags = (
      <TagsWrapper>
        {renderProductTags}
        {renderNewProductTag}
      </TagsWrapper>
    );
    const hasPromoText =
      productDetail &&
      productDetail.offer &&
      productDetail.offer.promoDiscountText &&
      productDetail.offer.promoDiscountText.length > 0;
    const isMultiBuyOffer =
      !!productDetail &&
      productDetail.offer &&
      productDetail.offer.promoMinQty &&
      productDetail.offer.promoMinQty > 1;
    const renderPromotion = (hasPromoText || isMultiBuyOffer) && !hasMixOffer && (
      <PromotionWrapper>
        <Promotion
          offer={productDetail.offer}
          promo={productDetail.offer.promoDiscountText}
          collectionPrice={
            productDetail &&
            productDetail.collection &&
            productDetail.collection.price
          }
          isPbbOffer={
            productDetail &&
            productDetail.offer &&
            productDetail.offer.promoTagId === PROMO_TAGS.Pay_By_Bank
          }
          isListMode={true}
          sPureText={true}
          isOneLine={true}
          unitPrice={
            productDetail &&
            productDetail.collection &&
            productDetail.collection.unitPriceDisplay
          }
        />
      </PromotionWrapper>
    );
    const renderOffersRange = hasMixOffer && !!mixAndSaveUrl && (
      <OfferRange>
        <OfferRangeLink to={mixAndSaveUrl}>
          {(productDetailData.offer && productDetailData.offer.promoTag) ||
            OFFER_TYPES.MIX}
        </OfferRangeLink>
      </OfferRange>
    );
    const hasNoProductMultiBuy =
      productDetail &&
      productDetail.collectionMultiBuy &&
      productDetail.collectionMultiBuy.length === 0 &&
      productDetail.deliveryMultiBuy &&
      productDetail.deliveryMultiBuy.length === 0;
    const renderPrice = productDetail &&
      isAvailable &&
      isAvailable.available &&
      ((productDetail.collection && productDetail.collection.price > 0) ||
        (productDetail.delivery && productDetail.delivery.price > 0)) && (
        <PriceWholeWrapper>
          {productDetail.collection && (
            <Price>
              <PriceType>Collection:</PriceType>
              <PriceText>£{productDetail.collection.price}</PriceText>
              {hasNoProductMultiBuy && (
                <UnitPriceText>
                  {productDetail.collection.unitPriceDisplay}
                </UnitPriceText>
              )}
            </Price>
          )}
          {productDetail.delivery && (
            <Price>
              <PriceType>Delivery:</PriceType>
              <PriceText>£{productDetail.delivery.price}</PriceText>
              {hasNoProductMultiBuy && (
                <UnitPriceText>
                  {productDetail.delivery.unitPriceDisplay}
                </UnitPriceText>
              )}
            </Price>
          )}
        </PriceWholeWrapper>
      );
    const renderMultiplePrice = productDetail &&
      isAvailable &&
      isAvailable.available &&
      (productDetail.collectionMultiBuy || productDetail.deliveryMultiBuy) && (
        <PriceWholeWrapper>
          <MultiPriceColumn>
            <MultiPrices
              prices={productDetail.collectionMultiBuy}
              hasMixOffer={hasMixOffer}
            />
          </MultiPriceColumn>
          <MultiPriceColumn>
            <MultiPrices
              prices={productDetail.deliveryMultiBuy}
              hasMixOffer={hasMixOffer}
            />
          </MultiPriceColumn>
        </PriceWholeWrapper>
      );
    const renderOfferTitle =
      (productDetail &&
        productDetail.offer &&
        productDetail.offer.promoTagId &&
        PROMO_TAGS_MAP[productDetail.offer.promoTagId]) ||
      'offer';
    const promoDisAmt =
      productDetail && productDetail.offer && productDetail.offer.promoDisAmt;
    const renderOfferPrice = productDetail &&
      !hasMixOffer &&
      !(isOOs && isSubstituteAvailable) &&
      (promoDisAmt > 0 || isMultiBuyOffer) &&
      ((productDetail.collection && productDetail.collection.price > 0) ||
        (productDetail.delivery && productDetail.delivery.price > 0)) && (
        <PriceWholeWrapper>
          {productDetail.collection && (
            <Price>
              <PriceType $isOffer={true}>
                {isMultiBuyOffer ? 'collection only' : renderOfferTitle}:
              </PriceType>
              <PriceText $isOffer={true}>
                £
                {isMultiBuyOffer
                  ? productDetail.offer.promoPrice
                  : (
                      productDetail.collection.price -
                      productDetail.offer.promoDisAmt
                    ).toFixed(2)}
              </PriceText>
            </Price>
          )}
          {productDetail.delivery && !isMultiBuyOffer && (
            <Price>
              <PriceType $isOffer={true}>{renderOfferTitle}:</PriceType>
              <PriceText $isOffer={true}>
                £
                {(
                  productDetail.delivery.price - productDetail.offer.promoDisAmt
                ).toFixed(2)}
              </PriceText>
            </Price>
          )}
        </PriceWholeWrapper>
      );
    const renderPriceUnavailable =
      productDetail &&
      !productDetail.collection &&
      !productDetail.delivery &&
      getPriceUnavailableText(true);
    const addButtonText = isProductInShoppingList ? 'Update' : 'Add to';
    const renderShoppingListButton = !!auth &&
      !isDiscontinued &&
      !isNotAllowed &&
      settings &&
      settings.shoppingList === 'true' && (
        <DropdownList
          addName={`${addButtonText} Shopping List`}
          lists={shoppingLists}
          createList={createAShoppingListFunc}
          updateListItem={this.onUpdateShoppingItemClick}
          itemId={productDetail && productDetail.itemId}
          loading={loading}
          productShoppingList={productShoppingList}
          listHashMap={listHashMap}
          step={quantityStep}
        />
      );
    const renderVAT = productDetail && productDetail.vat && (
      <VAT>This product is subject to VAT</VAT>
    );
    const renderContentPanel = productDetail && (
      <Content>
        <Id>{productDetail.itemId}</Id>
        <Title>{productDetail.itemName}</Title>
        <Description>
          {productDetail.productDescription &&
            productDetail.productDescription.toLowerCase() !==
              RESERVED_WORDS.NOT_AVAILABLE &&
            productDetail.productDescription}
        </Description>
        {renderTags}
        {renderOffersRange}
        <PricesWrapper>
          {renderPrice}
          {renderMultiplePrice}
          {renderOfferPrice}
          {renderPriceUnavailable}
        </PricesWrapper>
        {renderVAT}
        {renderPromotion}
        {renderOOsText}
        {renderAdvancedText}
        <ButtonsWrapper>
          {renderButton}
          {renderShoppingListButton}
        </ButtonsWrapper>
      </Content>
    );
    // todo: hard code now until BE return the right shape
    const nutritionHead = productDetail && (
      <InfoColumnBold>
        <InfoTitle>Nutrition Values</InfoTitle>
        <InfoDescription>(per 100g/ml)</InfoDescription>
      </InfoColumnBold>
    );
    const productSpec =
      productDetail &&
      PRODUCT_TABS.map((tab, index) => {
        switch (index) {
          case 0:
            return (
              <InfoText
                dangerouslySetInnerHTML={{
                  __html:
                    productDetail.ingredientsHTML || productDetail.ingredients,
                }}
              />
            );
          case 1:
            return (
              <Fragment>
                {nutritionHead}
                {getNutrition(productDetail).map((details, index) => (
                  <InfoColumn key={index}>
                    <InfoTitle>{details[0]}</InfoTitle>
                    <InfoDescription>{details[1]}</InfoDescription>
                  </InfoColumn>
                ))}
              </Fragment>
            );
          case 2:
            return productDetail.allergensDeclaration;
          case 3:
            return (
              <InfoDescription>
                {productDetail.cookingInstruction}
              </InfoDescription>
            );
          case 4:
            const branchName = getBranchFromUrl(match);
            const brandUrl = getBrandUrl(branchName, productDetail.brand);
            const renderBrand = !!productDetail.brand && (
              <InfoColumn>
                <InfoTitle>brand</InfoTitle>
                <InfoDescription>
                  {brandUrl && (
                    <BrandLink to={brandUrl}>{productDetail.brand}</BrandLink>
                  )}
                </InfoDescription>
              </InfoColumn>
            );
            const renderLogistics = getLogistics(productDetail).map(
              (details, index) => (
                <InfoColumn key={index}>
                  <InfoTitle>{details[0]}</InfoTitle>
                  <InfoDescription>{details[1]}</InfoDescription>
                </InfoColumn>
              )
            );
            return (
              <Fragment>
                {renderBrand}
                {renderLogistics}
              </Fragment>
            );
          default:
            return '';
        }
      });
    const renderProductTabs =
      productSpec &&
      PRODUCT_TABS.map((tab, index) => (
        <Fragment key={tab.name}>
          <BlockWrapper>
            <ProductTab name={tab.name} />
          </BlockWrapper>
          <InfoContent>{productSpec[index]}</InfoContent>
        </Fragment>
      ));
    const renderInfoPanel = productDetail && (
      <InfoWrapper>{renderProductTabs}</InfoWrapper>
    );
    const renderLoadingProductDetail = loadWholeProduct &&
      loading &&
      loading.searchResults && (
        <LoadingButtonWrapper>
          <Loading isLight={false} />
        </LoadingButtonWrapper>
      );
    const renderProductDetail = !loadWholeProduct && productDetail && (
      <ProductPanel>
        <Details>
          {renderMediaPanel}
          {renderContentPanel}
        </Details>
        {renderInfoPanel}
      </ProductPanel>
    );
    const renderBackButton = fromSearch && (
      <Back onClick={this.goBack}>&lt; {backButtonText}&#8739;</Back>
    );
    const categoryLink =
      productDetail && `?q=*&advanced=true&b=${branch}&category=`;
    const category1Link =
      categoryLink && `${categoryLink}${productDetail.category1Id}`;
    const category2Link =
      categoryLink && `${category1Link},${productDetail.category2Id}`;
    const category3Link =
      categoryLink && `${category2Link},${productDetail.category3Id}`;
    const requireNewSearch = {newSearch: true};
    const renderCategoryLinks = productDetail && (
      <span>
        {renderBackButton}
        <BreadCrumbLinks to="/">Home &gt;</BreadCrumbLinks>
        {productDetail.category1Name && (
          <BreadCrumbLinks
            to={{
              pathname: '/search',
              search: category1Link,
              state: requireNewSearch,
            }}
          >
            {productDetail.category1Name} >
          </BreadCrumbLinks>
        )}
        {productDetail.category2Name && (
          <BreadCrumbLinks
            to={{
              pathname: '/search',
              search: category2Link,
              state: requireNewSearch,
            }}
          >
            {productDetail.category2Name} >
          </BreadCrumbLinks>
        )}
        {productDetail.category3Name && (
          <BreadCrumbLinks
            to={{
              pathname: '/search',
              search: category3Link,
              state: requireNewSearch,
            }}
          >
            {productDetail.category3Name} >
          </BreadCrumbLinks>
        )}
        <CurrentPage>{productDetail.itemName}</CurrentPage>
      </span>
    );
    const offerData =
      productDetail &&
      productDetail.offer &&
      productDetail.offer.promoEnd &&
      moment(productDetail.offer.promoEnd, OFFER_END_DATE_FULL_FORMAT).format(
        OFFER_ENDS_DATE_FORMAT_SHORT
      );
    const renderProductBar = productDetail && (
      <ProductBar
        data-rw="product--bar"
        itemId={productDetail.itemId}
        name={productDetail.itemName}
        offerDate={offerData}
        hasPreviouslyPurchased={productDetail.previouslyPurchased}
        onFavoriteClick={this.onFavoriteClick}
        productDetail={productDetail}
        favourite={this.state.isFavorite}
        isNewProduct={productDetail.new || productDetail.isNew}
        hasMixOffer={hasMixOffer}
        mixAndSaveUrl={mixAndSaveUrl}
      />
    );

    const featuredList = syncBasketQuantityWithProducts(
      basketHashMap,
      recommendedItems
    );
    const renderItems = featuredList && featuredList.length > 0 && (
      <Fragment>
        <BoughtTogether>Often bought together</BoughtTogether>
        <ItemsWrapper>
          {featuredList.map(item => (
            <Product
              key={item.itemId}
              product={item}
              onProductClick={onProductClick}
              updateBasket={updateBasket}
              loading={loading}
              branch={branch}
              basketHashMap={basketHashMap}
              updateItem={updateItem}
              removeItem={removeItem}
              fulfillmentType={basket && basket.fulfillmentType}
              deviceInfo={deviceInfo}
              source={ANALYTICS_SOURCES.PRODUCT_DETAIL}
            />
          ))}
        </ItemsWrapper>
      </Fragment>
    );
    const renderLoading = !featuredList && <Loading isLight={false} />;
    const renderBreadCrumb = productDetail && (
      <BreadCrumb>{renderCategoryLinks}</BreadCrumb>
    );
    const renderNoProducts = !productDetail &&
      loading &&
      loading.searchResults === false && <NotFound>Product not found</NotFound>;
    const renderDisclaimer = productDetail && (
      <Disclaimer>
        {renderItems}
        {renderLoading}
        <SmallPrintTitle>DISCLAIMER</SmallPrintTitle>
        <SmallPrint>
          The above information has been prepared to assist in the selection of
          your purchase.
        </SmallPrint>
        <SmallPrint>
          Whilst we have taken great care in preparing these details and believe
          that they are accurate our suppliers may vary the ingredients or
          contents of the product at short notice without notifying JJ Food
          Service Limited. We therefore strongly advise that you read the
          product packaging and labels prior to use.
        </SmallPrint>
        <SmallPrint>
          If you do require more precise ingredient and content information you
          should consult the manufacturer, whose contact details will appear on
          the packaging or labels. For JJ Food Service Limited branded products
          please contact our JJ customer service department on
          <Email href="mailto:info@jjfoodservice.com">
            info@jjfoodservice.com
          </Email>
        </SmallPrint>
      </Disclaimer>
    );
    return (
      <Fragment>
        {renderProductBar}
        {renderBreadCrumb}
        <Container data-rw="product--container">
          {renderLoadingProductDetail}
          {renderNoProducts}
          {renderProductDetail}
        </Container>
        {renderDisclaimer}
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    productDetailData: state.productDetail,
    loading: state.loading,
    branch: state.branch,
    route: state.route,
    basketHashMap: state.basketHashMap,
    recommendedItems: state.recommendedItems,
    basket: state.basket,
    settings: state.settings,
    auth: state.auth,
    substitutes: state.substitutes,
    prevRoute: state.prevRoute,
    shoppingLists: state.shoppingLists,
  };
};

const mapDispatchToProps = dispatch => ({
  getProductDetail: bindActionCreators(getProductDetail, dispatch),
  setCurrentRoute: bindActionCreators(setCurrentRoute, dispatch),
  getRecommendedFunc: bindActionCreators(getRecommended, dispatch),
  addFavouriteFunc: bindActionCreators(addFavourite, dispatch),
  removeFavouriteFunc: bindActionCreators(removeFavourite, dispatch),
  setPrevRoute: bindActionCreators(setPrevRoute, dispatch),
  getShoppingListFunc: bindActionCreators(getShoppingList, dispatch),
  createAShoppingListFunc: bindActionCreators(createAShoppingList, dispatch),
  addToShoppingListFunc: bindActionCreators(addToShoppingList, dispatch),
  removeFromShoppingListFunc: bindActionCreators(
    removeFromShoppingList,
    dispatch
  ),
  resetProductDetail: bindActionCreators(resetProductDetail, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductDetailPage);
