import logo from '../images/logo.png';
import {
  CLOSED_TIME,
  PAGE_DES,
  RESERVED_WORDS,
  ROUTES,
} from '../constants/constants';

export const getHeadTags = () => {
  // about ogType: http://ogp.me/#types
  // https://webcode.tools/open-graph-generator/product
  const meta = [
    {charset: 'utf-8'},
    {
      name: 'description',
      content: PAGE_DES.DESCRIPTION,
    },
    {
      name: 'keywords',
      contents:
        'Burger restaurant supplier, chicken shop supplier, fish and chip shop supplier, pizza shop supplier throughout the UK cooking oil, frying oils, fish and chips, packaging, curries, gravies, pickles, sauces, wholesaler, cash and carry',
    },
    {property: 'og:site_name', content: PAGE_DES.TITLE},
    {property: 'og:image', content: logo},
    {property: 'og:locale', content: 'en_UK'},
    {
      property: 'og:title',
      content: PAGE_DES.TITLE,
    },
    {property: 'og:description', content: PAGE_DES.DESCRIPTION},
    {property: 'og:card', content: 'summary'},
    {property: 'og:site', content: '@jjfoodservice'},
    {property: 'og:creator', content: '@jjfoodservice'},

    {property: 'og:type', content: 'product'},
  ];

  return {
    titleTemplate: `${PAGE_DES.TITLE} %s`,
    title: PAGE_DES.TITLE_CONTENT,
    meta,
  };
};

export const setCmsHeadTags = tags => {
  if (tags.pageTitle) {
    document.title = tags.pageTitle;
    const ogTitleElement = document.querySelector('meta[property="og:title"]');
    if (ogTitleElement) {
      ogTitleElement.setAttribute('content', tags.pageTitle);
    }
  }
  const descriptionElement = document.querySelector('meta[name="description"]');
  const ogDescriptionElement = document.querySelector(
    'meta[property="og:description"]'
  );
  if (descriptionElement && tags.metaDescription) {
    descriptionElement.setAttribute('content', tags.metaDescription);
  }
  if (ogDescriptionElement && tags.metaDescription) {
    ogDescriptionElement.setAttribute('content', tags.metaDescription);
  }
};

export const setPageHeadTags = tags => {
  if (tags.title) {
    document.title = tags.title;
    const ogTitleElement = document.querySelector('meta[property="og:title"]');
    if (ogTitleElement) {
      ogTitleElement.setAttribute('content', tags.title);
    }
  }
  const descriptionElement = document.querySelector('meta[name="description"]');
  const ogDescriptionElement = document.querySelector(
    'meta[property="og:description"]'
  );
  if (descriptionElement && tags.description) {
    descriptionElement.setAttribute('content', tags.description);
  }
  if (ogDescriptionElement && tags.description) {
    ogDescriptionElement.setAttribute('content', tags.description);
  }
};

export const getOgData = product => {
  if (!product) {
    return {
      og: false,
    };
  }
  const categories = !!product.categoryName
    ? product.categoryName.split(',')
    : '';
  let category;
  if (categories.length > 0) {
    category = categories[categories.length - 1];
  } else {
    category = product.categoryName;
  }
  const description = `Buy ${category} at wholesale prices from JJ Foodservice. Order online or at one of our branches today. Open to all.`;
  let collectionPrice = product.collection && product.collection.price;
  if (!collectionPrice) {
    collectionPrice = product.price;
  }
  let deliveryPrice = product.delivery && product.delivery.price;
  if (!deliveryPrice) {
    deliveryPrice = product.price;
  }
  return {
    og: true,
    title: `Buy ${product.itemName} - Order Online From JJ Foodservice`,
    image: product.imageLinks && product.imageLinks[0],
    description,
    itemDescription:
      (product.productDescription &&
        product.productDescription.toLowerCase() !==
          RESERVED_WORDS.NOT_AVAILABLE &&
        product.productDescription) ||
      description,
    category: product.categoryName,
    product_type: (categories || []).join(' > ').replaceAll(',', ''),
    amount: product.price,
    collectionPrice,
    deliveryPrice,
    itemName: product.itemName,
    sku: product.itemId,
    brand: product.brand,
    branches: product.branches,
    priceValidUntil: (product.offer && product.offer.promoEndIso) || '',
    ...(product.itemNetWeight && {weight: `${product.itemNetWeight} kg`}),
  };
};

export const getSchemaOpeningTimes = openingTimes =>
  openingTimes
    .slice(0, 7)
    .map((day, i) => {
      const isOpen = day && day.from !== CLOSED_TIME && day.to !== CLOSED_TIME;
      return (
        isOpen && {
          '@type': 'OpeningHoursSpecification',
          dayOfWeek: [day.day],
          opens: day.from,
          closes: day.to,
        }
      );
    })
    .filter(item => typeof item === 'object');
