import {buffers} from 'redux-saga';
import {
  put,
  take,
  fork,
  select,
  delay,
  retry,
  actionChannel,
  cancel,
} from 'redux-saga/effects';
import basketApi from '../api/basket.api';
import * as ActionTypes from '../actions/types.action';
import {getBearerHeader} from '../helpers/api.helper';
import {captureException, withScope} from '@sentry/browser';
import {
  BASKET_RETRY_TIMEOUT,
  DEFAULT_DEBOUNCE_TIME,
  ERRORS,
  MAX_RETRY,
  PAYMENT_TYPES,
  SERVICE_TYPE,
  STOCK_CHECK,
  UPDATING_TYPES,
} from '../constants/constants';
import {createHashMap, getAlternativeGroupId} from '../helpers/data.helper';
import {
  generateProductArrayInfo,
  generateProductInfo,
} from '../helpers/product.helper';
import {getSubstitutes} from './basket.saga';

let groupedItems = [];

function* handleRequest(action, userContents, groupedItems) {
  try {
    const {branch, profile, headers, storeBasket, settings} = userContents;
    let data;
    const basketUuid = storeBasket && storeBasket.uuid;
    const isUpdating = !!basketUuid;
    let product;
    const branchId =
      (settings && settings.branchId) ||
      (profile && profile.branchId) ||
      branch;
    const settingsFulfillmentType =
      settings && settings.fulfillment && settings.fulfillment.toUpperCase();
    const fulfillmentType =
      settingsFulfillmentType &&
      settingsFulfillmentType !== SERVICE_TYPE.UNSELECTED
        ? settingsFulfillmentType
        : SERVICE_TYPE.COLLECTION;
    const actuallyFulfillmentType =
      (storeBasket && storeBasket.fulfillmentType) || fulfillmentType;
    const isCollection = actuallyFulfillmentType === SERVICE_TYPE.COLLECTION;
    if (action.item && groupedItems && groupedItems.length < 2) {
      product = generateProductInfo(action.item, isCollection);
    }
    let totalItems;
    if (groupedItems && groupedItems.length > 1) {
      totalItems = generateProductArrayInfo(groupedItems, isCollection);
    }
    if (isUpdating) {
      data = totalItems ? totalItems : [product];
    } else {
      const paymentMethod =
        (settings &&
          settings.paymentType &&
          PAYMENT_TYPES[settings.paymentType] &&
          PAYMENT_TYPES[settings.paymentType].mode) ||
        PAYMENT_TYPES.CDC.mode;
      data = {
        branchId,
        items: totalItems ? totalItems : [product],
        paymentMethod,
        fulfillmentType,
      };
    }
    const basket = yield retry(
      MAX_RETRY,
      BASKET_RETRY_TIMEOUT,
      basketApi.updateBasket,
      isUpdating,
      data,
      headers
    );
    if (basket) {
      const basketHashMap = createHashMap(basket.items);
      yield put({type: ActionTypes.UPDATE_BASKET_SUCCEEDED, basket});
      yield put({
        type: ActionTypes.SET_BASKET_HASHMAP_SUCCEEDED,
        basketHashMap,
      });
      const updatingItems = totalItems ? totalItems : [product];

      let updatedBasket = {
        items: [],
        branchId: basket.branchId,
        fulfillAtSlot: basket.fulfillAtSlot,
      };
      // go through basket items which has been added recently
      updatingItems.map(item => {
        if (
          basketHashMap[item.itemId] &&
          basketHashMap[item.itemId].stockCheck &&
          basketHashMap[item.itemId].stockCheck.result ===
            STOCK_CHECK.OUT_OF_STOCK
        ) {
          // get basket items matching itemId for recently added items
          const basketItem = basket.items.filter(
            basketItem => basketItem.itemId === item.itemId
          )[0];

          // get alternative group ids
          const altItemGroupId = getAlternativeGroupId(
            item.itemId,
            userContents.searchResults,
            userContents.productDetail
          );
          // add items with alternative ids
          updatedBasket.items.push({
            product: {
              altItemGroupId,
            },
            itemId: item.itemId,
            stockCheck: basketItem.stockCheck,
          });
        }
      });
      // get substitutes for recently added items
      if (updatedBasket.items.length > 0) {
        yield getSubstitutes(updatedBasket, headers);
      }
    }
    yield put({type: ActionTypes.ADD_TO_BASKET_FINISHED});
  } catch (e) {
    const {response} = e;
    if (!response || (response && !response.data)) {
      yield put({
        type: ActionTypes.UPDATE_BASKET_FAILED,
        message: e,
      });
    } else {
      const {data} = response;
      if (data.error === ERRORS.UNAUTHORIZED) {
        yield put({type: ActionTypes.ERROR_LOGIN_EXPIRED});
      } else if (
        data.error === ERRORS.EXPIRED ||
        data.code === ERRORS.NEED_AUTH
      ) {
        yield put({
          type: ActionTypes.ERROR_LOGIN_EXPIRED,
          error: {
            type: UPDATING_TYPES.BASKET,
            item: action.item,
          },
        });
      }
      yield put({
        type: ActionTypes.UPDATE_BASKET_FAILED,
        message: data.message,
        code: data.code,
      });
    }
    yield put({type: ActionTypes.ADD_TO_BASKET_FINISHED});
    if (response.data.code !== ERRORS.SLOT_EXPIRED) {
      withScope(scope => {
        scope.setExtra('action', action);
        captureException(e);
      });
    }
  }
}

export default function* updateBasket() {
  const buffer = buffers.expanding();
  const requestChan = yield actionChannel(
    ActionTypes.UPDATE_BASKET_REQUESTED,
    buffer
  );
  let task;
  while (true) {
    const payload = yield take(requestChan);
    groupedItems.push(payload.item);
    if (task) {
      yield cancel(task);
    }
    const getBasket = state => state.basket;
    const getBranch = state => state.branch;
    const getAuth = state => state.auth;
    const getProfile = state => state.profile;
    const getSettings = state => state.settings;
    const getSearchResults = state => state.searchResults;
    const getProductDetail = state => state.productDetail;
    const branch = yield select(getBranch);
    const storeToken = yield select(getAuth);
    if (storeToken && !storeToken.enhanced) {
      return;
    }
    const profile = yield select(getProfile);
    const headers = getBearerHeader(payload.jjToken, storeToken);
    const storeBasket = yield select(getBasket);
    let settings = yield select(getSettings);
    let time = 0;
    while (!settings) {
      time += 1;
      settings = yield select(getSettings);
      yield delay(200);
      if (time > 100) {
        yield put({type: ActionTypes.GET_ALL_SETTINGS_FAILED});
        break;
      }
    }
    let searchResults = yield select(getSearchResults);
    let productDetail = yield select(getProductDetail);
    const userContents = {
      branch,
      profile,
      headers,
      storeBasket,
      settings,
      searchResults,
      productDetail,
    };
    yield delay(DEFAULT_DEBOUNCE_TIME);
    if (buffer.isEmpty()) {
      task = yield fork(handleRequest, payload, userContents, groupedItems);
      groupedItems = [];
    }
  }
}
