import React, {PureComponent, Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import queryString from 'query-string';
import InfiniteScroll from 'react-infinite-scroll-component';

import {search, moreSearch, getSearchCategoryDescription} from '../../actions';
import SearchBar from '../../components/Search/SearchBar';
import Product from '../../components/Product/Product';
import Loading from '../../components/Loading/Loading';
import {
  Container,
  BreadCrumb,
  BreadCrumbLinks,
  CurrentPage,
  FiltersWrapper,
  FilterInfo,
  ResultsPanel,
  ResultsWrapper,
  ResultsTitle,
  Title,
  NoResult,
  LoadMore,
  ClearFilter,
  FiltersButtons,
  ClearFilterButton,
  DoneButton,
  LoadMoreButton,
  FilterButtonWrapper,
  FilterButton,
  Status,
  Separator,
  NameWrapper,
  BreadCrumbResult,
  CategoryDescription,
} from './SearchPage.style';
import {LeftLine, RightLine} from '../../styles/components/lines';
import Filters from '../../components/Filters/Filters';
import {
  JJ_LOCAL_STORAGE,
  PAGE_SIZE,
  ROUTES,
  SEARCH_SORT_TYPE,
  ANALYTICS_SOURCES,
  SEARCH_TITLE,
  SEARCH_PARAMS,
  SCROLL_THRESHOLD,
  SEARCH_ADVANCED_PARAMS,
  SORT_TYPES,
  DESCRIPTIONS,
  FILTERS_NAME,
  LONG_CATEGORY_LIST_FILTER_LENGTH,
  QUERY_PROMO_TAG_ID,
} from '../../constants/constants';
import {setCurrentRoute} from '../../actions';
import {
  checkIfStringIsDefined,
  getBranchFromUrl,
  getBreadCrumbLinks,
  getCategoryDescriptionKeyword,
  getCategoryName,
  getCategoryNamesFromUrl,
  getDisplayName,
  getTotalCount,
  titleCase,
  trimComma,
} from '../../helpers/data.helper';
import {getDataFromLocalStorage} from '../../helpers/localStorage.helper';
import BreadCrumbLink from './BreadCrumbLink';
import {syncBasketQuantityWithProducts} from '../../helpers/product.helper';
import {
  BRAND_PATH,
  CATEGORY_LEVEL1_PATH,
  CATEGORY_LEVEL2_PATH,
  CATEGORY_LEVEL3_PATH,
  CATEGORY_ROOT_PATH,
  SEARCH_PATH,
} from '../../constants/routePaths';
import {setCmsHeadTags, setPageHeadTags} from '../../helpers/seo.helper';
import {loadSearchResultsData} from '../../routes/loadDataForRoutes';
import ToTheTop from '../../components/ToTheTop/ToTheTop';
import {setPrevRoute} from '../../actions/prevRoute.action';

class SearchPage extends PureComponent {
  static propTypes = {
    searchResultsData: PropTypes.array,
  };

  constructor(props) {
    super(props);
    const {location, deviceInfo, match, searchResultsData} = props;
    let updatingUrl = false;
    this.searchTitleForSSR = null;
    this.adTitleDescription = null;
    this.firstSearch = searchResultsData && searchResultsData.length > 0;
    let urlParams;
    if (typeof window !== 'undefined' && window.location) {
      const urlSearchParams = new URLSearchParams(window.location.search);
      urlParams = Object.fromEntries(urlSearchParams.entries());
    }
    const hideDes = !!urlParams && urlParams.noDes === 'true';
    if (match && match.path === SEARCH_PATH && typeof window !== 'undefined') {
      if (
        urlParams &&
        urlParams.adTitle &&
        urlParams.adTitle.toLowerCase() &&
        !hideDes &&
        DESCRIPTIONS[urlParams.adTitle.toLowerCase()]
      ) {
        this.adTitleDescription = DESCRIPTIONS[urlParams.adTitle.toLowerCase()];
      }
    }
    if (match && match.path !== SEARCH_PATH && match.params) {
      let params = {};
      switch (match.path) {
        case BRAND_PATH: {
          const {brandName} = match.params;
          params = Object.assign({}, SEARCH_ADVANCED_PARAMS, {
            b: getBranchFromUrl(match),
            brand: brandName,
          });
          this.searchTitleForSSR = brandName;
          updatingUrl = true;
          break;
        }
        case CATEGORY_ROOT_PATH:
        case CATEGORY_LEVEL1_PATH:
        case CATEGORY_LEVEL2_PATH:
        case CATEGORY_LEVEL3_PATH: {
          const {
            brandName,
            category1Name,
            category2Name,
            category3Name,
          } = match.params;
          params = Object.assign({}, SEARCH_ADVANCED_PARAMS, {
            b: getBranchFromUrl(match),
            brand: brandName,
          });
          const checkCategory1Name = checkIfStringIsDefined(category1Name);

          if (checkCategory1Name) {
            getSearchCategoryDescription({
              category: category1Name,
            });
          }
          const categoryNames = getCategoryNamesFromUrl(
            category1Name,
            category2Name,
            category3Name
          );
          if (categoryNames.length > 0) {
            params = {
              ...params,
              ...{sortType: SORT_TYPES.CATEGORY},
              ...{categoryNames},
            };
          }
          this.searchTitleForSSR = titleCase(
            trimComma(getCategoryName(categoryNames))
          );
          updatingUrl = true;
          break;
        }
      }
      if (updatingUrl) {
        this.updateSearchUrl(props.history, params);
      }
    }
    this.state = {
      params: null,
      gridView: !(deviceInfo && deviceInfo.isPhone),
      showFilterInMobile: false,
      isCurrentSearch: false,
      searchTitle:
        (location && location.state && location.state.searchTitle) ||
        this.searchTitleForSSR ||
        null,
      hideDes,
      totalCategoryListCount: 0,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.location.search !== prevState.search) {
      const {location, branch, history} = nextProps;
      const params = queryString.parse(location.search);
      const prevParams = queryString.parse(prevState.search);
      if (!params.b && !params.size && params.q) {
        const newParams = Object.assign({}, params, {
          b: branch,
          size: SEARCH_PARAMS.size,
        });
        history.replace({
          pathname: '/search',
          search: queryString.stringify(newParams),
        });
      }
      return {
        params,
        search: location.search,
        isCurrentSearch: params.q === prevParams.q,
        showFilterInMobile: false,
      };
    }
    return null;
  }

  async componentDidMount() {
    const {
      fulfillmentDetails,
      isNew,
      prevRoute,
      search,
      route,
      setCurrentRoute,
      history,
      location,
      getSearchCategoryDescription,
      searchCategoryDescription,
      setPrevRoute,
    } = this.props;
    setPrevRoute(ROUTES.SEARCH_RESULTS);
    const authToken = await getDataFromLocalStorage(JJ_LOCAL_STORAGE.TOKEN);
    this.fulfillmentDetailsFromLocal = await getDataFromLocalStorage(
      JJ_LOCAL_STORAGE.FULFILLMENT
    );
    const {state} = location;
    // reset the search title router state after redirect
    if (state && state.searchTitle) {
      const state = {state: {}};
      history.replace({
        ...location,
        state,
      });
    }
    // stop trigger a new search which causes page reload
    // solution for navigate back from product detail page
    if (
      route === ROUTES.PRODUCT &&
      (!location.state || (location.state && !location.state.newSearch)) &&
      isNew
    ) {
      setCurrentRoute(ROUTES.SEARCH_RESULTS);
      return;
    }
    setCurrentRoute(ROUTES.SEARCH_RESULTS);
    const isNewSearch =
      history.location &&
      history.location.state &&
      history.location.state.newSearch;
    if (isNewSearch) {
      const newState = {...history.location.state};
      delete newState.newSearch;
      history.replace({...history.location, newState});
    }
    if (this.state.params && !this.state.params.q) {
      return;
    }
    if (!this.state.hideDes) {
      getSearchCategoryDescription({
        category: getCategoryDescriptionKeyword(
          this.state.params.category,
          this.state.params.categoryNames
        ),
      });
    }
    const ownBrandTitle = this.state.params.brand || this.state.params.adTitle;
    const isPrerender = navigator.userAgent.indexOf('Prerender') > -1;
    if (ownBrandTitle && isPrerender && !this.state.hideDes) {
      getSearchCategoryDescription({
        brand: ownBrandTitle,
      });
    }
    if (searchCategoryDescription) {
      setCmsHeadTags({metaDescription: searchCategoryDescription});
    }

    // should stop trigger search again while nav back from product screen
    if (prevRoute === ROUTES.PRODUCT && !isNewSearch) {
      return;
    }
    const fulfillmentDetailsObj =
      fulfillmentDetails || this.fulfillmentDetailsFromLocal;
    const params = Object.assign({}, this.state.params, {page: 0});
    const isInitialLoad = true;
    this.updateSearchUrl(history, params, isInitialLoad);
    if (authToken) {
      const token = queryString.parse(authToken);
      if (token) {
        search(params, token.access_token || authToken, fulfillmentDetailsObj);
      }
    } else {
      search(params, null, fulfillmentDetailsObj);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      location,
      auth,
      search,
      branch,
      history,
      aggression,
      prevRoute,
      loading,
      searchCategoryDescription,
    } = this.props;
    const {params, hideDes} = this.state;
    if (branch && params && (!params.b || params.b !== branch)) {
      const newParams = {...params, ...{b: branch}};
      history.replace({
        pathname: '/search',
        search: queryString.stringify(newParams),
      });
    }
    if (prevProps.location.search !== location.search) {
      this.setState({searchTitle: null});
    }
    if (
      !prevProps.auth &&
      prevProps.auth !== auth &&
      auth.enhanced &&
      prevRoute !== ROUTES.PRODUCT
    ) {
      search(this.state.params, null, this.fulfillmentDetailsFromLocal);
    }
    if (location.search !== prevProps.location.search) {
      const params = queryString.parse(location.search);
      const prevParams = queryString.parse(prevProps.location.search);
      if (
        params.b !== prevParams.b ||
        params.q !== prevParams.q ||
        params.isNew !== prevParams.isNew ||
        params.deliveryDate !== prevParams.deliveryDate
      ) {
        search(params, null, this.fulfillmentDetailsFromLocal);
        this.scrollToTop();
      }
    }
    if (prevProps.location.search !== location.search && !hideDes) {
      getSearchCategoryDescription({
        category: getCategoryDescriptionKeyword(
          params.category,
          params.categoryNames
        ),
      });
    }
    if (prevProps.aggression !== aggression) {
      const title = getDisplayName(params, aggression, false);
      setPageHeadTags(loadSearchResultsData(title));
      let totalCategoryListCount = 0;
      aggression.forEach(filter => {
        if (filter.name === FILTERS_NAME.categoryList && filter.data) {
          totalCategoryListCount = getTotalCount(filter.data);
        }
      });
      if (!!totalCategoryListCount) {
        this.setState({totalCategoryListCount});
      }
    }
    if (
      prevProps.loading &&
      prevProps.loading.searchResults &&
      loading &&
      !loading.searchResults
    ) {
      this.firstSearch = false;
    }
  }

  scrollToTop = () => window.scrollTo(0, 300);

  updateSearchUrl = (history, params, isInitialLoad) => {
    const searchParams = {
      pathname: '/search',
      search: queryString.stringify(params),
    };
    if (isInitialLoad) {
      history.replace(searchParams);
    } else {
      history.push(searchParams);
    }
  };

  updateSearchParams = (updatedParams, resetPageNumber) => {
    const {
      history,
      search,
      location,
      setCurrentRoute,
      searchResultsData,
    } = this.props;
    const urlParams = queryString.parse(location.search);
    let params = Object.assign({}, urlParams, updatedParams);
    if (resetPageNumber) {
      params = Object.assign({}, params, {page: 0});
    }
    if (params.category) {
      delete params.categoryNames;
    }
    this.updateSearchUrl(history, params);
    this.setState({params});
    setCurrentRoute(ROUTES.SEARCH);

    search(params, null, this.fulfillmentDetailsFromLocal);
  };

  clearFilters = () => {
    const {q, b, size, advanced, category, adTitle} = this.state.params;
    let params;

    if (
      (q === '*' || q.indexOf(QUERY_PROMO_TAG_ID) > -1) &&
      advanced === 'true'
    ) {
      params = {q, b, advanced, category, adTitle};
    } else {
      params = {q, b, size};
    }

    const {history, search} = this.props;
    this.updateSearchUrl(history, params);
    search(params);
    this.scrollToTop();
  };

  toggleGridView = () => this.setState({gridView: !this.state.gridView});

  loadMore = () => {
    const {moreSearch, location, page, fulfillmentDetails} = this.props;
    const urlParams = queryString.parse(location.search);
    const params = Object.assign({}, urlParams, {
      page: page.number + 1,
    });
    this.props.history.push({
      pathname: '/search',
      search: `?${queryString.stringify(params)}`,
    });
    moreSearch(params, null, fulfillmentDetails);
  };

  toggleFilterInMobile = () =>
    this.setState({showFilterInMobile: !this.state.showFilterInMobile});

  closeFilterInMobile = () => {
    this.setState({showFilterInMobile: false});
    window.scrollTo(0, 300); // todo: replace this with element height
  };

  render() {
    const {
      searchResultsData,
      page,
      branch,
      loading,
      aggression,
      updateBasket,
      updateItem,
      removeItem,
      basketHashMap,
      basket,
      onProductClick,
      deviceInfo,
      keywordSearch,
      searchCategoryDescription,
    } = this.props;
    const searchResults = syncBasketQuantityWithProducts(
      basketHashMap,
      searchResultsData
    );
    const {
      params,
      gridView,
      showFilterInMobile,
      searchTitle,
      isCurrentSearch,
      hideDes,
      totalCategoryListCount,
    } = this.state;
    let isSearchAll;
    try {
      isSearchAll = decodeURIComponent(params.q) === '*';
    } catch (e) {
      isSearchAll = false;
    }
    const isSearchAllAdvanced = isSearchAll && params.advanced === 'true';
    const isCategorySearch = isSearchAllAdvanced && params.isNew !== 'true';
    const isNewSearch = isSearchAllAdvanced && params.isNew === 'true';
    const displayName = searchTitle
      ? searchTitle
      : getDisplayName(params, aggression, isSearchAllAdvanced);
    const appliedFilters = {
      sizeOrCut: !!params.sizeOrCut,
      origin: !!params.origin,
      brand: !!params.brand,
      productFeatures: !!params.productFeatures,
      categoryList: !!params.category,
    };
    const renderSearchResultItems =
      searchResults &&
      searchResults.length > 0 &&
      searchResults.map((product, index) => (
        <Product
          product={product}
          branch={branch}
          key={product.itemId + index}
          isListView={!gridView}
          onProductClick={onProductClick}
          loading={loading}
          basketHashMap={basketHashMap}
          updateBasket={updateBasket}
          updateItem={updateItem}
          removeItem={removeItem}
          fulfillmentType={basket && basket.fulfillmentType}
          deviceInfo={deviceInfo}
          source={ANALYTICS_SOURCES.SEARCH}
          keywordSearch={keywordSearch}
        />
      ));
    const renderEmptyResults = loading &&
      !loading.searchResults &&
      loading.searchResults !== null &&
      searchResults &&
      searchResults.length === 0 && (
        <NoResult>Sorry, no matching product found</NoResult>
      );
    const renderFilterTitle = page && !!page.totalElements && (
      <FilterInfo>
        <Title>{`Filter ${page.totalElements} products by:`}</Title>
        <ClearFilter onClick={this.clearFilters}>clear filters</ClearFilter>
      </FilterInfo>
    );
    const breadCrumbLinks = getBreadCrumbLinks(isSearchAll, aggression);
    const renderBreadCrumbLinks =
      breadCrumbLinks &&
      Array.isArray(breadCrumbLinks) &&
      breadCrumbLinks.map((link, i) => (
        <BreadCrumbLink
          link={link}
          key={link.id}
          length={breadCrumbLinks.length}
          index={i}
          callback={this.updateSearchParams}
        />
      ));
    const renderFilterButtonName =
      page && page.totalElements && `${page.totalElements} products `;
    const renderFilterButton = searchResults && searchResults.length > 0 && (
      <FilterButtonWrapper>
        <FilterButton onClick={this.toggleFilterInMobile}>
          <span>Filter {renderFilterButtonName}By</span>
          <Status $selected={showFilterInMobile} />
        </FilterButton>
      </FilterButtonWrapper>
    );
    const renderFilters = aggression && totalCategoryListCount !== 0 && (
      <Filters
        params={params}
        updateSearchParams={this.updateSearchParams}
        filters={aggression}
        filtersCount={aggression.data || aggression.counts}
        appliedFilters={appliedFilters}
        showInMobile={showFilterInMobile}
        isCategorySearch={isCategorySearch}
        isCurrentSearch={isCurrentSearch}
        closedByDefault={deviceInfo && deviceInfo.isPhone}
        totalCategoryListCount={totalCategoryListCount}
      />
    );
    const searchResultTitle = params.adTitle
      ? params.adTitle
      : params.q
      ? params.q
      : displayName;
    const renderCurrentSearchTextContent = searchTitle
      ? searchTitle
      : isNewSearch
      ? SEARCH_TITLE.NEW
      : `Search Results for "${searchResultTitle}"`;
    const renderCurrentSearchTextField = isCategorySearch ? (
      renderBreadCrumbLinks
    ) : (
      <CurrentPage>
        <Separator>&gt;</Separator>
        <BreadCrumbResult>{renderCurrentSearchTextContent}</BreadCrumbResult>
      </CurrentPage>
    );
    const hasMore = page && page.number < page.totalPages - 1;
    const renderSearchResultsLoading = !this.firstSearch &&
      loading &&
      loading.searchResults && <Loading />;
    const renderLoadMore = hasMore &&
      totalCategoryListCount > LONG_CATEGORY_LIST_FILTER_LENGTH && (
        <LoadMoreButton onClick={this.loadMore}>Load more</LoadMoreButton>
      );
    const renderSearchResults = (!(loading && loading.searchResults) ||
      this.firstSearch) && (
      <InfiniteScroll
        hasMore={hasMore}
        next={this.loadMore}
        dataLength={searchResults.length}
        scrollThreshold={SCROLL_THRESHOLD}
        loader={
          <LoadMore>
            <Loading />
          </LoadMore>
        }
      >
        {renderSearchResultItems}
        {renderLoadMore}
      </InfiniteScroll>
    );
    const renderGoTop = page && page.number > 0 && <ToTheTop />;
    const renderResultsTitle = displayName && (
      <Fragment>
        <LeftLine />
        <NameWrapper>{displayName}</NameWrapper>
        <RightLine />
      </Fragment>
    );
    const renderCategoryDescription = searchCategoryDescription && !hideDes && (
      <CategoryDescription>{searchCategoryDescription}</CategoryDescription>
    );
    const renderAdTitleDescription = this.adTitleDescription && !hideDes && (
      <CategoryDescription>{this.adTitleDescription}</CategoryDescription>
    );
    return (
      <Fragment>
        <SearchBar
          numberOfProducts={page && page.totalElements}
          updateSearchParams={this.updateSearchParams}
          sortType={params.sortType || SEARCH_SORT_TYPE.RELEVANCE}
          size={params.size || PAGE_SIZE.M}
          gridView={gridView}
          toggleGridView={this.toggleGridView}
          deviceInfo={deviceInfo}
        />
        <BreadCrumb>
          <BreadCrumbLinks to="/">Home</BreadCrumbLinks>
          {renderCurrentSearchTextField}
        </BreadCrumb>
        <Container>
          {renderFilterButton}
          <FiltersWrapper $showInMobile={showFilterInMobile}>
            {renderFilterTitle}
            {renderFilters}
            <FiltersButtons>
              <DoneButton onClick={this.closeFilterInMobile}>Done</DoneButton>
              <ClearFilterButton onClick={this.clearFilters}>
                Clear All
              </ClearFilterButton>
            </FiltersButtons>
          </FiltersWrapper>
          <ResultsPanel>
            <ResultsTitle>{renderResultsTitle}</ResultsTitle>
            {renderCategoryDescription}
            {renderAdTitleDescription}
            <ResultsWrapper $gridView={gridView}>
              {renderSearchResultsLoading}
              {renderSearchResults}
              {renderEmptyResults}
              {renderGoTop}
            </ResultsWrapper>
          </ResultsPanel>
        </Container>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  searchResultsData: state.searchResults,
  loading: state.loading,
  aggression: state.aggression,
  page: state.page,
  route: state.route,
  branch: state.branch,
  auth: state.auth,
  basketHashMap: state.basketHashMap,
  basket: state.basket,
  fulfillmentDetails: state.fulfillmentDetails,
  prevRoute: state.prevRoute,
  searchCategoryDescription: state.searchCategoryDescription,
});

const mapDispatchToProps = dispatch => ({
  search: bindActionCreators(search, dispatch),
  moreSearch: bindActionCreators(moreSearch, dispatch),
  setCurrentRoute: bindActionCreators(setCurrentRoute, dispatch),
  getSearchCategoryDescription: bindActionCreators(
    getSearchCategoryDescription,
    dispatch
  ),
  setPrevRoute: bindActionCreators(setPrevRoute, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchPage);
