import React, {Fragment, PureComponent} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import queryString from 'query-string';

import {
  Wrapper,
  Title,
  Head,
  LinkButton,
  ButtonWrapper,
} from '../../styles/pages/pages';
import {
  SectionTitle,
  SectionText,
  SectionWrapper,
  TheLink,
  Tel,
  SubmitTakingTooLong,
  Error,
} from './ConfirmationPage.style';
import {removeBasket, setCurrentRoute} from '../../actions';
import {authAdyenPayment, resetCheckout} from '../../actions/checkout.action';
import MarketingOptions from '../../components/Marketing/MarketingOptions';
import {resetNextRoute} from '../../actions/nextRoute.action';
import {removeSetting} from '../../actions/settings.action';
import {
  AX_ORDER_STATUS,
  JJ_TEL_FULL,
  PAYMENT_TYPES,
  SERVICE_TYPE,
  ROUTES,
  CHECKOUT_TAKING_LONGER_TIMEOUT,
  CHECKOUT_TAKING_LONGEST_TIMEOUT,
  ADYEN_DONATION_CAMPAIGN,
  JJ_LOCAL_STORAGE,
} from '../../constants/constants';
import {getOrderAfterCheckout, resetOrder} from '../../actions/order.action';
import {GoogleAnalyticsHelper} from '../../helpers/googleAnalytics.helper';
import Loading from '../../components/Loading/Loading';
import {
  extractCollectionTime,
  extractDeliveryTime,
} from '../../helpers/array.helper';
import PopupModal from '../../components/PopupModal/PopupModal';
import {AdyenCheckout, Donation} from '@adyen/adyen-web';
import {AdyenContent, AydenWrapper} from '../CheckoutPage/CheckoutPage.style';
import {captureException} from '@sentry/browser';
import {checkIsDev} from '../../helpers/data.helper';
import {AdyenStyle} from '../../styles/adyen.style';
import {removeValueFromLocalStorage} from '../../helpers/localStorage.helper';

class ConfirmationPage extends PureComponent {
  requestOrder = true;
  receivedOrder = false;
  paymentRequiresMultiAuthSet = false;

  constructor(props) {
    super(props);
    this.state = {
      isSubmitTakingLonger: false,
      showPaymentRequiresMultiAuth: false,
      showDonationDropin: true,
      showDonationThankYou: false,
    };
    this.donationDropin = null;
  }

  componentDidMount() {
    const {
      location,
      setCurrentRoute,
      resetNextRoute,
      history,
      order,
    } = this.props;
    resetNextRoute();
    setCurrentRoute(ROUTES.CONFIRMATION);
    if (location && location.state) {
      if (location.state.basketId) {
        this.basketId = location.state.basketId;
      }
      if (location.state.axBasketId) {
        this.basketId = location.state.axBasketId;
      }
      if (location.state.orderId) {
        this.orderId = location.state.orderId;
      }
      if (location.state.fromOrders) {
        this.fromOrders = true;
      }
    }
    const params = queryString.parse(location.search);
    if (!!params.callback) {
      history.replace({search: ''});
    }
    if (!this.orderId) {
      this.orderId = location.pathname.replace('/confirmation/', '/');
    }

    const timeoutTimes =
      order && order.paymentMethod === PAYMENT_TYPES.PBB.mode
        ? CHECKOUT_TAKING_LONGEST_TIMEOUT
        : CHECKOUT_TAKING_LONGER_TIMEOUT;

    setTimeout(() => {
      this.setState({isSubmitTakingLonger: true});
    }, timeoutTimes);
  }

  componentDidUpdate(prevProps, prevState, prevContext) {
    const {
      adyen,
      auth,
      checkout,
      removeBasket,
      history,
      resetNextRoute,
      order,
      basket,
      getOrderAfterCheckout,
      basketHashMap,
      errors,
    } = this.props;
    if (auth && this.requestOrder) {
      this.requestOrder = false;
      getOrderAfterCheckout(this.orderId);
    }
    if (!!auth && order && order !== prevProps.order) {
      resetNextRoute();
    }
    if (
      auth &&
      order &&
      basket &&
      basketHashMap &&
      order.status &&
      order.status !== AX_ORDER_STATUS.PAYMENT_VOIDED &&
      order.status !== AX_ORDER_STATUS.CANCELLED &&
      order.status !== AX_ORDER_STATUS.SLOT_EXPIRED &&
      order.status !== AX_ORDER_STATUS.EDIT_EXPIRED &&
      order.status !== AX_ORDER_STATUS.TOTAL_DISCREPANCY
    ) {
      // wait until setting & basket & basketHashMap populated to avoid timing issues
      if (order.items && !this.receivedOrder) {
        this.receivedOrder = true;
        history.replace(location.pathname, {});
      }
      if (checkout && checkout.adyenGiving) {
        this.openAdyenGiving();
        try {
          const donationCampaign = JSON.parse(checkout.adyenGiving);
          const donationCampaignName =
            donationCampaign.donationCampaigns[0].causeName;
          this.setState({donationCampaignName});
        } catch (e) {
          console.info(
            'Error parsing checkout.adyenGiving',
            checkout.adyenGiving
          );
        }
      }
      removeBasket();
      resetNextRoute();
      resetCheckout();
      removeValueFromLocalStorage(JJ_LOCAL_STORAGE.ORDER_ID);
      removeValueFromLocalStorage(JJ_LOCAL_STORAGE.ORDER_AX_BASKETID);
    }
    if (
      auth &&
      order &&
      basket &&
      basketHashMap &&
      (order.status === AX_ORDER_STATUS.PAYMENT_VOIDED ||
        order.status === AX_ORDER_STATUS.CANCELLED)
    ) {
      if (!this.receivedOrder) {
        this.receivedOrder = true;
        history.replace(location.pathname, {});
        GoogleAnalyticsHelper.trackFailed(order.items, {
          id: order.orderId,
          revenue: order.price && order.price.total,
          shipping: order.price && order.price.delivery.toString(),
          axBasketId: order.axBasketId,
        });
      }
    }
    if (
      order &&
      order.paymentRequiresMultiAuth &&
      !this.paymentRequiresMultiAuthSet
    ) {
      this.paymentRequiresMultiAuthSet = true;
      this.setState({showPaymentRequiresMultiAuth: true});
    }
    if (
      adyen &&
      adyen.donationStatus === 'success' &&
      window.isSubmittingAdyen
    ) {
      window.isSubmittingAdyen = false;
      this.donationDropin.setStatus('success');
      this.openDonationThankYou();
      this.setState({
        showDonationDropin: false,
      });
    }
    if (
      adyen &&
      adyen.donationStatus === 'failed' &&
      window.isSubmittingAdyen
    ) {
      window.isSubmittingAdyen = false;
      this.donationDropin.setStatus('error');
    }
    if (errors && errors.adyen) {
      this.donationDropin && this.donationDropin.update();
      window.isSubmittingAdyen = false;
    }
  }

  componentWillUnmount() {
    this.props.resetOrder();
  }

  resetPaymentRequiresMultiAuth = () => {
    this.setState({showPaymentRequiresMultiAuth: false});
  };

  openDonationThankYou = () => this.setState({showDonationThankYou: true});
  closeDonationThankYou = () => this.setState({showDonationThankYou: false});

  openAdyenGiving = async callback => {
    const {checkout, authAdyenPayment} = this.props;
    let paymentMethodsResponse = {paymentMethods: []};
    try {
      paymentMethodsResponse = JSON.parse(checkout.paymentMethods);
    } catch (e) {
      return captureException(e);
    }

    const configuration = {
      countryCode: 'GBR',
      paymentMethodsResponse,
      clientKey: checkout.clientKey,
      locale: 'en-US',
      environment: checkIsDev(),
      analytics: {
        enabled: true,
      },
    };
    const checkoutAdyen = await AdyenCheckout(configuration);
    const donationCampaign = JSON.parse(checkout.adyenGiving)
      .donationCampaigns[0];

    const donationConfig = {
      ...donationCampaign,
      onDonate: action => {
        if (action.isValid) {
          try {
            if (window.isSubmittingAdyen) {
              return;
            }
            window.isSubmittingAdyen = true;
            authAdyenPayment(
              checkout.responseUrl,
              {
                type: 'ADYEN',
                callState: 'donate',
                givingDonationRequest: {
                  campaignId: ADYEN_DONATION_CAMPAIGN,
                  amount: action.data.amount.value / 100,
                },
              },
              checkout.orderId
            );
            this.donationDropin.setStatus('loading');
          } catch (error) {
            captureException(error);
          }
        }
      },
      showCancelButton: false,
    };

    this.donationDropin = new Donation(checkoutAdyen, donationConfig).mount(
      '#adyen-donation-container'
    );
    setTimeout(() => {
      this.setState({
        showDonationDropin: false,
      });
    }, 40 * 60 * 1000);
  };

  render() {
    const {auth, order, errors, branchList, loading} = this.props;
    const {
      isSubmitTakingLonger,
      showPaymentRequiresMultiAuth,
      showDonationDropin,
      showDonationThankYou,
      donationCampaignName,
    } = this.state;
    const checkingOrderStatus = !order || (order && !order.status);
    const getOrderError = errors && errors.order;

    const submitTakingLongerText =
      !getOrderError &&
      checkingOrderStatus &&
      isSubmitTakingLonger &&
      'Submitting your order is taking longer than usual.';

    const renderGetOrderFailed = getOrderError && order && !order.status && (
      <SectionText>
        <p>
          Please try to checkout again. You will not be required to pay twice.
        </p>
        <p>
          If the problem persists please call{' '}
          <Tel href={`tel:${JJ_TEL_FULL}`}>Customer Support</Tel>.
        </p>
      </SectionText>
    );
    const renderLoading = checkingOrderStatus && !getOrderError && (
      <Loading isLight={false} />
    );
    const isSuccessStatus =
      order &&
      !checkingOrderStatus &&
      !getOrderError &&
      order.status !== AX_ORDER_STATUS.PAYMENT_VOIDED &&
      order.status !== AX_ORDER_STATUS.CANCELLED;
    const renderWaitingTitle =
      checkingOrderStatus && !getOrderError && 'Please wait ';
    const renderSuccessTitle =
      isSuccessStatus &&
      (order && order.paymentRequiresMultiAuth ? 'Almost Done' : 'All done');
    const renderSuccessSectionTitle =
      !getOrderError &&
      isSuccessStatus &&
      'Thank you for ordering with JJ Foodservice';
    const renderSuccessPaymentText =
      !getOrderError &&
      isSuccessStatus &&
      this.fromOrders &&
      'Payment completed. ';
    const renderCollectionInfo =
      order &&
      order.fulfillmentType === SERVICE_TYPE.COLLECTION &&
      branchList &&
      branchList[order.branchId] &&
      branchList[order.branchId].name &&
      `from ${branchList[order.branchId].name} at ${extractCollectionTime(
        order.fulfillSlot
      )}`;
    const renderDeliveryInfo =
      order &&
      order.fulfillmentType === SERVICE_TYPE.DELIVERY &&
      extractDeliveryTime(order.fulfillSlot);
    const renderCollectionMessage = order &&
      order.fulfillmentType === SERVICE_TYPE.COLLECTION &&
      (order.paymentMethod === PAYMENT_TYPES.CDC.mode ||
        order.paymentMethod === PAYMENT_TYPES.BCDC.mode) && (
        <SectionText>
          Please bring your card with you when you collect your goods.
        </SectionText>
      );
    const renderSuccessMessage = isSuccessStatus && !getOrderError && (
      <Fragment>
        <SectionText>
          Your order {order.axBasketId} has now been placed.
          {renderCollectionMessage}
        </SectionText>
        <SectionText>Total items: {order.items.length}</SectionText>
        <SectionText>
          Total cost: £{order.price && order.price.total}
        </SectionText>
        <SectionText $extraMargin={true}>
          for {order.fulfillmentType} {renderCollectionInfo}
          {renderDeliveryInfo}
        </SectionText>
        <SectionText>
          You may edit this order up until the cutoff time. Please visit{' '}
          <TheLink to="/orders">My Orders</TheLink> to view and edit orders.
        </SectionText>
      </Fragment>
    );
    const isPaymentVoidStatus =
      order &&
      (order.status === AX_ORDER_STATUS.PAYMENT_VOIDED ||
        order.status === AX_ORDER_STATUS.CANCELLED);
    const renderPaymentVoidTitle =
      (isPaymentVoidStatus || (errors && errors.order)) && 'Sorry';
    const renderPaymentVoidSectionTitle =
      (isPaymentVoidStatus ||
        (getOrderError && order && !order.status) ||
        (!order && loading && !loading.order)) &&
      'Sorry there has been a problem submitting your order.';
    const renderPBBPaymentMessage = order &&
      order.paymentMethod === PAYMENT_TYPES.PBB.mode && (
        <Fragment>
          <SectionText>
            Please, do not copy the details from the payment request to do a
            manual bank payment into the JJ Foodservice bank account.
          </SectionText>
          <SectionText>
            If you do this, it will not be registered correctly as a paid order
            on the system, and you won't get your order.
          </SectionText>
        </Fragment>
      );
    const renderPaymentVoidMessage = (isPaymentVoidStatus ||
      (errors && errors.order)) && (
      <Fragment>
        <SectionText>
          Please <TheLink to="/basket">return to your basket </TheLink> and try
          to checkout again. You will not be required to pay twice.
        </SectionText>
        <SectionText>
          If the problem persists please contact Customer Services on{' '}
          <Tel href={`tel:${JJ_TEL_FULL}`}>01992 70 17 01</Tel>
        </SectionText>
        {renderPBBPaymentMessage}
        <SectionText>
          <ButtonWrapper>
            <LinkButton to="/basket">Return to basket</LinkButton>
          </ButtonWrapper>
        </SectionText>
      </Fragment>
    );
    const renderPaymentRequiresMultiAuthModal = showPaymentRequiresMultiAuth && (
      <PopupModal
        modalName="Almost Done"
        content={
          <>
            Your payment needs authorised signatories’ approval before we can
            process it.
            <br />
            If your payment is not authorised, your order may be cancelled.
            <br />
            To avoid any delays or cancellations, please ask your bank account
            authoriser to log on to Business Internet Banking to authorise it.
          </>
        }
        title="Almost Done"
        callback={this.resetPaymentRequiresMultiAuth}
        showModal={showPaymentRequiresMultiAuth}
      />
    );
    const renderMarketingOptions = auth &&
      auth.email &&
      !getOrderError &&
      !isPaymentVoidStatus &&
      !checkingOrderStatus && (
        <MarketingOptions
          email={auth && auth.email}
          hideIfSubscribed={true}
          hideWhileLoading={true}
          simpleView={true}
        />
      );
    const renderAdyenGivingError = errors &&
      errors.adyen &&
      showDonationDropin && (
        <Error>
          {errors.adyen.message ||
            (errors.adyen.errors && errors.adyen.errors.GIVING)}
          , please try again.
        </Error>
      );
    const renderAdyenGiving = showDonationDropin && (
      <AydenWrapper>
        <AdyenContent id="adyen-donation-container" />
      </AydenWrapper>
    );
    const renderDonationThankYou = showDonationThankYou && (
      <PopupModal
        content={
          <>
            Your donation makes a real difference. We're incredibly grateful for
            your support in helping us with{' '}
            <strong>{donationCampaignName}</strong>
            <br />
            <br />
            Your contribution brings us closer to achieving our goals, and we
            couldn’t do it without amazing people like you. <br />
            <br />
            Thank you for being part of this journey. ❤️
          </>
        }
        title={'Thank you!'}
        isError={false}
        showModal={showDonationThankYou}
        showCancelButton={false}
        callback={this.closeDonationThankYou}
      />
    );
    return (
      <Wrapper>
        <AdyenStyle />
        <Head>
          <Title>
            {renderWaitingTitle}
            {renderSuccessTitle}
            {renderPaymentVoidTitle}
          </Title>
        </Head>
        <SectionTitle>
          {renderSuccessPaymentText}
          {renderSuccessSectionTitle}
          {renderPaymentVoidSectionTitle}
        </SectionTitle>
        <SectionWrapper>
          {renderLoading}
          <SubmitTakingTooLong>
            &nbsp;{submitTakingLongerText}&nbsp;
          </SubmitTakingTooLong>
          {renderSuccessMessage}
          {renderPaymentVoidMessage}
          {renderGetOrderFailed}
          {renderAdyenGivingError}
          {renderAdyenGiving}
          {renderDonationThankYou}
        </SectionWrapper>
        <SectionWrapper>{renderMarketingOptions}</SectionWrapper>
        {renderPaymentRequiresMultiAuthModal}
      </Wrapper>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  adyen: state.adyen,
  order: state.order,
  basket: state.basket,
  basketHashMap: state.basketHashMap,
  checkout: state.checkout,
  errors: state.errors,
  branchList: state.branchList,
  loading: state.loading,
});

const mapDispatchToProps = dispatch => ({
  removeBasket: bindActionCreators(removeBasket, dispatch),
  removeSetting: bindActionCreators(removeSetting, dispatch),
  resetOrder: bindActionCreators(resetOrder, dispatch),
  resetCheckout: bindActionCreators(resetCheckout, dispatch),
  resetNextRoute: bindActionCreators(resetNextRoute, dispatch),
  setCurrentRoute: bindActionCreators(setCurrentRoute, dispatch),
  getOrderAfterCheckout: bindActionCreators(getOrderAfterCheckout, dispatch),
  authAdyenPayment: bindActionCreators(authAdyenPayment, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmationPage);
