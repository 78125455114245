import moment from 'moment';
import {
  FULFILLMENT_SUCCESS_FORMAT,
  FULL_TIME_FORMAT,
  MAX_AVAILABLE_QUANTITY,
  ORDER_HISTORY_OUT_DATE_FORMAT,
  STANDARD_FORMAT,
  THE_DATE,
  URL,
} from '../constants/constants';

export const arrayToObject = (array, key) =>
  array.reduce((obj, item) => {
    obj[item[key]] = item;
    return obj;
  }, {});

export const arrayToObjectByTwoKeys = (array, key1, key2) =>
  array.reduce((obj, item) => {
    obj[item[key1]] = item[key2];
    return obj;
  }, {});

export const arrayToObjectValue = (array, key, value) =>
  array.reduce((obj, item) => {
    obj[item[key]] = value;
    return obj;
  }, {});

export const findFromToTime = (array, from, to) => {
  let result = false;
  for (let i = 0; i < array.length; i++) {
    if (array[i].from === from && array[i].to === to && array[i].isAvailable) {
      result = true;
    }
  }
  return result;
};

export const getBasketItemsIds = items => {
  return items.map(item => item.itemId);
};

const getDate = (day, format) => moment(new Date(day)).format(format);

export const extractCollectionTime = times => {
  if (!times.from || !times.day) {
    return '';
  }
  const slotDay = moment(times.day);
  const isToday =
    moment()
      .startOf('day')
      .diff(slotDay, 'day') === 0;
  const renderToday = isToday ? 'today ' : '';
  const day = getDate(times.day, FULFILLMENT_SUCCESS_FORMAT);
  return `${times.from.slice(0, 5)} ${renderToday}on ${day}.`;
};

export const extractDeliveryTime = times => {
  if (!times.from || !times.day) {
    return '';
  }
  const day = getDate(times.day, FULFILLMENT_SUCCESS_FORMAT);
  return `between ${times.from.slice(0, 5)} - ${times.to.slice(
    0,
    5
  )} on ${day}.`;
};

export const extractOrderDateTime = (
  datetime,
  fulfillmentType,
  isDispatched
) => {
  const out = isDispatched ? 'OUT ' : '';
  const time = moment(datetime.day, STANDARD_FORMAT).format(
    ORDER_HISTORY_OUT_DATE_FORMAT
  );
  const fromTo = `${datetime.from.slice(0, 5)}\xa0-\xa0${datetime.to.slice(
    0,
    5
  )}`;
  return `${out} for ${fulfillmentType} on ${time} ${fromTo}`;
};

export const extractInvoicedDateTime = (dateTime, fulfillmentType) => {
  if (!dateTime || !fulfillmentType) {
    return '';
  }
  const time = dateTime[2] + '/' + dateTime[1] + '/' + dateTime[0];

  return `for ${fulfillmentType} on ${time}`;
};

export const getCategoryName = product => {
  let categoryName = '';
  if (product.category1Name) {
    product.categoryName += product.category1Name + '/';
  }
  if (product.category2Name) {
    product.categoryName += product.category2Name + '/';
  }
  if (product.category3Name) {
    product.categoryName += product.category3Name;
  }
  return categoryName;
};

export const isLessThanAWeekOld = theTime => {
  const isDev = window.location.host.indexOf(URL.JJS_PROD) === -1;
  return (
    moment().diff(moment(theTime).format(FULL_TIME_FORMAT), 'days') <
    (isDev ? THE_DATE.REFUND_DEV : THE_DATE.REFUND_PROD)
  );
};

const convert2Digit = time => (time < 10 ? '0' + time : time);

export const isLessThanAWeekOldByDate = theDate => {
  if (!theDate) {
    return false;
  }
  const convertedTime =
    theDate[0] +
    '-' +
    convert2Digit(theDate[1]) +
    '-' +
    convert2Digit(theDate[2]) +
    'T' +
    convert2Digit(theDate[3]) +
    ':' +
    convert2Digit(theDate[4]) +
    ':' +
    convert2Digit(theDate[5]);

  return isLessThanAWeekOld(convertedTime);
};

export const groupInvoices = (invoices, cancelledOrders) => {
  let agg = [];
  if (invoices) {
    const invoicesCouldRefund = invoices.map(invoice => {
      if (isLessThanAWeekOld(invoice.invoiceDateTime)) {
        invoice.couldBeRefunded = true;
      }
      return invoice;
    });
    agg = [...agg, ...invoicesCouldRefund];
  }
  if (cancelledOrders) {
    agg = [...agg, ...cancelledOrders];
  }
  const merged = agg.sort((a, b) => {
    const prevItem = a.statusChangedAt || a.invoiceDateTime;
    const nextItem = b.statusChangedAt || b.invoiceDateTime;
    return prevItem > nextItem ? -1 : 1;
  });
  return merged.reduce((r, a) => {
    if (a.salesId) {
      r[a.salesId] = r[a.salesId] || [];
      r[a.salesId].push(a);
    }
    if (a.axBasketId) {
      r[a.axBasketId] = r[a.axBasketId] || [];
      r[a.axBasketId].push(a);
    }
    return r;
  }, Object.create(null));
};

export const getHighestTotalPages = pages =>
  pages.reduce((prev, current) =>
    ((prev && prev.totalPages) || 0) > ((current && current.totalPages) || 0)
      ? prev
      : current
  );

export const getAvailableQuantities = (max, step) => {
  if ((!max || max === 0) && step === 1) {
    return Array.from({length: MAX_AVAILABLE_QUANTITY}, (_, i) => i + 1);
  }
  const theMax = max || MAX_AVAILABLE_QUANTITY;
  if (step >= theMax) {
    return [theMax];
  }
  const theStep = !step || step === 0 ? 1 : step;
  return Array.from(
    {length: (theMax - theStep) / theStep + 1},
    (_, i) => theStep + i * theStep
  );
};

export const arrayToHashMapTrueObj = array =>
  array.reduce(
    (acc, curr) => ((acc[curr.shoppingListId] = curr.quantity), acc),
    {}
  );
export const objectHasTrueValue = obj =>
  Object.keys(obj).some(function(k) {
    return obj[k] > 0;
  });

export const getQtyById = (list, itemId) => {
  const items = (list && list.items) || [];
  let qty;
  if (items) {
    for (let i = 0; i < items.length; i++) {
      if (items[i].itemId === itemId) {
        qty = items[i].quantity;
        break;
      }
    }
  }
  return qty;
};

export const containListId = (lists, listId) => {
  let isInList = false;
  for (let i = 0; i < lists.length; i++) {
    if (lists[i].shoppingListId === listId) {
      isInList = true;
      break;
    }
  }
  return isInList;
};

export const getQtyInList = (lists, listId) => {
  let qty;
  for (let i = 0; i < lists.length; i++) {
    if (lists[i].shoppingListId === listId) {
      qty = lists[i].quantity;
      break;
    }
  }
  return qty;
};

export const checkValueInObject = (obj, value) => {
  let isExisted = false;
  Object.values(obj).map(val => {
    if (val === value) {
      isExisted = true;
    }
  });
  return isExisted;
};

export const createArrayFromNumber = num => {
  const theNum = num > -1 ? num : 0;
  return Array(theNum)
    .fill(0)
    .map((e, i) => i + 1);
};

export const sortBanners = banners => {
  const myBanners = {};
  banners.forEach(banner => {
    if (banner && banner.id) {
      if (banner.id > 10) {
        const pos = Math.floor(banner.id / 10);
        if (myBanners[pos] === undefined) {
          myBanners[pos] = [];
        }
        myBanners[pos].push(banner);
      } else {
        myBanners[banner.id] = banner;
      }
    }
  });
  const nums = Object.keys(myBanners);
  nums.forEach(num => {
    if (Array.isArray(myBanners[num])) {
      const k = myBanners[num].sort((a, b) => {
        return a.id - b.id;
      });
    }
  });
  return Object.values(myBanners);
};
