import axios from 'axios';
import queryString from 'query-string';

import {getApiConfig} from '../config/configProvider';
import {getValidUSDate} from '../helpers/data.helper';
import {GQL_GET_SEARCH} from '../gql/search.gql';

export default class SearchApi {
  static search(params, headers, host) {
    const config = getApiConfig();
    // fix search api not handling '&' correctly
    const categoryNames =
      params.categoryNames ||
      (!!params.categoryName ? [params.categoryName] : undefined);
    let variables = {
      page: parseInt(params.page, 10),
      size: parseInt(params.size, 10),
      branch: params.b,
      deliveryDate: getValidUSDate(params.deliveryDate),
      brand: params.brand,
      productFeatures: params.productFeatures,
      sizeOrCut: params.sizeOrCut,
      sortType: params.sortType,
      origin: params.origin,
      query: params.q && params.q.replace(/&|%26/g, '-'),
      categoryIds: params.category,
      first: params.first,
    };
    if (categoryNames) {
      variables.categoryNames = categoryNames;
    }
    if (params.isNew) {
      variables.isNew = params.isNew === 'true';
    }
    if (params.advanced) {
      variables.advanced = true;
    }
    if (params.includeNonPublicItems) {
      variables.includeNonPublicItems = params.includeNonPublicItems === 'true';
    }
    return axios({
      url: host || config.graphQL,
      method: 'post',
      data: {
        query: GQL_GET_SEARCH,
        variables,
      },
      headers,
    }).then(res => res.data);
  }

  static aggregate(params, headers, host) {
    const config = getApiConfig();
    const qs = queryString.stringify(params);
    const url = `${host || config.searchUrl}/product/webaggregate?${qs}`;
    return axios
      .get(url, {headers})
      .then(res => res.data)
      .catch(err => console.log(err));
  }

  static searchSuggestions(keyword) {
    const config = getApiConfig();
    const url = `${config.searchSuggestionUrl}?q=${encodeURIComponent(
      keyword
    )}`;
    return axios
      .get(url)
      .then(res => res.data)
      .catch(err => console.log(err));
  }

  static searchCategoryDescription(keyword) {
    const config = getApiConfig();
    const url = `${config.searchCategoryDescriptionUrl}/${encodeURIComponent(
      keyword
    )}`;
    return axios
      .get(url)
      .then(res => res.data)
      .catch(err => console.log(err));
  }

  static searchOwnBrandsDescription(keyword) {
    const config = getApiConfig();
    const url = `${config.searchOwnBrandsDescriptionUrl}/${encodeURIComponent(
      keyword
    )}`;
    return axios
      .get(url)
      .then(res => res.data)
      .catch(err => console.log(err));
  }
}
