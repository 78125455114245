import {sizes} from '../styles/components/media-queries';
import {createGlobalStyle} from 'styled-components';

export const GlobalStyled = createGlobalStyle`

    @font-face {
        font-family: 'Open Sans';
        src: url('https://fonts.googleapis.com/css?family=Open+Sans') format('embedded-opentype');
    }

    @font-face {
        font-family: 'icomoon';
        src: url('/api/fastly-assets/fonts/icomoon.eot');
        src: url('/api/fastly-assets/fonts/icomoon.eot#iefix') format('embedded-opentype'),
        url('/api/fastly-assets/fonts/icomoon.ttf') format('truetype'),
        url('/api/fastly-assets/fonts/icomoon.woff') format('woff'),
        url('/api/fastly-assets/fonts/icomoon.svg#icomoon') format('svg');

        font-weight: normal;
        font-style: normal;
    }

    html {
        box-sizing: border-box;
        font-size: 62.5%;
    }

    html,
    body {
        margin: 0;
        padding: 0;
        font-family: 'Open Sans', sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    *,
    *:before,
    *:after {
        box-sizing: inherit;
        box-sizing: border-box;
    }

    a {
        text-decoration: none;
    }

    .ReactModal__Content {
        max-height: 100%;
    }

    .zsiq_theme1 .zsiq_flt_rel {
        width: 50px !important;
        border-radius: 50px !important;
        height: 50px !important;
    }

    .zsiq_theme1 .siqico-chat:before {
        font-size: 25px !important;
        line-height: 50px !important;
    }

    header.noaboutme.nologo {
        height: 80px !important;
        max-height: 80px;
    }

    @media (max-width: ${sizes.small}px) {
        .ReactModal__Content {
            width: 100%;
            height: 100%;
            background: transparent !important;
        }
    }

    // CSS classes for MarkDown content
    .columns-2 {
        columns: 2;
        column-gap: 5rem;
    }

    .forceBreak {
        -webkit-column-break-after: always;
        break-after: column;
    }

    span.forceBreak {
        display: block;
    }

    // SmartBanner
    .smartbanner-show.smartbanner-margin-top {
        margin-top: 80px
    }

    .smartbanner-show.smartbanner-margin-bottom {
        margin-bottom: 80px
    }

    .smartbanner-show .smartbanner {
        display: block
    }

    .smartbanner {
        left: 0;
        display: none;
        width: 100%;
        height: 80px;
        line-height: 80px;
        font-family: Helvetica Neue, sans-serif;
        background: #f4f4f4;
        z-index: 9998;
        -webkit-font-smoothing: antialiased;
        overflow: hidden;
        -webkit-text-size-adjust: none
    }

    .smartbanner-top {
        position: absolute;
        top: 0
    }

    .smartbanner-bottom {
        position: fixed;
        bottom: 0
    }

    .smartbanner-container {
        margin: 0 auto;
        padding: 0 5px
    }

    .smartbanner-close {
        display: inline-block;
        vertical-align: middle;
        margin: 0 5px 0 0;
        font-family: ArialRoundedMTBold, Arial;
        font-size: 20px;
        text-align: center;
        color: #888;
        text-decoration: none;
        border: 0;
        border-radius: 14px;
        padding: 0 0 1px;
        background-color: transparent;
        -webkit-font-smoothing: subpixel-antialiased
    }

    .smartbanner-close:active, .smartbanner-close:hover {
        color: #aaa
    }

    .smartbanner-icon {
        width: 57px;
        height: 57px;
        margin-right: 12px;
        background-size: cover;
        border-radius: 10px
    }

    .smartbanner-icon, .smartbanner-info {
        display: inline-block;
        vertical-align: middle
    }

    .smartbanner-info {
        white-space: normal;
        width: calc(99% - 201px);
        font-size: 11px;
        line-height: 1.2em;
        font-weight: 700
    }

    .smartbanner-wrapper {
        max-width: 110px;
        display: inline-block;
        text-align: right;
        width: 100%
    }

    .smartbanner-title {
        font-size: 13px;
        line-height: 18px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden
    }

    .smartbanner-description {
        max-height: 40px;
        overflow: hidden
    }

    .smartbanner-author {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden
    }

    .smartbanner-author:empty + .smartbanner-description {
        max-height: 50px
    }

    .smartbanner-button {
        margin: auto 0;
        height: 24px;
        font-size: 14px;
        line-height: 24px;
        text-align: center;
        font-weight: 700;
        color: #6a6a6a;
        text-transform: uppercase;
        text-decoration: none;
        display: inline-block;
        text-shadow: 0 1px 0 hsla(0, 0%, 100%, .8)
    }

    .smartbanner-button:active, .smartbanner-button:hover {
        color: #aaa
    }

    .smartbanner-ios {
        background: #f2f2f2;
        box-shadow: 0 1px 1px rgba(0, 0, 0, .2);
        line-height: 80px
    }

    .smartbanner-ios .smartbanner-close {
        border: 0;
        width: 18px;
        height: 18px;
        line-height: 18px;
        font-family: Arial;
        color: #888;
        text-shadow: 0 1px 0 #fff;
        -webkit-font-smoothing: none
    }

    .smartbanner-ios .smartbanner-close:active, .smartbanner-ios .smartbanner-close:hover {
        color: #888
    }

    .smartbanner-ios .smartbanner-icon {
        background-size: cover
    }

    .smartbanner-ios .smartbanner-info {
        color: #6a6a6a;
        text-shadow: 0 1px 0 hsla(0, 0%, 100%, .8);
        font-weight: 300
    }

    .smartbanner-ios .smartbanner-title {
        color: #4d4d4d;
        font-weight: 500
    }

    .smartbanner-ios .smartbanner-button {
        padding: 0 10px;
        font-size: 15px;
        min-width: 10%;
        font-weight: 400;
        color: #0c71fd
    }

    .smartbanner-ios .smartbanner-button:active, .smartbanner-ios .smartbanner-button:hover {
        background: #f2f2f2
    }

    .smartbanner-android {
        background: #3d3d3d url("data:image/gif;base64,R0lGODlhCAAIAIABAFVVVf///yH5BAEHAAEALAAAAAAIAAgAAAINRG4XudroGJBRsYcxKAA7");
        box-shadow: inset 0 4px 0 #88b131;
        line-height: 82px
    }

    .smartbanner-android .smartbanner-close {
        border: 0;
        max-width: 17px;
        width: 100%;
        height: 17px;
        line-height: 17px;
        margin-right: 7px;
        color: #b1b1b3;
        background: #1c1e21;
        text-shadow: 0 1px 1px #000;
        text-decoration: none;
        box-shadow: inset 0 1px 2px rgba(0, 0, 0, .8), 0 1px 1px hsla(0, 0%, 100%, .3);
        cursor: pointer
    }

    .smartbanner-android .smartbanner-close:active, .smartbanner-android .smartbanner-close:hover {
        color: #eee
    }

    .smartbanner-android .smartbanner-icon {
        background-color: transparent;
        box-shadow: none
    }

    .smartbanner-android .smartbanner-info {
        color: #ccc;
        text-shadow: 0 1px 2px #000
    }

    .smartbanner-android .smartbanner-title {
        color: #fff;
        font-weight: 700
    }

    .smartbanner-android .smartbanner-button {
        min-width: 12%;
        color: #d1d1d1;
        font-weight: 700;
        padding: 0;
        background: none;
        border-radius: 0;
        box-shadow: 0 0 0 1px #333, 0 0 0 2px #dddcdc
    }

    .smartbanner-android .smartbanner-button:active, .smartbanner-android .smartbanner-button:hover {
        background: none
    }

    .smartbanner-android .smartbanner-button-text {
        text-align: center;
        display: block;
        padding: 0 10px;
        background: #42b6c9;
        background: linear-gradient(180deg, #42b6c9, #39a9bb);
        text-transform: none;
        text-shadow: none;
        box-shadow: none
    }

    .smartbanner-android .smartbanner-button-text:active, .smartbanner-android .smartbanner-button-text:hover {
        background: #2ac7e1
    }

    .smartbanner-kindle, .smartbanner-windows {
        background: #f4f4f4;
        background: linear-gradient(180deg, #f4f4f4, #cdcdcd);
        box-shadow: 0 1px 2px rgba(0, 0, 0, .5);
        line-height: 80px
    }

    .smartbanner-kindle .smartbanner-close, .smartbanner-windows .smartbanner-close {
        border: 0;
        width: 18px;
        height: 18px;
        line-height: 18px;
        color: #888;
        text-shadow: 0 1px 0 #fff
    }

    .smartbanner-kindle .smartbanner-close:active, .smartbanner-kindle .smartbanner-close:hover, .smartbanner-windows .smartbanner-close:active, .smartbanner-windows .smartbanner-close:hover {
        color: #aaa
    }

    .smartbanner-kindle .smartbanner-icon, .smartbanner-windows .smartbanner-icon {
        background: rgba(0, 0, 0, .6);
        background-size: cover;
        box-shadow: 0 1px 3px rgba(0, 0, 0, .3)
    }

    .smartbanner-kindle .smartbanner-info, .smartbanner-windows .smartbanner-info {
        color: #6a6a6a;
        text-shadow: 0 1px 0 hsla(0, 0%, 100%, .8)
    }

    .smartbanner-kindle .smartbanner-title, .smartbanner-windows .smartbanner-title {
        color: #4d4d4d;
        font-weight: 700
    }

    .smartbanner-kindle .smartbanner-button, .smartbanner-windows .smartbanner-button {
        padding: 0 10px;
        min-width: 10%;
        color: #6a6a6a;
        background: #efefef;
        background: linear-gradient(180deg, #efefef, #dcdcdc);
        border-radius: 3px;
        box-shadow: inset 0 0 0 1px #bfbfbf, 0 1px 0 hsla(0, 0%, 100%, .6), inset 0 2px 0 hsla(0, 0%, 100%, .7)
    }

    .smartbanner-kindle .smartbanner-button:active, .smartbanner-kindle .smartbanner-button:hover, .smartbanner-windows .smartbanner-button:active, .smartbanner-windows .smartbanner-button:hover {
        background: #dcdcdc;
        background: linear-gradient(180deg, #dcdcdc, #efefef)
    }

    div[class^="BlogPagestyle__Wrapper"] {
        font-size: 1.6rem;
    }

    div[class^="BlogPagestyle__PostNode"] h1,
    div[class^="BlogPagestyle__PostNode"] h2,
    div[class^="BlogPagestyle__PostNode"] h3,
    div[class^="BlogPagestyle__PostNode"] h4,
    div[class^="BlogPagestyle__PostNode"] h5,
    div[class^="BlogPagestyle__PostNode"] h6,
    div[class^="BlogPagestyle__PostNode"] h1 span,
    div[class^="BlogPagestyle__PostNode"] h2 span,
    div[class^="BlogPagestyle__PostNode"] h3 span,
    div[class^="BlogPagestyle__PostNode"] h4 span,
    div[class^="BlogPagestyle__PostNode"] h5 span,
    div[class^="BlogPagestyle__PostNode"] h6 span,
    div[class^="BlogPagestyle__Wrapper"] h1,
    div[class^="BlogPagestyle__Wrapper"] h2,
    div[class^="BlogPagestyle__Wrapper"] h3,
    div[class^="BlogPagestyle__Wrapper"] h4,
    div[class^="BlogPagestyle__Wrapper"] h5,
    div[class^="BlogPagestyle__Wrapper"] h6,
    div[class^="BlogPagestyle__Wrapper"] h1 span,
    div[class^="BlogPagestyle__Wrapper"] h2 span,
    div[class^="BlogPagestyle__Wrapper"] h3 span,
    div[class^="BlogPagestyle__Wrapper"] h4 span,
    div[class^="BlogPagestyle__Wrapper"] h5 span,
    div[class^="BlogPagestyle__Wrapper"] h6 span,
    div[class^="BlogPagestyle__PostNode"] [class^="BlogNodestyle__BlogHeading"],
    div[class^="BlogPagestyle__Wrapper"] [class^="BlogNodestyle__BlogHeading"] {
        color: #26ae5d;
        font-size: 2.6rem;
        font-weight: 200;
        line-height: 2.7rem;
    }

    div[class^="BlogPagestyle__Wrapper"] p, div[class^="BlogPagestyle__Wrapper"] span {
        color: rgb(117, 117, 117);
    }

    a > span[class^="SearchPagestyle__BreadCrumbResult"],
    div[class^="BlogPagestyle__PostNode"] a,
    div[class^="BlogPagestyle__Wrapper"] a {
        color: #3594cc;
    }

    div[class^="BlogPagestyle__PostNode"] p img,
    div[class^="BlogPagestyle__Wrapper"] img {
        max-width: 100%;
        height: auto !important;
    }

    div[class^="BlogPagestyle__Wrapper"] [class^="BlogNodestyle__BlogButtonWrapper"] {
        text-align: center;
        display: block;
        margin: 15px 0;
    }

    [class^="BlogPagestyle__PostNode"] [class^="BlogNodestyle__BlogButtonWrapper"] a[class^="BlogNodestyle__BlogButtonNode"] {
        color: #fff;
    }

    div[class^="BlogPagestyle__Wrapper"] [class^="BlogNodestyle__ParagraphWrapper"] {
        font-size: 1.6rem;
        min-width: 100%;
        margin: 2rem 0;
        line-height: 2.7rem;
    }

    div[class^="BlogPagestyle__Wrapper"] [class^="BlogNodestyle__LineBreak"] {
        height: 0.5rem;
        min-height: 0.5rem;
    }

    [class^="BlogNodestyle__BlogButtonWrapper"] {
        width: 100%;
        margin: 1.5rem 0;
        display: flex;
        justify-content: center;
    }

    [class^="BlogNodestyle__BlogButtonNode"] .jjLinkButton,
    a[class^="BlogNodestyle__BlogButtonNode"],
    [class^="BlogPagestyle__PostNode"] .jjLinkButton,
    div[class^="BlogPagestyle__Wrapper"] .jjLinkButton {
        max-width: max-content;
        font-size: 16px;
        padding: 12px 14px;
        color: #fff;
        background-color: #607a80;
        text-decoration: none;
        border-radius: 30px;
        max-height: 42px;
        margin: 0 auto;
    }

    a.olive-green {
        background-color: #a9a251 !important;
    }

    a.sage-gray {
        background-color: #848e7e !important;
    }

    a.copper-orange {
        background-color: #d0874a !important;
    }

    a.burnt-sienna {
        background-color: #dd623a !important;
    }

    a.charcoal {
        background-color: #3f3f3d !important;
    }

    a.slate-gray {
        background-color: #686866 !important;
    }

    div[class^="BlogPagestyle__Wrapper"] iframe {
        max-width: 100%;
        height: auto;
        aspect-ratio: 2.32;
    }


    div[class^="BlogPagestyle__Wrapper"] ul.jj-content-ul {
        display: flex;
        padding: 0;
        width: 100%;
        list-style-type: none;
    }

    div[class^="BlogPagestyle__Wrapper"] ul.jj-content-ul h2 {
        color: #26ae5d;
        text-transform: capitalize;
        font-size: 22px;
        font-weight: lighter
    }

    div[class^="BlogPagestyle__Wrapper"] ul li {
        margin-bottom: 0.8rem;
    }

    div[class^="BlogPagestyle__Wrapper"] ul.jj-content-ul li {
        text-align: center;
        flex-basis: 33%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    div[class^="BlogPagestyle__PostNode"] p {
        max-width: none;
    }

    div[class^="BlogPagestyle__PostNode"] p,
    div[class^="BlogPagestyle__Wrapper"] ul.jj-content-ul p {
        color: #797979;
        font-size: 16px;
        line-height: 24px;

    }

    div[class^="BlogPagestyle__Wrapper"] ul.jj-content-ul p {
        max-width: 240px;
    }

    div[class^="BlogPagestyle__Wrapper"] ul.jj-content-ul img {
        width: 240px;
    }

    article[class^="ShopBySectorsstyle__Wrapper"] > div > div[class^="BlogPagestyle__MainTitle"],
    div[class^="BlogPagestyle__PostNode"] > div > div[class^="BlogPagestyle__MainTitle"],
    div[class^="BlogPagestyle__PostNode"] > div > div > div[class^="BlogPagestyle__MainTitle"],
    div[class^="BlogPagestyle__Wrapper"] [class^="BlogPagestyle__PostBar-"] {
        display: none;
    }

    div[class^="BlogNodestyle__LineBreak"] {
        height: 0.5rem;
        min-height: 0.5rem;
    }

    div[class^="BlogNodestyle__BlogGalleryContainer"] {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    div[class^="BlogNodestyle__BlogGalleryWrapper"] {
        display: inline-flex;
        flex-wrap: wrap;
        margin: 0;
        min-width: 100%;
        gap: 10px;
    }

    img[class^="BlogNodestyle__BlogImageBlock"] {
        max-width: 100%;
    }

    img[class^="BlogNodestyle__BlogImageBlock"]:not([width]):not([style*="width"]) {
        width: 100%;
    }

    img[class^="BlogNodestyle__BlogImageBlock"][width],
    img[class^="BlogNodestyle__BlogImageBlock"][style*="width"] {
        max-width: 100%;
        width: auto;
        height: auto;
    }

    [class^=BlogNodestyle__BlogGalleryWrapper] img[class^="BlogNodestyle__BlogImageBlock"] {
        height: revert-layer;
    }

    span[class^="BlogNodestyle__BlogText"] {
        font-size: 1.6rem;
        line-height: 2.4rem;
        margin: 1rem 0;
        color: #797979;
    }

    table[class^="BlogNodestyle__BlogTableNode"] {
        width: 100%;
        position: relative;
        table-layout: fixed;
        border-spacing: 0;
    }

    tr[class^="BlogNodestyle__BlogTR"] {
        width: 100%;
        position: relative;
    }

    td[class^="BlogNodestyle__BlogTD"] {
        vertical-align: TOP;
        border-top-width: 0;
        border-left-width: 0;
        border-right-width: 0;
        border-bottom-width: 0;
        min-width: 120px;
        padding: 1rem 2rem;
        border-left-color: #FFFFFF;
        border-right-color: #FFFFFF;
        border-top-color: #FFFFFF;
        border-bottom-color: #FFFFFF;
        background-color: transparent;
        border-style: solid;
    }

    a[class^="BlogNodestyle__BlogLink"] {
        display: inline;
        padding: 0 0.2rem;
        margin: 1rem 0;
        font-size: 1.6rem;
        line-height: 2.4rem;
        cursor: pointer;
        color: #3594cc;
    }

    span[class^="BlogNodestyle__BlogHeading"] {
        font-size: 2.6rem;
        line-height: 5rem;
        font-weight: lighter;
        color: #26ae5d;
    }

    iframe[class^="BlogNodestyle__BlogVideoIframe"] {
        max-width: 100%;
        height: auto;
        max-height: 50rem;
        border: 0;
        aspect-ratio: 16/9;
    }


    ul.jj-content-ul {
        display: flex;
        padding: 0;
        width: 100%;
        list-style-type: none;
    }

    ul.jj-content-ul h2 {
        color: #26ae5d;
        text-transform: capitalize;
        font-size: 22px;
        font-weight: lighter
    }

    ul.jj-content-ul li {
        text-align: center;
        flex-basis: 33%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    ul.jj-content-ul p {
        color: #797979;
        font-size: 16px;
        line-height: 24px;
        max-width: 240px;
    }

    ul.jj-content-ul img {
        width: 240px;
    }

    article[class^="ShopBySectorsstyle__Wrapper"] img[class^="BlogNodestyle__BlogImageBlock"] {
        height: auto;
    }

    article[class^="ShopBySectorsstyle__Wrapper"] table colgroup {
        display: none;
    }

    article[class^="ShopBySectorsstyle__Wrapper"] table tr {
        display: flex;
        flex-wrap: wrap;
    }

    article[class^="ShopBySectorsstyle__Wrapper"] table tr td[class^="BlogNodestyle__BlogTD"] * {
        text-align: left !important;
    }

    article[class^="ShopBySectorsstyle__Wrapper"] table tr td[class^="BlogNodestyle__BlogTD"] a {
        font-size: 1.4rem;
    }

    article[class^="ShopBySectorsstyle__Wrapper"] table tr td[class^="BlogNodestyle__BlogTD"] {
        text-align: left;
        flex: 1;
        min-width: 25%;

    }

    @media (max-width: 768px) {
        article[class^="ShopBySectorsstyle__Wrapper"] table tr td[class^="BlogNodestyle__BlogTD"] {
            min-width: 50%;
            display: block;
        }
    }

    @media (max-width: 480px) {
        article[class^="ShopBySectorsstylea_Wrapper"] table tr td[class^="BlogNodestyle__BlogTD"] {
            min-width: 100%;
            display: block;
        }
    }

    span[class^="BlogNodestyle__BlogText"], [class^=BlogNodestyle__ParagraphWrapper] table td p, [class^=BlogNodestyle__ParagraphWrapper] p {
        font-size: 1.6rem;
        line-height: 2.4rem;
        margin: 1rem 0;
        color: #797979
    }
`;
